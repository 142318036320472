import React from 'react';
import Routes from './Config/Routes';
import { gv } from './Components/globalvar'

class App extends React.Component {

  componentWillMount() {
    let headerData = localStorage.getItem("headerData")
    if (!headerData) {
      fetch(`${gv}/api/v1/list/of/values`)
        .then(response => response.json())
        .then(result => {
          let resp = result.data
          localStorage.setItem("headerData", JSON.stringify(resp))
          this.setState({ fb_url: resp.fb_url, insta_url: resp.instagram_url, email: resp.retailer_email, store_name: resp.store_name, blaiz_link: resp.blaiz_link, iconFav: resp.fav_icon })
          localStorage.setItem("theme_settings", JSON.stringify(resp.theme_settings))
          localStorage.setItem("tocp", true)
          window.location.reload()
        })
        .catch(error => console.log('error', error));
    }
  }

  render() {
    return (
      <Routes />
    );
  }
}

export default App;
