import React, { Component } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import './index.css'
import Navbar from '../../Components/Navbar'
import CK from '../../Components/Ck'
import CheckIcon from '@material-ui/icons/Check';
import { gv } from '../../Components/globalvar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ReadMoreAndLess from 'react-read-more-less';
import { Helmet } from "react-helmet";
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CropperFile from '../../Components/CropperFile';

AOS.init();

export class ProductDetail extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: [],
            tablist: 0,
            tabname: '',
            tabvalue: '',
            tabDescription: '',
            firstProductImage: '',
            variationsIndex: 0,
            getDataHeading: false,
            outOfStock: false,
            product_id: '',
            product_stock: '',
            cartNav: '',
            // Editing WOrking
            isAdmin: false,
            title_3: "",
            title_3Input: false,
            desc_3: "",
            desc_3Input: false,
            category_name: "",
            category_nameInput: false,
            category_title: "",
            category_titleInput: false,
            category_desc: "",
            category_descInput: false,
            desc_2: "",
            desc_2Input: "",
            online_payment: true,
            main_descInput: false,
            cropperModal: false,
            currentIndexOther: 0,
            is_sub_account:false,
            city:"",
        }
        this.getTabData = this.getTabData.bind(this)
        this.createMarkup = this.createMarkup.bind(this)
    }

    componentDidMount() {
        let data = JSON.parse(localStorage.getItem(`product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}`))
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ getDataHeading: false })
        if (data) {
            this.setState({ wholeData: data })
        }

        let cartNav = localStorage.getItem("cartNav")
        if (cartNav) {
            this.setState({ cartNav: JSON.parse(cartNav) })
        }

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        fetch(`${gv}/api/v1/product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}?browser_type=${browserType}`)
            .then(response => response.json())
            .then(result => {
                let timeStamp = new Date().getTime()
                if (result.data.brand_name) {
                    let resp = result.data
                    localStorage.setItem(`product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}`, JSON.stringify(resp))
                    this.setState({
                        wholeData: resp,
                        browserType,
                        product_id: resp.product.product_id,
                        product_stock: resp.product.stock,
                        title_3: resp.section3_title,
                        desc_3: resp.section3_desc,
                        category_name: resp.category_name,
                        category_title: resp.category_title,
                        category_desc: resp.category_desc,
                        desc_2: resp.section2_desc,
                        timeStamp,
                        city:resp.city,
                        is_sub_account:resp.is_sub_account
                    })
                    this.getTabData(0)
                } else {
                    window.location.href = `/brand-products/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}`
                }
            })
        // .catch(error => {
        //     window.location.href = "/"
        // });

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let resp = JSON.parse(headerData)
            this.setState({ online_payment: resp.onliny_payment })
        }
    }


    getTabData(i) {
        if (!!this.state.wholeData.product) {
            let attributes = this.state.wholeData.product.attributes
            if (attributes.length > 0) {
                this.setState({ tablist: i, tabname: attributes[i].attribute_name, tabvalue: attributes[i].attribute_value, tabDescription: attributes[i].description })
            }
        }
    }

    createMarkup() {
        if (this.state.desc_2) {
            return { __html: this.state.desc_2 };
        }
    }

    addToCart() {
        const { product_id, product_stock } = this.state
        let cartData = JSON.parse(localStorage.getItem("cartData"))
        let cartDataFiltered = []
        if (cartData) {
            cartDataFiltered = cartData.cart_items.filter((f) => {
                return f.product_id === product_id
            })
        }
        if (cartDataFiltered.length > 0) {
            if (product_stock > cartDataFiltered[0].qty) {
                let imgtodrag = document.getElementsByClassName('image-front')[0];
                let disLeft = imgtodrag.getBoundingClientRect().left;
                let disTop = imgtodrag.getBoundingClientRect().top;
                let viewcart = document.getElementsByClassName('cart-icon')[0];
                let image = imgtodrag.cloneNode(true);

                let cartleft = viewcart.getBoundingClientRect().left;
                let carttop = viewcart.getBoundingClientRect().top;

                image.style = 'z-index: 1111; width: 350px;opacity:0.8; position:fixed; top:' + disTop + 'px;left:' + disLeft + 'px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)';

                var rechange = document.body.appendChild(image);
                setTimeout(function () {
                    image.style.left = cartleft + 'px';
                    image.style.top = carttop + 'px';
                    image.style.width = '40px';
                    image.style.opacity = '0';
                }, 200);
                setTimeout(function () {
                    rechange.parentNode.removeChild(rechange);
                }, 2000);

                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);
                    myHeaders.append("Accept", "application/json");

                    var formdata = new FormData();
                    formdata.append("product_id", product_id);
                    formdata.append("qty", 1);
                    formdata.append("product_type", "product");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/add/cart?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.setState({ outOfStock: false, getDataHeading: true })
                            this.setState({ getDataHeading: false })
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();

                    var formdata = new FormData();
                    formdata.append("product_id", product_id);
                    formdata.append("qty", 1);
                    formdata.append("product_type", "product");
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/add/cart?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.setState({ outOfStock: false, getDataHeading: true })
                            this.setState({ getDataHeading: false })
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                this.setState({ outOfStock: true })
            }
        } else {
            let imgtodrag = document.getElementsByClassName('image-front')[0];
            let disLeft = imgtodrag.getBoundingClientRect().left;
            let disTop = imgtodrag.getBoundingClientRect().top;
            let viewcart = document.getElementsByClassName('cart-icon')[0];
            let image = imgtodrag.cloneNode(true);

            let cartleft = viewcart.getBoundingClientRect().left;
            let carttop = viewcart.getBoundingClientRect().top;

            image.style = 'z-index: 1111; width: 350px;opacity:0.8; position:fixed; top:' + disTop + 'px;left:' + disLeft + 'px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)';

            var rechange = document.body.appendChild(image);
            setTimeout(function () {
                image.style.left = cartleft + 'px';
                image.style.top = carttop + 'px';
                image.style.width = '40px';
                image.style.opacity = '0';
            }, 200);
            setTimeout(function () {
                rechange.parentNode.removeChild(rechange);
            }, 2000);

            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${usertoken}`);
                myHeaders.append("Accept", "application/json");

                var formdata = new FormData();
                formdata.append("product_id", product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "product");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/add/cart?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ outOfStock: false, getDataHeading: true })
                        this.setState({ getDataHeading: false })
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();

                var formdata = new FormData();
                formdata.append("product_id", product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "product");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/add/cart?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ outOfStock: false, getDataHeading: true })
                        this.setState({ getDataHeading: false })
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    removeItem = () => {
        let cartData = JSON.parse(localStorage.getItem("cartData"))
        let cartDataFiltered = []
        if (cartData) {
            cartDataFiltered = cartData.cart_items.filter((f) => {
                return f.product_id === this.state.wholeData.product.product_id
            })
        }
        if (cartDataFiltered.length > 0) {
            if (this.state.wholeData.product.stock > Number(cartDataFiltered[0].qty)) {
                this.setState({ outOfStock: false })
            } else {
                this.setState({ outOfStock: true })
            }
        } else {
            this.setState({ outOfStock: false })
        }
    }

    updateSec() {
        const { title_3, desc_3, category_name, category_title, category_desc, desc_2 } = this.state

        let arr = [
            { key: "product_detail_page_section3_title", value: title_3 },
            { key: "product_detail_page_section3_desc", value: desc_3 },
            { key: "product_detail_page_category_name", value: category_name },
            { key: "product_detail_page_category_title", value: category_title },
            { key: "product_detail_page_category_desc", value: category_desc },
            { key: "product_detail_page_section2_desc", value: desc_2 },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("main_category", this.props.match.params.maincategory);
        formdata.append("brand_id", this.props.match.params.cildcategory);
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/dynamic/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    imgUploading(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.maincategory);
            formdata.append("brand_id", this.props.match.params.cildcategory);
            formdata.append("section_type", "product_detail_page_banner");
            formdata.append("image_type", "banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.state.wholeData.banner_image = result.data.image_url
                        this.state.wholeData.check_banner_image = true
                        this.setState({ loader: false, wholeData: this.state.wholeData })
                    } else {
                        this.setState({ loader: false })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImage() {
        this.setState({ loader: true })

        let splitted = this.state.wholeData.banner_image.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.maincategory);
        formdata.append("brand_id", this.props.match.params.cildcategory);
        formdata.append("image_type", "banner");
        formdata.append("section_type", "product_detail_page_banner");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.banner_image = result.data.default_url
                this.state.wholeData.check_banner_image = false
                this.setState({ loader: false, wholeData: this.state.wholeData })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loader: false })
            });
    }

    imgUploadingSec(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loaderSe: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.maincategory);
            formdata.append("brand_id", this.props.match.params.cildcategory);
            formdata.append("section_type", "product_detail_page_left_image");
            formdata.append("image_type", "left_banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.state.wholeData.left_image = result.data.image_url
                        this.state.wholeData.check_left_image = true
                        this.setState({ loaderSe: false, wholeData: this.state.wholeData })
                    } else {
                        this.setState({ loaderSe: false })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loaderSe: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImageSec() {
        this.setState({ loaderSe: true })

        let splitted = this.state.wholeData.left_image.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.maincategory);
        formdata.append("brand_id", this.props.match.params.cildcategory);
        formdata.append("image_type", "left_banner");
        formdata.append("section_type", "product_detail_page_left_image");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.left_image = result.data.default_url
                this.state.wholeData.check_left_image = false
                this.setState({ loaderSe: false, wholeData: this.state.wholeData })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loaderSe: false })
            });
    }

    imgUploadingTr(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loaderTr: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.maincategory);
            formdata.append("brand_id", this.props.match.params.cildcategory);
            formdata.append("section_type", "product_detail_page_right_image");
            formdata.append("image_type", "right_banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.state.wholeData.right_image = result.data.image_url
                        this.state.wholeData.check_right_image = true
                        this.setState({ loaderTr: false, wholeData: this.state.wholeData })
                    } else {
                        this.setState({ loaderTr: false })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loaderTr: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImageTr() {
        this.setState({ loaderTr: true })

        let splitted = this.state.wholeData.right_image.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.maincategory);
        formdata.append("brand_id", this.props.match.params.cildcategory);
        formdata.append("section_type", "product_detail_page_right_image");
        formdata.append("image_type", "right_banner");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.state.wholeData.right_image = result.data.default_url
                this.state.wholeData.check_right_image = false
                this.setState({ loaderTr: false, wholeData: this.state.wholeData })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loaderTr: false })
            });
    }


    changeAlert(e) {
        this.setState({ desc_2: e })
    }

    close() {
        this.setState({ desc_2Input: false })
    }

    changeAlertDesc(e) {
        if (this.state.wholeData.product) {
            this.state.wholeData.product.long_desc = e
            this.setState({ wholeData: this.state.wholeData })
        }
    }

    closeDesc() {
        this.setState({ main_descInput: false })
    }

    imgUploadingProduct(e, f) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0] })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (f === "first") {
                        if (width > 401 && height > 401) {
                            let obj = {
                                unit: 'px',
                                width: "400",
                                height: "400",
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropperModal: true, crop: obj, imageTypeProduct: "first" })
                        } else {
                            let obj = {
                                unit: '%',
                                width: 100,
                                height: 80,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropperModal: true, crop: obj, imageTypeProduct: "first" })
                        }
                    } else {
                        if (width > 600 && height > 600) {
                            let obj = {
                                unit: 'px',
                                width: 600,
                                height: 600,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropperModal: true, crop: obj, imageTypeProduct: "second" })
                        } else {
                            let obj = {
                                unit: '%',
                                width: 100,
                                height: 80,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropperModal: true, crop: obj, imageTypeProduct: "second" })
                        }
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl, crop });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    showCurrentImage() {
        this.setState({ cropperModal: false })
    }

    doneImage() {
        let f = this.state.imageName
        let g = this.state.imagePosition
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });
        let timeStamp = new Date().getTime()

        this.setState({ cropper: false, croppedImageUrl: "" })

        if (this.state.imageTypeProduct === "first") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("product_id", this.state.product_id);
            formdata.append("image", file);
            formdata.append("image_type", "first");
            formdata.append("description", this.state.wholeData.product.long_desc);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/product/update/info`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    fetch(`${gv}/api/v1/product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}?browser_type=${this.state.browserType}`)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data
                            localStorage.setItem(`product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}`, JSON.stringify(resp))
                            this.setState({
                                wholeData: resp,
                                product_id: resp.product.product_id,
                                product_stock: resp.product.stock,
                                title_3: resp.section3_title,
                                desc_3: resp.section3_desc,
                                category_name: resp.category_name,
                                category_title: resp.category_title,
                                category_desc: resp.category_desc,
                                desc_2: resp.section2_desc,
                                cropperModal: false,
                                timeStamp,
                            })
                            this.getTabData(0)
                        })
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        } else {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("product_id", this.state.product_id);
            formdata.append("image", file);
            formdata.append("image_type", "second");
            formdata.append("description", this.state.wholeData.product.long_desc);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/product/update/info`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    fetch(`${gv}/api/v1/product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}?browser_type=${this.state.browserType}`)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data
                            localStorage.setItem(`product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}`, JSON.stringify(resp))
                            this.setState({
                                wholeData: resp,
                                product_id: resp.product.product_id,
                                product_stock: resp.product.stock,
                                title_3: resp.section3_title,
                                desc_3: resp.section3_desc,
                                category_name: resp.category_name,
                                category_title: resp.category_title,
                                category_desc: resp.category_desc,
                                desc_2: resp.section2_desc,
                                cropperModal: false,
                                currentIndexOther: 0,
                                timeStamp,
                            })
                            this.getTabData(0)
                        })
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        }
    }

    removeImageProduct(e) {
        let timeStamp = new Date().getTime()
        if (e === "first") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            let splitted = this.state.wholeData.product.image_url.split("/")

            var formdata = new FormData();
            formdata.append("product_id", this.state.product_id);
            formdata.append("image", splitted[splitted.length - 1]);
            formdata.append("image_type", "first");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/product/remove/image?browser_type=chrome`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    fetch(`${gv}/api/v1/product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}?browser_type=${this.state.browserType}`)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data
                            localStorage.setItem(`product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}`, JSON.stringify(resp))
                            this.setState({
                                wholeData: resp,
                                product_id: resp.product.product_id,
                                product_stock: resp.product.stock,
                                title_3: resp.section3_title,
                                desc_3: resp.section3_desc,
                                category_name: resp.category_name,
                                category_title: resp.category_title,
                                category_desc: resp.category_desc,
                                desc_2: resp.section2_desc,
                                cropperModal: false,
                                timeStamp,
                            })
                            this.getTabData(0)
                        })
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        } else {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            let splitted = this.state.wholeData.product.other_image[this.state.currentIndexOther].image_url.split("/")

            var formdata = new FormData();
            formdata.append("product_id", this.state.product_id);
            formdata.append("image", splitted[splitted.length - 1]);
            formdata.append("image_type", "second");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/product/remove/image?browser_type=chrome`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    fetch(`${gv}/api/v1/product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}?browser_type=${this.state.browserType}`)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data
                            localStorage.setItem(`product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}`, JSON.stringify(resp))
                            this.setState({
                                wholeData: resp,
                                product_id: resp.product.product_id,
                                product_stock: resp.product.stock,
                                title_3: resp.section3_title,
                                desc_3: resp.section3_desc,
                                category_name: resp.category_name,
                                category_title: resp.category_title,
                                category_desc: resp.category_desc,
                                desc_2: resp.section2_desc,
                                cropperModal: false,
                                currentIndexOther: 0,
                                timeStamp,
                            })
                            this.getTabData(0)

                        })
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        }
    }

    updateDescriptionApi() {
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("product_id", this.state.product_id);
        formdata.append("description", this.state.wholeData.product.long_desc);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/product/update/info`, requestOptions)
            .then(response => response.json())
            .then(() => {
                fetch(`${gv}/api/v1/product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}?browser_type=${this.state.browserType}`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data
                        localStorage.setItem(`product/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}/${this.props.match.params.productsku}`, JSON.stringify(resp))
                        this.setState({
                            wholeData: resp,
                            product_id: resp.product.product_id,
                            product_stock: resp.product.stock,
                            title_3: resp.section3_title,
                            desc_3: resp.section3_desc,
                            category_name: resp.category_name,
                            category_title: resp.category_title,
                            category_desc: resp.category_desc,
                            desc_2: resp.section2_desc,
                            cropperModal: false,
                            main_descInput: false,
                        })
                        this.getTabData(0)
                    })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loader: false })
            });
    }

    render() {
        return (
            <div className="product-detail-owner">
                <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>
                <Navbar getData={this.state.getDataHeading} removeItem={this.removeItem} />

                <div className="cropper-modal-overlay" style={{ opacity: this.state.cropperModal ? 1 : 0, pointerEvents: this.state.cropperModal ? "all" : "none" }}>

                </div>
                <div className="cropper-modal-new" style={{ opacity: this.state.cropperModal ? 1 : 0, pointerEvents: this.state.cropperModal ? "all" : "none", zIndex: 999999999, paddingBottom: 30 }}>
                    <div style={{ position: "relative", zIndex: 99999999999, }}>
                        {this.state.src && <CropperFile src={this.state.src} crop={this.state.crop} onImageLoaded={this.onImageLoaded} makeClientCrop={(e) => this.makeClientCrop(e)} />}
                        <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                            <input type="file" onChange={(e) => this.imgUploadingProduct(e, "first")} accept="image/*" />
                            <PublishIcon style={{ color: "white" }} />
                        </div>
                        <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                            <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                        </div>
                        <div className="footer-allpopups-sections">
                            <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                                <font>DONE</font>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-main">
                    <div className="first-section-pd">
                        <div className="product-img" data-aos="fade-left">
                            {this.state.isAdmin && <div className="uploading-beu" style={{ position: "absolute", top: 50, left: 40 }}>
                                <input type="file" onChange={(e) => this.imgUploadingProduct(e, "first")} accept="image/*" />
                                <PublishIcon />
                            </div>}
                            {this.state.wholeData.check_first_image && this.state.isAdmin && <div className="uploading-beu" style={{ position: "absolute", top: 50, left: 90 }} onClick={this.removeImageProduct.bind(this, "first")}>
                                <DeleteIcon />
                            </div>}
                            {!!this.state.wholeData.product && <img src={`${this.state.wholeData.product.image_url}?t=${this.state.timeStamp}`} style={{ maxWidth: 400 }} className="image-front" alt="other" />}
                        </div>
                        <div className="product-detail">
                            <div className="pd-content">
                                <div className="product-tile-pdc">
                                    <img src={this.state.wholeData.small_logo} className="rituals-icon" />
                                    <h1 className="product-name-pt">
                                        <span>{this.state.wholeData.brand_name}</span>
                                        {this.state.wholeData.category_name}
                                    </h1>
                                </div>
                                <div className="product-content-pt">
                                    {this.state.isAdmin ? <div className="long-description" onClick={() => this.setState({ main_descInput: true })}>
                                        {!!this.state.wholeData.product && this.state.wholeData.product.long_desc && !this.state.main_descInput ? <ReadMoreAndLess
                                            ref={this.ReadMore}
                                            className="read-more-content"
                                            charLimit={300}
                                            readMoreText="Lees meer"
                                            readLessText="Lees minder"
                                        >
                                            {this.state.wholeData.product.long_desc}
                                        </ReadMoreAndLess> : this.state.wholeData.product && this.state.wholeData.product.long_desc && <textarea style={{ backgroundColor: "transparent", minWidth: 300, minHeight: 300 }} value={this.state.wholeData.product.long_desc} onChange={(e) => {
                                            this.state.wholeData.product.long_desc = e.target.value
                                            this.setState({ wholeData: this.state.wholeData })
                                        }} onBlur={this.updateDescriptionApi.bind(this)}></textarea>}
                                    </div> : !!this.state.wholeData.product && this.state.wholeData.product.long_desc && <div className="long-description">
                                        <ReadMoreAndLess
                                            ref={this.ReadMore}
                                            className="read-more-content"
                                            charLimit={300}
                                            readMoreText="Lees meer"
                                            readLessText="Lees minder"
                                        >
                                            {this.state.wholeData.product.long_desc}
                                        </ReadMoreAndLess>
                                    </div>}
                                    {this.state.wholeData.product && this.state.wholeData.product.variations.length > 1 ? <div className="product-short-info">
                                        <font>Selecteer maat</font>
                                    </div> : this.state.wholeData.product && this.state.wholeData.product.variations.length === 1 && <div className="product-short-info">
                                        <font>Laatste maat</font>
                                    </div>}
                                    {this.state.wholeData.product && this.state.wholeData.product.variations.length > 0 && <div className="size-pcpt">
                                        {this.state.wholeData.product.variations.map((e, i) => {
                                            return <div className="block-spcpt" key={Math.random()}>
                                                <input type="radio" name="size-check"
                                                    checked={this.state.variationsIndex === i ? true : false}
                                                    onChange={() => {
                                                        this.state.wholeData.product.retail_price = e.retail_price
                                                        this.state.wholeData.product.sale_price = e.sale_price
                                                        this.state.wholeData.product.show_sale_price = e.show_sale_price
                                                        this.state.wholeData.product.show_retail_price = e.show_retail_price
                                                        this.setState({ wholeData: this.state.wholeData, product_id: e.product_id, product_stock: e.stock, variationsIndex: i, getDataHeading: false, outOfStock: false })
                                                    }} />
                                                <div className="inner-bspcpt">
                                                    <p>{e.size}</p>
                                                </div>
                                            </div>
                                        })}
                                    </div>}
                                    <div className="product-price">
                                        {!!this.state.wholeData.product && this.state.wholeData.product.sale_price && this.state.wholeData.product.sale_price !== "0.00" && <font style={{ textDecorationLine: "line-through", fontSize: 18, color: "#b2b2b2" }} >€ {this.state.wholeData.product.show_retail_price}</font>}
                                    </div>
                                    <div className="product-price">
                                        {!!this.state.wholeData.product && this.state.wholeData.product.sale_price && Number(this.state.wholeData.product.sale_price) !== 0 && Number(this.state.wholeData.product.sale_price) < Number(this.state.wholeData.product.retail_price) ? <font>€ {this.state.wholeData.product.show_sale_price}</font> : !!this.state.wholeData.product && <font>€ {this.state.wholeData.product.show_retail_price}</font>}
                                    </div>
                                    {this.state.online_payment && <div className="add-to-cart">
                                        {!this.state.outOfStock ? <button onClick={this.addToCart.bind(this)}>IN WINKELMAND</button> : <button>REEDS TOEGEVOEGD</button>}
                                    </div>}
                                    <div className="shipping-info">
                                        <div className="content-asset">
                                            <ul>
                                                {this.state.is_sub_account && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#865" }} />
                                                    </span>
                                                    <span>
                                                    Op voorraad in vestiging: {this.state.city}
                                                    </span>
                                                </li>}
                                                {this.state.wholeData.product && this.state.wholeData.product.variations.length > 0 && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#865" }} />
                                                    </span>
                                                    <span>
                                                        Artikelnummer: {this.state.wholeData.product.variations[this.state.variationsIndex].plain_sku}
                                                    </span>
                                                </li>}
                                                {this.state.cartNav && this.state.cartNav.detail_page_delivery_text && this.state.online_payment && <li style={{ display: "flex", alignItems: "center" }}>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#865" }} />
                                                    </span>
                                                    <span>
                                                        {this.state.cartNav.detail_page_delivery_text}
                                                    </span>
                                                </li>}
                                                {this.state.wholeData.product && this.state.wholeData.product.variations.length === 0 && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#865" }} />
                                                    </span>
                                                    <span>
                                                        Artikelnummer: {this.props.match.params.productsku}
                                                    </span>
                                                </li>}
                                                {this.state.online_payment && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#865" }} />
                                                    </span>
                                                    <span>
                                                        Delivery {this.state.wholeData.delivery_code}
                                                    </span>
                                                </li>}
                                                <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#865" }} />
                                                    </span>
                                                    <span>
                                                        <span className="mobilenone" style={{ width: "auto" }}>Vragen over een product?</span> Bel ons op: {this.state.wholeData.phone_number}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-sec-pd">
                    <div className="product-img" data-aos="fade-right" style={{ position: "relative" }}>
                        {this.state.isAdmin && <div className="uploading-beu" style={{ position: "absolute", top: 50, left: 40 }}>
                            <input type="file" onChange={(e) => this.imgUploadingProduct(e, "second")} accept="image/*" />
                            <PublishIcon />
                        </div>}
                        {this.state.wholeData.product && this.state.wholeData.product.other_image[this.state.currentIndexOther].check_second_image && this.state.isAdmin && <div className="uploading-beu" style={{ position: "absolute", top: 50, left: 90 }} onClick={this.removeImageProduct.bind(this, "second")}>
                            <DeleteIcon />
                        </div>}
                        <div className="wrapper-product">
                            {!!this.state.wholeData.product && <img src={`${this.state.wholeData.product.other_image[this.state.currentIndexOther].image_url}?t=${this.state.timeStamp}`} alt="other" />}
                        </div>
                        {this.state.wholeData.product && this.state.wholeData.product.other_image.length > 1 && <div className="chevron-icons-other">
                            {this.state.currentIndexOther > 0 && <div>
                                <ChevronLeftIcon onClick={() => this.setState({ currentIndexOther: this.state.currentIndexOther - 1 })} />
                            </div>}
                            {this.state.currentIndexOther < this.state.wholeData.product.other_image.length - 1 && <div>
                                <ChevronRightIcon onClick={() => this.setState({ currentIndexOther: this.state.currentIndexOther + 1 })} />
                            </div>}
                        </div>}
                    </div>
                    <div className="product-detail">
                        <div className="wrapper-product">
                            {this.state.isAdmin ? <div className="product-item" onClick={() => this.setState({ desc_2Input: true })}>
                                {!this.state.desc_2Input ? <div dangerouslySetInnerHTML={this.createMarkup()}></div> : <CK data={this.state.desc_2} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                            </div> : <div className="product-item">
                                    <div dangerouslySetInnerHTML={this.createMarkup()}>
                                    </div>
                                    {/* {this.state.wholeData.product.long_desc} */}
                                </div>}
                            {this.state.wholeData.product && this.state.wholeData.product.attributes.length > 0 && <div style={{ width: "100%", textAlign: "left" }}>
                                <button>
                                    <a href="#tabs" style={{ color: "white", textDecoration: "none" }}>
                                        BEKIJK SPECIFICATIES
                                    </a>
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="third-sec-pd" data-aos="fade-in">
                    <div className="img-tsp" style={{ backgroundImage: `url(${this.state.wholeData.banner_image})` }}>
                        {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 10, top: 10 }}>
                            <div className="uploading-beu">
                                <input type="file" onChange={(e) => this.imgUploading(e)} accept="image/*" />
                                <PublishIcon />
                            </div>
                            {this.state.wholeData.check_banner_image && <div className="uploading-beu">
                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                            </div>}
                        </div>}
                        {this.state.loader && <div className="loader-working-fb">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>}
                    </div>
                    <div className="content-tsp">
                        {this.state.isAdmin ? <div className="content-tsp-box">
                            <h3 onClick={() => this.setState({ title_3Input: true })}>
                                {!this.state.title_3Input ? this.state.title_3 : <input autoFocus style={{ border: "none", outline: "none" }} value={this.state.title_3} onChange={(e) => this.setState({ title_3: e.target.value })} onBlur={() => this.setState({ title_3Input: false })} />}
                            </h3>
                            <p onClick={() => this.setState({ desc_3Input: true })} style={{ width: "100%" }}>
                                {!this.state.desc_3Input ? this.state.desc_3 : <input autoFocus style={{ border: "none", outline: "none", width: "100%" }} value={this.state.desc_3} onChange={(e) => this.setState({ desc_3: e.target.value })} onBlur={() => this.setState({ desc_3Input: false })} />}
                            </p>
                        </div> : <div className="content-tsp-box">
                                <h3>{this.state.title_3}</h3>
                                <p>{this.state.desc_3}</p>
                            </div>}
                    </div>
                </div>
                {this.state.wholeData.product && this.state.wholeData.product.attributes.length > 0 && <div className="product-tabs">
                    <div className="fourth-sec-pd" id="tabs">
                        <h1 className="heading-h3" style={{ textAlign: "center", marginBottom: 10, letterSpacing: 8 }}>SPECIFICATIE</h1>
                        <h1 className="heading-h3" style={{ textAlign: "center", marginBottom: 20, letterSpacing: 3, fontFamily: "Roboto", fontSize: 13 }} className="bodyText">{this.state.wholeData.brand_name} {this.state.wholeData.plain_sku}</h1>
                        <div className="tabs-holder">
                            <div className="tabs-items-holder">
                                {this.state.wholeData.product && this.state.wholeData.product.attributes.length > 4 && <ChevronLeftIcon onClick={() => {
                                    document.getElementById("tablist").scrollLeft -= 200
                                }} />}
                                <ul className="tabs" id="tablist" style={{ marginTop: 40, justifyContent: this.state.wholeData.product && this.state.wholeData.product.attributes.length < 5 && "center" }}>
                                    {!!this.state.wholeData.product && this.state.wholeData.product.attributes.map((e, i) => {
                                        return <li onClick={this.getTabData.bind(this, i)} key={Math.random()}>
                                            <button className={e.attribute_name === this.state.tabname ? "selected" : null}>{e.attribute_name}</button>
                                        </li>
                                    })}
                                </ul>
                                {this.state.wholeData.product && this.state.wholeData.product.attributes.length > 4 && <ChevronRightIcon onClick={() => {
                                    document.getElementById("tablist").scrollLeft += 200
                                }} />}
                            </div>
                            <div className="content-tc">
                                <font>{this.state.tabDescription}</font>
                            </div>
                            <div className="tabs-content">
                                <div className="content-tc">
                                    <font>{this.state.tabvalue}</font>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="fifth-sec-pd" style={{ marginTop: 50, paddingBottom: 50 }}>
                    <div className="wrapper-fifth">
                        {this.state.isAdmin ? <div className="product-packshot-container">
                            <div className="product-packshot-column column-text">
                                <div className="bg-pct" style={{ backgroundImage: `url(${this.state.wholeData.left_image})` }}>
                                    <span className="packshot-bg-overlay"></span>
                                    {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 10, top: 10 }}>
                                        <div className="uploading-beu">
                                            <input type="file" onChange={(e) => this.imgUploadingSec(e)} accept="image/*" />
                                            <PublishIcon />
                                        </div>
                                        {this.state.wholeData.check_left_image && <div className="uploading-beu">
                                            <DeleteIcon onClick={this.removeImageSec.bind(this)} />
                                        </div>}
                                    </div>}
                                    {this.state.loaderSe && <div className="loader-working-fb">
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </div>}
                                </div>
                                <div className="packshot-text-box">
                                    <p className="heading-h4" onClick={() => this.setState({ category_nameInput: true })}>
                                        {!this.state.category_nameInput ? this.state.category_name : <input autoFocus style={{ border: "none", outline: "none", backgroundColor: "transparent", width: "100%", color: "white" }} value={this.state.category_name} onChange={(e) => this.setState({ category_name: e.target.value })} onBlur={() => this.setState({ category_nameInput: false })} />}
                                    </p>
                                    <h2 className="heading-h3" onClick={() => this.setState({ category_titleInput: true })}>
                                        {!this.state.category_titleInput ? this.state.category_title : <input autoFocus style={{ border: "none", outline: "none", backgroundColor: "transparent", width: "100%", color: "white" }} value={this.state.category_title} onChange={(e) => this.setState({ category_title: e.target.value })} onBlur={() => this.setState({ category_titleInput: false })} />}
                                    </h2>
                                    <p className="normal-text" onClick={() => this.setState({ category_descInput: true })}>
                                        {!this.state.category_descInput ? this.state.category_desc : <input autoFocus style={{ border: "none", outline: "none", backgroundColor: "transparent", width: "100%", color: "white" }} value={this.state.category_desc} onChange={(e) => this.setState({ category_desc: e.target.value })} onBlur={() => this.setState({ category_descInput: false })} />}
                                    </p>
                                    <button onClick={() => window.location.href = `/brand-products/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}`} >DISCOVER THE COLLECTION</button>
                                </div>
                            </div>
                            <div className="product-packshot-column column-products" style={{ backgroundImage: `url(${this.state.wholeData.right_image})`, height: "100%" }}>
                                {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 10, top: 10 }}>
                                    <div className="uploading-beu">
                                        <input type="file" onChange={(e) => this.imgUploadingTr(e)} accept="image/*" />
                                        <PublishIcon />
                                    </div>
                                    {this.state.wholeData.check_right_image && <div className="uploading-beu">
                                        <DeleteIcon onClick={this.removeImageTr.bind(this)} />
                                    </div>}
                                </div>}
                                {this.state.loaderTr && <div className="loader-working-fb">
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                </div>}
                            </div>
                        </div> : <div className="product-packshot-container">
                                <div className="product-packshot-column column-text">
                                    <div className="bg-pct" style={{ backgroundImage: `url(${this.state.wholeData.left_image})` }}>
                                        <span className="packshot-bg-overlay"></span>
                                    </div>
                                    <div className="packshot-text-box">
                                        <p className="heading-h4">{this.state.category_name}</p>
                                        <h2 className="heading-h3">{this.state.category_title}</h2>
                                        <p className="normal-text">{this.state.category_desc}</p>
                                        <button onClick={() => window.location.href = `/brand-products/${this.props.match.params.maincategory}/${this.props.match.params.cildcategory}`} >DISCOVER THE COLLECTION</button>
                                    </div>
                                </div>
                                <div className="product-packshot-column column-products" style={{ backgroundImage: `url(${this.state.wholeData.right_image})` }}></div>
                            </div>}
                    </div>
                </div>
                {!!this.state.wholeData.product && this.state.wholeData.other_products.length > 0 && <div className="sixth-sec-pd">
                    <h1 className="heading-h3">{this.state.wholeData.other_products_title}</h1>
                    <div className="inner-wrapper">
                        <div className="second-card-bath">
                            {this.state.wholeData.other_products.map((e) => {
                                return <div className="card-bbath" data-aos="fade-in" key={Math.random()} onClick={() => {
                                    window.location.href = `/product-detail/${this.props.match.params.maincategory}/${e.brand_id}/${e.plain_sku}`
                                }}>
                                    <div className="card-bimage">
                                        <img src={e.image_url} style={{ maxWidth: "100%" }} />
                                    </div>
                                    <div className="content-bimage">
                                        <span className="heading-hss" style={{ textTransform: "uppercase", fontFamily: "Roboto" }} className="bodyText">{e.name}</span>
                                        <h2 className="bodyText" style={{ fontFamily: "Roboto", fontSize: 13, color: "#b3b3b3" }}>{e.plain_sku}</h2>
                                        <p className="short-desc-bi">{e.category_name}</p>
                                        {e.sale_price === "0.00" && Number(e.sale_price) > Number(e.retail_price) ? <p className="product-price-bi">€ {e.show_retail_price}</p> : <p className="product-price-bi">&nbsp;</p>}
                                        {e.sale_price !== "0.00" && Number(e.sale_price) < Number(e.retail_price) ? <button className="bodyText" style={{ backgroundColor: "black", color: "white", borderRadius: 4, padding: "7px 12px", fontFamily: "Roboto" }}>€ {e.show_sale_price}</button> : <button className="bodyText" style={{ backgroundColor: "black", color: "white", borderRadius: 4, padding: "7px 12px", fontFamily: "Roboto" }}>€ {e.show_retail_price}</button>}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>}
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.greaterSizeImage}
                    onClose={() => this.setState({ greaterSizeImage: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size exceeds 7MB</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size should be 450x450 minimum</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.smallNotificationSecond}
                    onClose={() => this.setState({ smallNotificationSecond: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size should be 550x550 minimum</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default ProductDetail
