import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import { gv } from '../Components/globalvar'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button';

export class FrameSec extends Component {

    constructor() {
        super()
        this.state = {
            iframe: "",
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            createLoader: true
        }
    }

    componentDidMount() {
        let accesstoken = localStorage.getItem("adminKey")

        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    iframe() {
        return {
            __html: `<iframe id="frame" src="${this.state.iframe}" width="100%" height="750"></iframe>`
        }
    }

    saveSection(e) {
        if (e === "yes") {
            const { iframe, wholeData } = this.state

            let obj = {
                type: "layout_12",
                iframe,
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
            }

            this.setState({ createLoader: true })

            let currentIndex = localStorage.getItem("currentOrder")
            wholeData.sections.splice(Number(currentIndex) + 1, 0, obj)

            this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
            let stringified = JSON.stringify(this.state.sendingData.other_pages)

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("builder_settings", stringified);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, createLoader: false })
                });
        } else {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
                mode: 'no-cors'
            };

            fetch(this.state.iframe, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (result === "") {
                        const { iframe, wholeData } = this.state

                        let obj = {
                            type: "layout_12",
                            iframe,
                            space_top: this.state.spaceTop,
                            space_bottom: this.state.spaceBottom,
                        }

                        this.setState({ createLoader: true })

                        let currentIndex = localStorage.getItem("currentOrder")
                        wholeData.sections.splice(Number(currentIndex) + 1, 0, obj)

                        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
                        let stringified = JSON.stringify(this.state.sendingData.other_pages)

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("builder_settings", stringified);

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
                            .then(response => response.json())
                            .then(() => {
                                window.location.reload()
                            })
                            .catch(error => {
                                this.setState({ showErrorModal: true, createLoader: false })
                            });
                    } else {
                        this.setState({ popupOpen: true })
                    }
                })
                .catch(error => console.log('error', error, 123));
        }
    }

    render() {
        return (
            <div>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div style={{ width: "100%", height: "100%", paddingTop: 40 }}>
                    <input placeholder="Plaats hier de link naar uw brochure" value={this.state.iframe} onChange={(e) => {
                        let currentWindow = window.location
                        if (e.target.value.includes(currentWindow.origin)) {
                            this.setState({ currentWindowAlert: true })
                        } else {
                            this.setState({ iframe: e.target.value, currentWindowAlert: false })
                        }
                    }} style={{ marginBottom: 10, width: "100%", padding: 10 }} />
                    {this.state.currentWindowAlert && <span style={{ fontSize: 12, color: "red", fontWeight: 400, marginBottom: 80 }}>Current URL can't be included as iFrame</span>}
                    <div dangerouslySetInnerHTML={this.iframe()}></div>
                </div>
                {!this.state.currentWindowAlert && !this.state.createLoader && <div className="footer-allpopups-sections">
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.popupOpen}
                    onClose={() => this.setState({ popupOpen: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Are you sure to continue with invalid URL?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ popupOpen: false })} color="primary">No</Button>
                        <Button onClick={this.saveSection.bind(this, "yes")} color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default FrameSec
