import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import CK from '../Components/Ck'

export class TextTemplateData extends Component {

    constructor() {
        super()
        this.state = {
            title_2: "",
            desc_2: "",
            spaceTop: true,
            spaceBottom: true,
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })
    }

    createMarkup() {
        if (this.props.data) {
            return { __html: this.props.data.desc };
        }
    }

    render() {
        return (
            <div>
                <div className="simple-text-block" style={{ margin: 0, padding: 0, textAlign: "center", marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                    <div className="inner-wrapper">
                        <h3 className="heading-h3">
                            {this.props.data.title}
                        </h3>
                        <p onClick={() => this.setState({ desc_2Input: true })} style={{ width: "100%" }}>
                            <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default TextTemplateData
