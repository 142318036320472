import React, { Component } from 'react'

export class FooterContactData extends Component {

    constructor() {
        super()
        this.state = {
            left_title_1: "Maarten Smedema",
            left_title_2: "Projectmanager",
            left_image: "./assets/maarten.png",
            right_title: "CONTACT",
            left_title: "SOCIAL",
            phone_number: "",
            email: "123@gmail.com",
            address: "abc street",
            right_image: "./assets/footer-image.png",
            linkedInUrl: "",
            leftColor: "blue",
            rightColor: "pink",
        }
    }

    componentDidMount() {
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            headerData = JSON.parse(headerData)
            this.setState({ phone_number: headerData.phone, address: headerData.address, email: headerData.email, linkedInUrl: headerData.linked_in_url, fb_url: headerData.fb_url, insta_url: headerData.instagram_url, })
        }

        if (this.props.data.leftColor) {
            this.setState({ leftColor: this.props.data.leftColor, rightColor: this.props.data.rightColor })
        }
    }

    render() {
        return (
            <div style={{ marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60 }}>
                <div className="seventh-sec-fc" id="contact">
                    <div className="contact-card-ss-fc" style={{ flexDirection: this.props.data.is_flipped && "row-reverse" }}>
                        <div className="left-ccss-fc" data-aos="fade-right">
                            <h5>{this.props.data.left_title_1}</h5>
                            <h5>{this.props.data.left_title_2}</h5>
                            <div className="img-lccss-fc" style={{ backgroundImage: `url(${this.props.data.left_image})` }}>

                            </div>
                        </div>
                        <div className="right-ccss-fc" style={{ backgroundImage: `url(${this.props.data.right_image})` }}>
                            <div className="first-sccss-fc" data-aos="fade-right" style={{ backgroundColor: this.state.leftColor }}>
                                <h3 className="title">{this.props.data.right_title}</h3>
                                <ul>
                                    <li>
                                        <div>
                                            <img alt="phone" src="./assets/phone.png" />
                                        </div>
                                        <font><a style={{color: "#ffffff",textDecoration: 'none'}} href='tel:{this.state.phone_number}'>{this.state.phone_number}</a></font>                                        
                                    </li>
                                    <li>
                                        <div>
                                            <img alt="mail" src="./assets/mail.png" />
                                        </div>
                                        <font><a style={{color: "#ffffff",textDecoration: 'none'}} href="mailto:{this.state.email}">{this.state.email}</a></font>
                                    </li>
                                    <li>
                                        <div>
                                            <img alt="location" src="./assets/location.png" />
                                        </div>
                                        <font>{this.props.data.address}</font>
                                    </li>
                                    <li style={{ paddingLeft: 45 }}>
                                        <font>{this.props.data.zip_code}</font>&nbsp;
                                        <font>{this.props.data.city}</font>
                                    </li>
                                </ul>
                            </div>
                            <div className="second-sccss-fc" data-aos="fade-left" style={{ backgroundColor: this.state.rightColor }}>
                                <h3 className="title">{this.props.data.left_title}</h3>
                                <ul>
                                    {this.state.linkedInUrl && <li onClick={() => {
                                        if (this.state.linkedInUrl) {
                                            window.open(this.state.linkedInUrl, "_blank")
                                            // window.location.href = this.state.linkedInUrl
                                        }
                                    }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div>
                                            <img alt="insta" src="./assets/linkedin.png" style={{ maxWidth: 30 }} />
                                        </div>
                                        <font>Linkedin</font>
                                    </li>}
                                    {this.state.fb_url && <li onClick={() => {
                                        if (this.state.fb_url) {
                                            window.open(this.state.fb_url, "_blank")
                                            // window.location.href = this.state.fb_url
                                        }
                                    }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div>
                                            <img alt="insta" src="./assets/fb.png" style={{ maxWidth: 30 }} />
                                        </div>
                                        <font>Facebook</font>
                                    </li>}
                                    {this.state.insta_url && <li onClick={() => {
                                        if (this.state.insta_url) {
                                            window.open(this.state.insta_url, "_blank")
                                            // window.location.href = this.state.insta_url
                                        }
                                    }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div>
                                            <img alt="insta" src="./assets/instagram.png" style={{ maxWidth: 30 }} />
                                        </div>
                                        <font>Instagram</font>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FooterContactData
