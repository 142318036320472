import React, { Component } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import Home from '../Containers/Home'
import BathBody from '../Containers/Bath-Body'
import CollectionStory from '../Containers/Collection-Story'
import Cart from '../Containers/Cart'
import Account from '../Containers/Account'
import ProductDetail from '../Containers/Product-Detail'
import HomeDuplicate from '../Containers/Home-Duplicate'
import CollectionStoryJing from '../Containers/CollectionStoryJing'
import Orders from '../Containers/Orders'
import Newsletter from '../Containers/Newsletter'
import Checkout from '../Containers/Checkout'
import Payment from '../Containers/Payment'
import Login from '../Containers/Login'
import Delivery from '../Containers/Delivery'
import OrderConfirmation from '../Containers/Confirmation'
import Footer from '../Components/Footer'
import Angeli from '../Containers/angeli'
import BrandProducts from '../Containers/Brand Products'
import BrandList from '../Containers/Brand List'
import Registration from '../Containers/Registration'
import TAC from '../Containers/Terms and Conditions'
import PrivacyPolicy from '../Containers/Privacy Policy'
import SoortData from '../Containers/Soort Data'
import BrandFilters from '../Containers/Brand Filters'
import HomeSec from '../Containers/HomeSec'
import SoortFilters from '../Containers/Soort Filters'
import Sales from '../Containers/Sales'
import SalesFilters from '../Containers/Sales Filters'
import reactRouterToArray from 'react-router-to-array';
import SearchData from '../Containers/Search Data'
import Sorting from '../Components/Sorting'
import SearchFilters from '../Containers/Search Filters'
import BrandsShow from '../Templates/BrandsShow'
import EditProfile from '../Containers/Edit Profile'
import OrderCancellation from '../Containers/Order Cancellation'
import NavbarSecond from '../Components/NavbarSecond'
import CompleteNavbar from '../Components/CompleteNavbar'
import Reservationlist from '../Containers/Reservation List'

const CreateBrowserHistory = require("history").createBrowserHistory
const history = CreateBrowserHistory()

export class Routes extends Component {
    render() {
        let allMenus = localStorage.getItem("headerData")
        let filtered = []
        if (allMenus) {
            let parsed = JSON.parse(allMenus)
            filtered = parsed.menus.main_manus.filter((e) => {
                return e.id === "giftcard"
            })
        }
        const allRoutes = reactRouterToArray(
            <Router history={history}>
                <Route exact path="/" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/registration" component={Registration} />
                <Route path="/terms-and-conditions" component={TAC} />
                {filtered.length > 0 && <Route path="/giftcard" component={HomeSec} />}
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/sales" component={Sales} />
            </Router>)
        localStorage.setItem("allRoutes", JSON.stringify(allRoutes))
        return (
            <div>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/admin/key/:id" component={Home} />
                        <Route exact path="/searches/:string" component={SearchData} />
                        <Route path="/searches/:string/:filtersData" component={SearchFilters} />
                        {/* <Route path="/duplicate-for-menu" component={HomeDuplicateForMenu} /> */}
                        <Route path="/bath-body" component={BathBody} />
                        <Route exact path="/bath/body" component={BathBody} />
                        <Route path="/collection-story/:csid/:nestedid" component={CollectionStory} />
                        <Route path="/cart" component={Cart} />
                        <Route exact path="/soort/:brandname" component={SoortData} />
                        <Route path="/soort/:brandname/:filtersData" component={SoortFilters} />
                        <Route path="/account" component={Account} />
                        <Route path="/account-edit-profile" component={EditProfile} />
                        <Route path="/product-detail/:maincategory/:cildcategory/:productsku" component={ProductDetail} />
                        <Route path="/duplicate-home" component={HomeDuplicate} />
                        <Route path="/collection-story-jing" component={CollectionStoryJing} />
                        <Route path="/order-history" component={Orders} />
                        <Route path="/reservation-list" component={Reservationlist} />
                        {/* <Route path="/address-list" component={Addresses} /> */}
                        <Route path="/newsletter-subscription" component={Newsletter} />
                        <Route path="/checkout" component={Checkout} />
                        <Route path="/payment" component={Payment} />
                        <Route path="/login" component={Login} />
                        <Route path="/delivery" component={Delivery} />
                        <Route path="/order-confirmation/:orderid" component={OrderConfirmation} />
                        <Route path="/angeli-di-bosca" component={Angeli} />
                        <Route exact path="/brand-products/:categoryid/:brandname" component={BrandProducts} />
                        <Route path="/brand-products/:categoryid/:brandname/:filtersData" component={BrandFilters} />
                        {/* <Route path="/about" component={About} /> */}
                        <Route path="/:blid/merken" component={BrandList} />
                        <Route path="/registration" component={Registration} />
                        <Route path="/terms-and-conditions" component={TAC} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/giftcard" component={HomeSec} />
                        <Route exact path="/sales" component={Sales} />
                        <Route path="/sales/:filtersData" component={SalesFilters} />
                        <Route path="/menu-sorting" component={Sorting} />
                        <Route path="/brnadsshow" component={BrandsShow} />
                        <Route path="/order-cancellation" component={OrderCancellation} />
                        <Route path="/mainnavwork" component={CompleteNavbar} />
                        <Route path="/:pagename" key={Math.random()} component={Home} />
                    </Switch>
                    <Footer />
                </Router>
            </div>
        )
    }
}

export default Routes