import React, { Component } from 'react'
import { gv } from '../Components/globalvar'

export class ProductCollectionData extends Component {

    constructor() {
        super()
        this.state = {
            data: '',
            product: [],
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })

        let browserType = ""
        // Broswe Detections
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
                this.setState({ browserType: "chrome" })
            } else {
                browserType = "safari"
                this.setState({ browserType: "safari" })
            }
        }

        if (!this.props.gettingData) {

        } else {
            var formdata = new FormData();
            if (this.props.data.product_sku.length > 0) {
                formdata.append("product_skus", JSON.stringify(this.props.data.product_sku));
            }
            formdata.append("product_type", this.props.data.product_type);
            formdata.append("layout_type", "layout_1");
            formdata.append("main_category", this.props.data.main_category);
            formdata.append("brand_id", this.props.data.brand_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        if (result.data.data[0].short_desc) {
                            if (result.data.data[0].short_desc.length > 199) {
                                result.data.data[0].short_desc = `${result.data.data[0].short_desc.substr(0, 200)} ....`
                            }
                        }
                        if (result.data.data[0].long_desc) {
                            if (result.data.data[0].long_desc.length > 199) {
                                result.data.data[0].long_desc = `${result.data.data[0].long_desc.substr(0, 200)} ....`
                            }
                        }
                        this.setState({ product: result.data.data })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    render() {
        return (
            <div className="second-content-last-cs" style={{ margin: "0px auto", marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top && 0, width: this.props.data.full_width ? "100%" : "80%" }}>
                <div className="img-secc-last-cs" style={{ backgroundImage: `url(${this.state.data.left_image})` }} >
                </div>
                <div className="content-secc-last-cs" data-aos="fade-left">
                    <div className="slider-inner-wrap">
                        <h3>{this.state.data.heading}</h3>
                        <p>{this.state.data.desc}</p>
                        {this.state.product.length > 0 && this.state.product.map((e) => {
                            return <div className="card-cslc" key={Math.random()} onClick={() => {
                                window.location.href = `/product-detail/${e.main_category.toLowerCase()}/${e.brand_id}/${e.plain_sku}`
                            }}>
                                <div className="card-cs-img">
                                    <img src={e.image_url} alt="pi" width="100%" height="100%" />
                                </div>
                                <div className="content-cslc">
                                    <span style={{ textTransform: "uppercase" }} className="heading-hss">{e.name}</span>
                                    {e.short_desc ? <h1>{e.short_desc}</h1> : <h1>{e.long_desc}</h1>}
                                    {!!e && e.sale_price && Number(e.sale_price) !== 0 && <p style={{ textDecorationLine: "line-through", fontSize: 18, color: "#b2b2b2" }} >€ {e.show_retail_price}</p>}
                                    {!!e && e.sale_price && Number(e.sale_price) !== 0 ? <p>€ {e.sale_price}</p> : !!e && <p>€ {e.show_retail_price}</p>}
                                    {this.state.data.is_right_button && <button>{this.state.data.right_text}</button>}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductCollectionData
