import React, { Component } from 'react'
import NavbarSecond from './NavbarSecond'
import NavbarSecondMobile from './NavbarSecondMobile'

export class CompleteNavbar extends Component {

    constructor() {
        super()
        this.state = {
            isMobile: false,
        }
    }

    componentDidMount() {
        if (window.innerWidth > 768) {
            this.setState({ isMobile: false })
        } else {
            this.setState({ isMobile: true })
        }
    }

    render() {
        return (
            this.state.isMobile ? <NavbarSecondMobile /> : <NavbarSecond />
        )
    }
}

export default CompleteNavbar
