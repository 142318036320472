import React, { Component } from 'react'
import FilterListIcon from '@material-ui/icons/FilterList';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import './index.css'
import SearchIcon from '@material-ui/icons/Search';
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import Navbar from '../../Components/Navbar';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

AOS.init();

export class Sales extends Component {

    constructor() {
        super()
        this.state = {
            transition: false,
            modal: false,
            thirdBlock: false,
            fourthBlock: false,
            priceLow: true,
            completeData: [],
            stopData: true,
            nextUrl: '',
            bottom: false,
            popisOpened: false,
            totalItems: 0,
            itemsPerPage: 0,
            currentPage: 1,
            loadingMore: false,
            bannerImage: '',
            title: '',
            desc1: '',
            desc2: '',
            nextDataShouldCome: true,
            brandname: "",
            loaderOnly: false,
            maincategoryid: '',
            // noData: false,
            filterData: [],
            sortBy: '',
            inputProduct: '',
            filterListArr: [],
            filterArr: [],
            newVal: '',
            browserType: "",
            price_from: '',
            price_to: '',
            filtersLeft: false,
            name: false,
            newest: false,
            noData: false,
            popupheight: false,
            // Editing content working
            is_deleting: false,
            isAdmin: false,
        }
        this.getData = this.getData.bind(this)
        this.getNextData = this.getNextData.bind(this)
        // this.getDataHeader = this.getDataHeader.bind(this)
        this.getFilterData = this.getFilterData.bind(this)
    }

    async componentDidMount() {
        this.getData()
        // this.getDataHeader()
        window.onscroll = () => {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 1700)) {
                if (this.state.inputSearch) {
                    if (!this.state.stopData && this.state.nextUrlF !== null && this.state.nextUrlF !== "undefined") {
                        this.getNextData()
                        this.setState({ loadingMore: true })
                    }
                } else {
                    if (!this.state.stopData && this.state.nextUrl !== null && this.state.nextUrl !== "undefined") {
                        this.getNextData()
                        this.setState({ loadingMore: true })
                    }
                }
            }
        };

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        // Broswe Detections
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                this.setState({ browserType: "chrome" })
            } else {
                this.setState({ browserType: "safari" })
            }
        }
        // Broswe Detections

        // Header Working
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let resp = JSON.parse(headerData)
            this.setState({ logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus) })
        }

        let data = JSON.parse(localStorage.getItem("sales"))
        if (data) {
            this.setState({ nextUrl: data.next_page_url, totalItems: data.total, itemsPerPage: data.per_page, currentPage: data.current_page, bannerImage: data.banner_image, title: data.title, desc1: data.desc1, desc2: data.desc2, is_deleting: data.check_banner_image })
            let completeData = this.state.completeData
            data.data.map((e) => {
                completeData.push(e)
                return this.setState({ completeData, stopData: false, nextDataShouldCome: true })
            })
        }

        let dataFilter = JSON.parse(localStorage.getItem(`sales/filters`))
        if (dataFilter) {
            this.setState({ filterData: dataFilter })
        }

        localStorage.setItem("priceto", '')
        localStorage.setItem("pricefrom", '')
        localStorage.setItem("pricesorting", '')

    }

    getData() {
        this.getFilterData('normal')
        this.state.completeData = []
        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        fetch(`${gv}/api/v1/products/sales?browser_type=${browserType}`)
            .then(response => response.json())
            .then(result => {
                let resp = result.data
                localStorage.setItem("sales", JSON.stringify(resp))
                this.setState({ nextUrl: resp.next_page_url, pagetitle: resp.page_title, totalItems: resp.total, itemsPerPage: resp.per_page, currentPage: resp.current_page, bannerImage: resp.banner_image, title: resp.title, desc1: resp.desc1, desc2: resp.desc2, is_deleting: resp.check_banner_image })
                this.setState({ completeData: resp.data, stopData: false, nextDataShouldCome: true, browserType })
            })
            .catch(error => console.log('error', error));
    }

    getNextData() {
        let completeData = this.state.completeData
        let filterArr = this.state.filterArr
        let newData = ''
        if (this.state.newest === true) {
            newData = 'yes'
        } else {
            newData = 'no'
        }
        let nameData = ''
        if (this.state.name === true) {
            nameData = 'yes'
        } else {
            nameData = 'no'
        }
        if (this.state.nextDataShouldCome && !this.state.inputSearch) {
            this.setState({ stopData: true })
            if (this.state.priceSorting) {
                fetch(`${this.state.nextUrl}&browser_type=${this.state.browserType}&sort_by_price=${this.state.priceSorting}&sort_by_new=${newData}&sort_by_name=${nameData}`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data
                        if (filterArr.length > 0) {
                            resp.data.forEach(element => {
                                filterArr.push(element)
                            })
                            this.setState({ filterArr, stopData: false, loadingMore: false, currentPage: resp.current_page, nextUrl: resp.next_page_url })
                        } else {
                            resp.data.forEach(element => {
                                completeData.push(element)
                            })
                            this.setState({ completeData, stopData: false, loadingMore: false, currentPage: resp.current_page, nextUrl: resp.next_page_url })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                fetch(`${this.state.nextUrl}&sort_by_new=${newData}&sort_by_name=${nameData}&browser_type=${this.state.browserType}`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data
                        if (filterArr.length > 0) {
                            resp.data.forEach(element => {
                                filterArr.push(element)
                            })
                            this.setState({ filterArr, stopData: false, loadingMore: false, currentPage: resp.current_page, nextUrl: resp.next_page_url })
                        } else {
                            resp.data.forEach(element => {
                                completeData.push(element)
                            })
                            this.setState({ completeData, stopData: false, loadingMore: false, currentPage: resp.current_page, nextUrl: resp.next_page_url })
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            this.setState({ stopData: true })
            fetch(`${this.state.nextUrlF}&all-search=${this.state.inputProduct}&sort_by_new=${newData}&sort_by_name=${nameData}&browser_type=${this.state.browserType}`)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    if (filterArr.length > 0) {
                        resp.data.forEach(element => {
                            filterArr.push(element)
                        })
                        this.setState({ filterArr, stopData: false, loadingMore: false, currentPageF: resp.current_page, nextUrlF: resp.next_page_url })
                    } else {
                        resp.data.forEach(element => {
                            completeData.push(element)
                        })
                        this.setState({ completeData, stopData: false, loadingMore: false, currentPageF: resp.current_page, nextUrlF: resp.next_page_url })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    getFilterData(e) {
        let filterData = []
        if (e === "normal") {
            fetch(`${gv}/api/v1/get/products/sales/filters`)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    let objectresp = Object.entries(resp)
                    objectresp.map((e) => {
                        let arr = []
                        if (e[0] !== "Merken") {
                            e[1].map((f) => {
                                let obj = {
                                    name: f.value,
                                    total_products: f.total_products,
                                    checked: false,
                                }
                                arr.push(obj)
                            })
                            let attributes = {
                                attribute: e[0],
                                arr,
                                popupopen: false,
                            }
                            filterData.push(attributes)
                        } else {
                            e[1].map((f) => {
                                let obj = {
                                    name: f.name,
                                    brand_id: f.brand_id,
                                    checked: false,
                                }
                                arr.push(obj)
                            })
                            let attributes = {
                                attribute: e[0],
                                arr,
                                popupopen: false,
                            }
                            filterData.push(attributes)
                        }
                        localStorage.setItem(`sales/filters`, JSON.stringify(filterData))
                        this.setState({ filterData })
                    })
                })
                .catch(error => console.log('error', error));
        } else {
            fetch(`${gv}/api/v1/get/products/sales/filters?price_from=${this.state.price_from}&price_to=${this.state.price_to}`)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    let objectresp = Object.entries(resp)
                    objectresp.map((e) => {
                        let arr = []
                        e[1].map((f) => {
                            let obj = {
                                name: f.value,
                                total_products: f.total_products,
                                checked: false,
                            }
                            arr.push(obj)
                        })
                        let attributes = {
                            attribute: e[0],
                            arr,
                            popupopen: false,
                        }
                        filterData.push(attributes)
                        localStorage.setItem(`${this.props.match.params.categoryid}/${this.props.match.params.brandname}/filters`, JSON.stringify(filterData))
                        this.setState({ filterData })
                    })
                })
                .catch(error => console.log('error', error));
        }
    }

    findProductsInput(e) {
        this.setState({ loadingMore: false, inputProduct: e.target.value })
        if (e.target.value === "") {
            this.setState({ filterArr: [], totalItemsF: '', inputSearch: false, })
        } else {
            fetch(`${gv}/api/v1/products/sales?all-search=${e.target.value}&browser_type=${this.state.browserType}`)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    this.setState({ filterArr: resp.data, totalItemsF: resp.total, itemsPerPageF: resp.per_page, currentPageF: resp.current_page, inputSearch: true, nextUrlF: resp.next_page_url, noData: false })
                })
                .catch(error => console.log('error', error));
        }
    }

    openPop(id) {
        this.state.filterData.map((e) => {
            e.popupopen = false
        })
        let filterData = this.state.filterData
        filterData[id].popupopen = true
        this.setState({ filterData: this.state.filterData })
        if (window.innerWidth < 768) {
            this.setState({ popupheight: true })
        }
        document.getElementById("popupoverlayd").style.display = "block"
    }

    closePop(id) {
        document.getElementById("popupoverlayd").style.display = "none"
        let filterData = this.state.filterData
        filterData[id].popupopen = false
        if (window.innerWidth < 768) {
            this.setState({ popupheight: false })
        }
        this.setState({ filterData: this.state.filterData })
    }

    showSearch(close, title, input, lastClose, search) {
        document.getElementById(close).style.display = "none"
        document.getElementById(title).style.display = "none"
        document.getElementById(search).style.display = "none"
        document.getElementById(input).style.display = "block"
        document.getElementById(lastClose).style.display = "block"
    }

    closeSearch(close, title, input, lastClose, search) {
        document.getElementById(close).style.display = "inline-block"
        document.getElementById(title).style.display = "inline-block"
        document.getElementById(search).style.display = "inline-block"
        document.getElementById(input).style.display = "none"
        document.getElementById(lastClose).style.display = "none"
    }

    closeAllPops() {
        this.state.filterData.map((e) => {
            e.popupopen = false
        })
        if (window.innerWidth < 768) {
            this.setState({ popupheight: false })
        }
        this.setState({ filterData: this.state.filterData })
        document.getElementById("popupoverlayd").style.display = "none"
    }

    componentWillUnmount() {
        this.setState({ menuOpen: false })
    }

    sortDataFilter() {
        this.state.filterData.map((e) => {
            e.popupopen = false
        })
        this.setState({ filterData: this.state.filterData })
        document.getElementById("popupoverlayd").style.display = "none"
        var inputElements = [].slice.call(document.querySelectorAll('.checkboxessortname'));
        var inputElementsMer = [].slice.call(document.querySelectorAll('.checkboxesmerkenname'));
        var checkedValue = inputElements.filter(chk => chk.checked);
        var checkedValueMer = inputElementsMer.filter(chk => chk.checked);
        let arr = []
        let arrMer = []
        let arrNames = []
        let wholeListData = []
        this.state.filterData.map((e) => {
            e.arr.filter((f) => {
                if (e.attribute !== "Merken") {
                    checkedValue.map((i) => {
                        if (i.value === f.name) {
                            let newVal = e.attribute.split(' ').join('-')
                            arrNames.push(newVal)
                        }
                    })
                }
            })
        })
        let unique = [...new Set(arrNames)];
        checkedValue.map((e) => {
            arr.push(e.value)
            wholeListData.push(e.value)
        })
        checkedValueMer.map((e) => {
            arrMer.push(e.value)
            wholeListData.push(e.value)
        })
        let priceSorting = "low"
        if (this.state.priceLow === true) {
            priceSorting = "low"
        } else {
            priceSorting = "high"
        }
        localStorage.setItem("priceSorting", JSON.stringify(priceSorting))
        if (arr.length > 0 || arrMer.length > 0) {
            localStorage.setItem(`filterData/sales`, JSON.stringify(this.state.filterData))
            localStorage.setItem(`filterListArr/sales`, JSON.stringify(arr))
            localStorage.setItem(`filterListArrMerken/sales`, JSON.stringify(arrMer))
            localStorage.setItem(`wholeListData/sales`, JSON.stringify(wholeListData))
            if (this.state.price_from && this.state.price_to) {
                localStorage.setItem(`sales/price_to`, JSON.stringify(this.state.price_to))
                localStorage.setItem(`sales/price_from`, JSON.stringify(this.state.price_from))
                window.location.href = `/sales/sortby=${unique}&sort_value=${arr}&price_from=${this.state.price_from}&price_to=${this.state.price_to}&sort_by_price=${priceSorting}`
            } else {
                if (arrMer.length > 0 && arr.length > 0) {
                    window.location.href = `/sales/sortby=${unique}&sort_value=${arr}&brand_id=${arrMer}&sort_by_price=${priceSorting}`
                } else if (arrMer.length > 0) {
                    window.location.href = `/sales/brand_id=${arrMer}&sort_by_price=${priceSorting}`
                } else {
                    window.location.href = `/sales/sortby=${unique}&sort_value=${arr}&sort_by_price=${priceSorting}`
                }
            }
        } else {
            this.getFilterData('normal')
            this.setState({ filterListArr: [], filterArr: [], filtersLeft: false })
        }
    }

    checkInputChange(e, i) {
        this.state.filterData[i].arr.filter((f) => {
            if (f === e) {
                e.checked = !e.checked
            }
        })
        this.setState({ filterData: this.state.filterData })
    }

    applyFilters() {
        const { price_to, price_from, name, newest, priceLow } = this.state
        if (price_to > price_from || name === true || newest === true || priceLow) {
            this.setState({ filtersLeft: true })
        }
        let newData = ''
        if (newest === true) {
            newData = 'yes'
        } else {
            newData = 'no'
        }
        let nameData = ''
        if (name === true) {
            nameData = 'yes'
        } else {
            nameData = 'no'
        }
        let priceSorting = "low"
        if (priceLow === true) {
            priceSorting = "low"
        } else {
            priceSorting = "high"
        }
        if (price_to && price_from) {
            localStorage.setItem("pricefrom", this.state.price_from)
            localStorage.setItem("priceto", this.state.price_to)
            localStorage.setItem("priceSorting", JSON.stringify(priceSorting))
            localStorage.setItem(`filterData/sales`, [])
            localStorage.setItem(`filterListArr/sales`, [])
            localStorage.setItem(`filterListArrMerken/sales`, [])
            localStorage.setItem(`wholeListData/sales`, [])
            window.location.href = `/sales/price_from=${this.state.price_from}&price_to=${this.state.price_to}&sort_by_price=${priceSorting}`
        } else {
            fetch(`${gv}/api/v1/products/sales?browser_type=${this.state.browserType}&sort_by_new=${newData}&sort_by_name=${nameData}&sort_by_price=${priceSorting}`)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    if (resp.data.length > 0) {
                        this.getFilterData("normal")
                        this.setState({ filterArr: resp.data, priceSorting, modal: false, currentPage: this.state.currentPage, nextDataShouldCome: true, nextUrl: resp.next_page_url, noData: false })
                    } else {
                        this.setState({ noData: true, modal: false, nextDataShouldCome: false, nextUrl: resp.next_page_url })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }


    imgUploading(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", "uitverkoop");
            formdata.append("brand_id", "uitverkoop");
            formdata.append("image_type", "banner");
            formdata.append("section_type", "uitverkoop_page_banner_image");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.setState({ bannerImage: result.data.image_url, is_deleting: true, loader: false })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImage() {
        this.setState({ loader: true })

        const { bannerImage } = this.state
        let splitted = bannerImage.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", "uitverkoop");
        formdata.append("brand_id", "uitverkoop");
        formdata.append("image_type", "banner");
        formdata.append("section_type", "uitverkoop_page_banner_image");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.setState({ bannerImage: result.data.default_url, is_deleting: false, loader: false })
                // this.setState({ is_deleting: false, loader: false })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loader: false })
            });
    }

    checkForDiscount(retail, sale) {
        let discount = retail - sale
        let discountPercentage = (discount / retail) * 100
        return `${Math.round(discountPercentage)} %`
    }

    clearSeparateFilters(e) {
        let currentWork = this.state.filterData[e]
        currentWork.arr.map((f) => {
            f.checked = false
        })
        this.setState({ filterData: this.state.filterData })
    }

    render() {
        // Navbar Working
        if (!!this.state.menuOpen) {
            document.getElementById("root").style.position = "fixed";
            document.getElementById("root").style.width = "100%";
        } else {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.width = "auto";
        }
        // Navbar Working
        return (
            <div style={{ backgroundColor: "#fbfbfb", paddingBottom: 60 }} ref={r => this.reference = r}>
                {/* Navbar Working */}
                <Helmet>
                    <title>{this.state.pagetitle}</title>
                </Helmet>
                <Navbar />
                {/* Navbar Working */}
                < div style={{ position: "fixed", width: "100%", height: "100%", top: 100, right: 0, left: 0, bottom: 0, display: "none" }} id="popupoverlayd" onClick={this.closeAllPops.bind(this)} >

                </div>
                <div className="bg-main-secondh" >
                    {!!this.state.bannerImage && <div
                        className="full-bg-hcs"
                        style={{
                            backgroundImage: `url(${this.state.bannerImage})`,
                            // transform: this.state.transition ? "scale(1.1)" : "scale(1)",
                            height: 400,
                            position: "relative"
                        }}>
                        {this.state.isAdmin && <div className="btn-editingUpload" style={{ top: 10, left: 10 }}>
                            <div className="uploading-beu">
                                <input type="file" onChange={(e) => this.imgUploading(e)} accept="image/*" />
                                <PublishIcon />
                            </div>
                            {this.state.is_deleting && <div className="uploading-beu">
                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                            </div>}
                        </div>}
                        {this.state.loader && <div className="loader-working-fb">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>}
                    </div>}
                </div>
                <div className="content-friendlist-main">
                    <div className="container brandproducts">
                        <div className="top-pl-main">
                            <div className="mobile-horizontal" id="mheight" style={{ height: this.state.popupheight ? 500 : "auto" }}>
                                {this.state.filterData.length > 0 && this.state.filterData.map((e, i) => {
                                    return <div style={{ position: "relative", display: "inline-block" }} className="popupbl" key={Math.random()}>
                                        <button style={{ backgroundColor: "#1A131A", textTransform: "uppercase", marginLeft: 0, border: "1px solid black", padding: "7px 10px", outline: "none", color: "white", fontWeight: "500" }} onClick={this.openPop.bind(this, i)}>
                                            {e.attribute}
                                        </button>
                                        <div className="popovercustom" style={{ opacity: e.popupopen ? 1 : 0, zIndex: e.popupopen ? 9999999 : 1, pointerEvents: e.popupopen ? "all" : "none" }}>
                                            <div className="content-dpc">
                                                <div className="inner-content-dpc">
                                                    <div className="close-btn-ci">
                                                        <CloseIcon onClick={this.closePop.bind(this, i)} id={`close${e.attribute}`} />
                                                        <p id={`title${e.attribute}`} className="bodyText" style={{ fontFamily: "Roboto" }}>{e.attribute}</p>
                                                        <input placeholder="search" id={`input${e.attribute}`} />
                                                        <CloseIcon className="closesearch" id={`closebtn${e.attribute}`} onClick={this.closeSearch.bind(this, `close${e.attribute}`, `title${e.attribute}`, `input${e.attribute}`, `closebtn${e.attribute}`, `searchbtn${e.attribute}`)} />
                                                        <SearchIcon className="search" id={`searchbtn${e.attribute}`} onClick={this.showSearch.bind(this, `close${e.attribute}`, `title${e.attribute}`, `input${e.attribute}`, `closebtn${e.attribute}`, `searchbtn${e.attribute}`)} />
                                                    </div>
                                                    <div className="calendar-inner">
                                                        <div className="inner-popoc-custom">
                                                            <ul className="list-ipc">
                                                                {e.arr.length > 0 && e.arr.map((f) => {
                                                                    if (e.attribute !== "Merken") {
                                                                        return <li key={Math.random()}>
                                                                            <label className="inner-lip-custom">
                                                                                <input type="checkbox" className="input-radio checkboxessortname" value={f.name}
                                                                                    onChange={this.checkInputChange.bind(this, f, i)}
                                                                                    checked={f.checked} />
                                                                                <span>
                                                                                    <font>{f.name}</font>
                                                                                    <font> {f.total_products}</font>
                                                                                </span>
                                                                            </label>
                                                                        </li>
                                                                    } else {
                                                                        return <li key={Math.random()}>
                                                                            <label className="inner-lip-custom">
                                                                                <input type="checkbox" className="input-radio checkboxesmerkenname" value={f.brand_id}
                                                                                    onChange={this.checkInputChange.bind(this, f, i)}
                                                                                    checked={f.checked} />
                                                                                <span>
                                                                                    <font>{f.name}</font>
                                                                                </span>
                                                                            </label>
                                                                        </li>
                                                                    }
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bottom-dpc">
                                                <div className="inner-bottom-dpc">
                                                    <button className="btn-ib-dpc" onClick={this.clearSeparateFilters.bind(this, i)}>
                                                        Wissen
                                                </button>
                                                    <div className="content-ib-dpc">
                                                        <button onClick={this.sortDataFilter.bind(this, e.attribute)}>Opslaan</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                {this.state.filterListArr.length > 0 && <div style={{ position: "relative", display: "inline-block" }} key={Math.random()} onClick={() => {
                                    this.setState({ filterArr: [], filterListArr: [] });
                                    this.state.filterData.map((e) => {
                                        e.arr.map((f) => {
                                            f.checked = false
                                        })
                                    })
                                }}>
                                    <button style={{ backgroundColor: "#1A131A", marginLeft: 0, border: "1px solid black", padding: "7px 10px", outline: "none", color: "white", fontWeight: "500" }}>
                                        Clear Filters
                                    </button>
                                </div>}
                                {this.state.filtersLeft && <div style={{ position: "relative", display: "inline-block" }} key={Math.random()} onClick={() => {
                                    this.setState({ filterArr: [], modal: false, filtersLeft: false, price_from: '', price_to: '', noData: false, name: false, newest: false });
                                    this.getFilterData('normal')
                                }}>
                                    <button style={{ backgroundColor: "#1A131A", marginLeft: 0, border: "1px solid black", padding: "7px 10px", outline: "none", color: "white", fontWeight: "500" }}>
                                        Clear Filters
                                    </button>
                                </div>}
                            </div>
                            <div className="left-icons-pl">
                                {this.state.totalItemsF ?
                                    <span className="bodyText" style={{ fontFamily: "Roboto", marginRight: 15, fontSize: 13 }}>{this.state.totalItemsF} ITEMS</span>
                                    :
                                    <span className="bodyText" style={{ fontFamily: "Roboto", marginRight: 15, fontSize: 13 }}>{this.state.totalItems} ITEMS</span>
                                }
                                <FilterListIcon onClick={() => {
                                    this.setState({ modal: true });
                                    this.state.filterData.map((e) => {
                                        e.popupopen = false
                                    })
                                    this.setState({ filterData: this.state.filterData })
                                    document.getElementById("popupoverlayd").style.display = "none"
                                }} style={{ position: "relative" }} />
                            </div>
                        </div>
                        {this.state.filterListArr.length > 0 && <div className="filter-list-arr">
                            <ul>
                                {this.state.filterListArr.map((e, i) => {
                                    return <li key={Math.random()}>
                                        <font>
                                            {e}
                                        </font>
                                        <CloseIcon onClick={this.cuttingfla.bind(this, i, e)} />
                                    </li>
                                })}
                            </ul>
                        </div>}
                        <div className="input-sec-productl" style={{ backgroundColor: "#f5f5f5", marginTop: 30 }}>
                            <input style={{ backgroundColor: "#f5f5f5" }} onChange={this.findProductsInput.bind(this)} value={this.state.inputProduct} />
                            <SearchIcon />
                        </div>
                        <div className="left-icons-pl mobilenonel">
                            {this.state.totalItemsF ?
                                <span className="bodyText" style={{ fontFamily: "Roboto", marginRight: 15, fontSize: 13 }}>{this.state.totalItemsF} ITEMS</span>
                                :
                                <span className="bodyText" style={{ fontFamily: "Roboto", marginRight: 15, fontSize: 13 }}>{this.state.totalItems} ITEMS</span>
                            }
                            <FilterListIcon onClick={() => this.setState({ modal: true })} style={{ position: "relative" }} />
                        </div>
                        <div className="all-product-list">
                            {this.state.completeData.length > 0 && this.state.filterArr.length <= 0 && !this.state.noData ? this.state.completeData.map((e) => {
                                return <div className="productcard-main" key={Math.random()} onClick={() => {
                                    localStorage.setItem(`filterData/sales`, [])
                                    localStorage.setItem(`filterListArr/sales`, [])
                                    window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                }}>
                                    {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <div className="discount-calculation">
                                        <font>{this.checkForDiscount(e.retail_price, e.sale_price)}</font>
                                    </div> : <div className="discount-calculation" style={{ backgroundColor: "transparent" }}>
                                            <font>&nbsp;</font>
                                        </div>}
                                    <div className="img-pcm">
                                        <img src={e.image_url} />
                                    </div>
                                    <div className="content-pcm">
                                        <span className="heading-hss" style={{ textTransform: "uppercase", fontFamily: "Roboto" }} className="bodyText">{e.name}</span>
                                        <h2 className="bodyText" style={{ fontFamily: "Roboto" }}>{e.plain_sku}</h2>
                                        <p className="short-desc-bi" style={{ marginBottom: 0 }}>{e.category_name}</p>
                                        {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <p className="short-desc-bi"><font style={{ textDecorationLine: "line-through", fontSize: 13 }}>€ {e.show_retail_price}</font></p> : <p className="short-desc-bi"><font>&nbsp;</font></p>}
                                        {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <button> € {e.show_sale_price}</button> : <button>€ {e.show_retail_price}</button>}
                                    </div>
                                </div>
                            }) : this.state.filterArr.map((e) => {
                                return <div className="productcard-main" key={Math.random()} onClick={() => {
                                    const { filterData, filterListArr } = this.state
                                    if (filterData.length > 0 && filterListArr.length > 0) {
                                        localStorage.setItem(`filterData/sales`, JSON.stringify(filterData))
                                        localStorage.setItem(`filterListArr/sales`, JSON.stringify(filterListArr))
                                    }
                                    window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                }}>
                                    {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <div className="discount-calculation">
                                        <font>{this.checkForDiscount(e.retail_price, e.sale_price)}</font>
                                    </div> : <div className="discount-calculation" style={{ backgroundColor: "transparent" }}>
                                            <font>&nbsp;</font>
                                        </div>}
                                    <div className="img-pcm">
                                        <img src={e.image_url} />
                                    </div>
                                    <div className="content-pcm">
                                        <span className="heading-hss" style={{ textTransform: "uppercase", fontFamily: "Roboto" }} className="bodyText">{e.name}</span>
                                        <h2 style={{ fontFamily: "Roboto" }} className="bodyText">{e.plain_sku}</h2>
                                        <p className="short-desc-bi" style={{ marginBottom: 0 }}>{e.category_name}</p>
                                        {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <p className="short-desc-bi"><font style={{ textDecorationLine: "line-through", fontSize: 13 }}>€ {e.show_retail_price}</font></p> : <p className="short-desc-bi"><font>&nbsp;</font></p>}
                                        {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <button> € {e.show_sale_price}</button> : <button>€ {e.show_retail_price}</button>}
                                    </div>
                                </div>
                            })}
                            {this.state.noData && <div className="nodatabp">
                                <p>No Filters Found</p>
                            </div>}
                        </div>
                        {this.state.loadingMore && <div className="loading-morebottom">
                            {!this.state.totalItemsF ? <p>{this.state.itemsPerPage * this.state.currentPage} of {this.state.totalItems} seen</p> : <p>{this.state.itemsPerPageF * this.state.currentPageF} of {this.state.totalItemsF} seen</p>}
                            <div className="loade-lmb">
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </div>
                        </div>}
                        {this.state.loaderOnly && <div className="loading-morebottom">
                            <div className="loade-lmb">
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className={`filter-flyin ${this.state.modal && "refinements-visible"}`}>
                    <div className="filter-overlay" onClick={() => this.setState({ modal: false })}>

                    </div>
                    <div className="filter-inner">
                        <div className="filter-header">
                            <button className="filter-x-close" onClick={() => this.setState({ modal: false })}>
                                <CloseIcon />
                            </button>
                            <h3>Filter</h3>
                        </div>
                        <div className="filter-holder">
                            <div className="filters-container">
                                <div className="filter-block" onClick={() => {
                                    this.setState({ newest: !this.state.newest })
                                }}>
                                    <input type="checkbox" id="checknew" checked={this.state.newest} onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ newest: true })
                                        } else {
                                            this.setState({ newest: false })
                                        }
                                    }} />
                                    <label>Sort by New</label>
                                </div>
                                <div className="filter-block" onClick={() => {
                                    this.setState({ name: !this.state.name })
                                }}>
                                    <input type="checkbox" id="checkname" checked={this.state.name} onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ name: true })
                                        } else {
                                            this.setState({ name: false })
                                        }
                                    }} />
                                    <label>Sort by Name</label>
                                </div>
                                <div className="filter-block">
                                    <button className="filter-block-title">
                                        PRICE RANGE
                                        <span className="icon-expanding" onClick={() => this.setState({ thirdBlock: !this.state.thirdBlock })}>
                                            {!this.state.thirdBlock ? <AddIcon style={{ fontSize: 28, color: "#453f3f" }} /> : <RemoveIcon style={{ fontSize: 28, color: "#453f3f" }} />}
                                        </span>
                                    </button>
                                    {this.state.thirdBlock && <div className="filter-options">
                                        <div className="filter-option-title">

                                        </div>
                                        <div className="price-holder">
                                            <div className="price-item-input">
                                                <label>€</label>
                                                <input type="number" style={{ width: 70 }} value={this.state.price_from} onChange={(e) => this.setState({ price_from: e.target.value })} />
                                            </div>
                                            <div className="price-item-input">
                                                <label>until</label>
                                                <input type="number" style={{ width: 70 }} value={this.state.price_to} onChange={(e) => this.setState({ price_to: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="filter-block">
                                    <button className="filter-block-title" onClick={() => this.setState({ priceLow: !this.state.priceLow, fourthBlock: !this.state.fourthBlock })}>
                                        {!this.state.fourthBlock ? "Price Low - High" : "Price High - Low"}
                                        <span className="icon-expanding">
                                            {!this.state.fourthBlock ? <KeyboardArrowUpIcon style={{ fontSize: 28, color: "#453f3f" }} /> : <KeyboardArrowDownIcon style={{ fontSize: 28, color: "#453f3f" }} />}
                                        </span>
                                    </button>
                                </div>
                                <div className="filter-user-actions">
                                    <button className="btn-clear-all" onClick={() => window.location.reload()}>TO CLEAR</button>
                                    <button className="btn-apply-all" onClick={this.applyFilters.bind(this)}>TO APPLY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.greaterSizeImage}
                    onClose={() => this.setState({ greaterSizeImage: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size exceeds 7MB</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default Sales
