import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import CK from '../Components/Ck'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

export class TextTemplateEdit extends Component {

    constructor() {
        super()
        this.state = {
            title_2: "",
            title_2Input: false,
            desc_2: "",
            desc_2Input: false,
            spaceTop: true,
            spaceBottom: true,
            createLoader: true,
        }
    }

    componentDidMount() {

        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)
            this.setState({ title_2: parsedData.title, desc_2: parsedData.desc, spaceTop: parsedData.space_top, spaceBottom: parsedData.space_bottom })
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { title_2, desc_2, wholeData } = this.state
        let obj = {
            type: "layout_15",
            title: title_2,
            desc: desc_2,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });

    }

    createMarkup() {
        if (this.state.desc_2) {
            return { __html: this.state.desc_2 };
        }
    }

    changeAlert(e) {
        this.setState({ desc_2: e })
    }

    close() {
        this.setState({ desc_2Input: false })
    }


    render() {
        return (
            <div>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="simple-text-block" style={{ marginBottom: 80, padding: 0, textAlign: "center" }}>
                    <div className="inner-wrapper">
                        {this.state.title_2 ? <h3 className="heading-h3" onClick={() => this.setState({ title_2Input: true })}>
                            {!this.state.title_2Input ? this.state.title_2 : <input style={{ textTransform: "uppercase", backgroundColor: "transparent", border: "none", outline: "none", width: "100%" }} value={this.state.title_2} autoFocus onChange={(e) => this.setState({ title_2: e.target.value })} onBlur={() => this.setState({ title_2Input: false })} />}
                        </h3> : <input style={{ textTransform: "uppercase", backgroundColor: "#e7e7e7", marginBottom: 10, width: "100%" }} value={this.state.title_2} autoFocus onChange={(e) => this.setState({ title_2: e.target.value, title_2Input: true })} onBlur={() => this.setState({ title_2Input: false })} />}
                        {this.state.desc_2 ? <p onClick={() => this.setState({ desc_2Input: true })} style={{ width: "100%" }}>
                            {!this.state.desc_2Input ? <div dangerouslySetInnerHTML={this.createMarkup()}></div> : <CK data={this.state.desc_2} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                        </p> : <CK data={this.state.desc_2} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                    </div>
                </div>
                {!this.state.createLoader && <div className="footer-allpopups-sections">
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default TextTemplateEdit
