import React, { Component } from 'react'

export class BannerData extends Component {

    constructor() {
        super()
        this.state = {
            data: '',
            isMobile: false,
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })

        if (window.innerWidth < 768) {
            this.setState({ isMobile: true })
        }

    }

    render() {
        return (
            this.state.data && <div
                className="full-bg-hcs"
                style={{
                    backgroundImage: this.props.data.banner_type === "image" && `url(${this.state.data.banner_image})`, margin: "0px auto",
                    width: this.props.data.full_width ? "100%" : "80%",
                    paddingBottom: this.props.data.banner_type === "video" && this.props.data.full_height && !this.state.isMobile ? 0 : this.props.data.banner_type === "video" && this.props.data.full_height && this.state.isMobile && 0,
                    marginBottom: this.props.data.banner_type === "video" && !this.props.data.full_height && 200,
                    minHeight: this.props.data.banner_type === "video" && !this.props.data.full_height && 500,
                    height: this.props.data.full_height && this.state.isMobile ? "calc(100vh - 60px)" : this.props.data.banner_type === "video" && !this.props.data.full_height ? 500 : this.props.data.full_height && !this.state.isMobile && "calc(100vh - 70px)",
                    marginTop: this.props.data.full_height && this.state.isMobile && 0,
                    marginBottom: !this.props.data.space_bottom ? 0 : 60,
                    marginTop: !this.props.data.space_top ? 0 : 60,
                    position: "relative",
                    overflow: "hidden"
                }}>
                {this.state.data.show_overlay && <div className="overlay-section-banner">

                </div>}
                {this.props.data.banner_type === "video" && <video src={this.state.data.banner_image} playsInline muted autoPlay loop style={{
                    position: "absolute", zIndex: -100, width: this.props.data.full_height && this.state.isMobile ? "234vh" : this.props.data.full_height ? "223vh" : "100%"
                }}></video>}
                {this.state.data.show_text && <span className={`the-ritual-title ${this.state.data.show_overlay && "overlayTextbg"}`} style={{ color: "white", textAlign: "center" }}>{this.state.data.heading}</span>}
                {this.state.data.show_text && <h2 className={this.state.data.show_overlay && "overlayTextbg"}>{this.state.data.desc}</h2>}
                {this.state.data.is_button_route && this.state.data.show_button && this.state.data.button_url !== "/undefined" && <button onClick={() => {
                    this.state.data.button_url.includes(".com") ? window.location.href = `https://${this.state.data.button_url}` : window.location.href = this.state.data.button_url
                }}>{this.state.data.buttonText}</button>}
            </div>
        )
    }
}

export default BannerData
