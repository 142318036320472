import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import FlipIcon from '@material-ui/icons/Flip';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import CK from '../Components/Ck';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class CollectieEdit extends Component {

    constructor() {
        super()
        this.state = {
            image_url: "",
            heading: "",
            headingInput: false,
            desc_1: "",
            descfInput: false,
            delete_image: false,
            wholeData: '',
            isFlip: false,
            imageUploading: false,
            crop: {
                // unit: '%',
                width: 713,
                height: 772,
                aspect: 16 / 9,
            },
            createLoader: true,
            spaceTop: false,
            spaceBottom: false,
        }
    }

    componentDidMount() {
        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)
            this.setState({
                heading: parsedData.heading, desc_1: parsedData.desc_1, image_url: parsedData.right_image,
                isFlipped: parsedData.is_flipped,
                spaceTop: parsedData.space_top,
                spaceBottom: parsedData.space_bottom,
            })

            let splitted = parsedData.right_image.split('/')
            if (splitted[splitted.length - 1] !== "layout_9_left_image.jpg") {
                this.setState({ delete_image: true })
            }
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    uploadImg(e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0] })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 712 && height > 850) {
                            let crop = {
                                unit: 'px',
                                width: 713,
                                height: 772,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        } else {
                            let crop = {
                                unit: 'px',
                                width: 713,
                                height: 620,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    removeImage() {
        this.setState({ imageUploading: true })

        const { image_url } = this.state
        let splitted = image_url.split("/")
        if (splitted[splitted.length - 1] !== "last.jpg") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_3");
            formdata.append("image_name", splitted[splitted.length - 1]);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_9_left_image.jpg", delete_image: false, imageUploading: false })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    saveSection() {
        const { heading, desc_1, image_url, wholeData, isFlipped } = this.state
        let obj = {
            type: "layout_9",
            heading,
            desc_1,
            right_image: image_url,
            is_flipped: this.state.isFlipped,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }


    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (file) {
            this.setState({ imageUploading: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_3");
            formdata.append("image", file);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.setState({ image_url: result.data.image_url, delete_image: true, imageUploading: false, croppedImageUrl: "", cropper: false })
                    } else {
                        this.setState({ imageUploading: false, croppedImageUrl: "", cropper: false })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    showCurrentImage() {
        this.setState({ cropper: false, image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_9_left_image.jpg", croppedImageUrl: "" })
    }

    createMarkup() {
        if (this.state.desc_1) {
            return { __html: this.state.desc_1 };
        }
    }

    changeAlert(e) {
        this.setState({ desc_1: e })
    }

    close() {
        this.setState({ descfInput: false })
    }

    render() {
        return (
            <div className="collectie-cr">
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="collectie-main sectiles" style={{ paddingRight: this.state.isFlipped && 150, flexDirection: this.state.isFlipped && "row-reverse", paddingBottom: 80 }}>
                        <div className="closer-working" onClick={() => this.setState({ headingInput: false, descfInput: false, descsInput: false, buttonInput: false })}>

                        </div>
                        <div className="right-cmain" style={{ backgroundImage: `url(${this.state.image_url})`, position: "relative" }}>
                            <div className="file-uploadimg" style={{ zIndex: 9999, left: "102%", top: 10 }}>
                                <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                                <PublishIcon style={{ color: "white" }} />
                            </div>
                            {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, left: "103%", top: 10 }}>
                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                            </div>}
                            {this.state.imageUploading && <div className="loader-working-fb">
                                <div className="lds-facebook"><div></div><div></div><div></div></div>
                            </div>}
                        </div>
                        <div className="left-cmain" style={{ zIndex: 999999, marginRight: this.state.isFlipped && -150 }}>
                            <div className="secchecks" style={{ width: "100%" }}>
                                {this.state.heading ? <h3 onClick={() => this.setState({ headingInput: true })} onBlur={() => this.setState({ headingInput: false })}>
                                    {!this.state.headingInput ? this.state.heading : <input autoFocus type="text" value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} />}
                                </h3> : <input type="text" style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value, headingInput: true })} onBlur={() => this.setState({ headingInput: false })} />}
                            </div>
                            <div className="secchecks" style={{ width: "100%" }}>
                                {!this.state.descfInput && this.state.desc_1 ? <div dangerouslySetInnerHTML={this.createMarkup()} onClick={() => this.setState({ descfInput: true })} onBlur={() => this.setState({ descfInput: false })}></div> : <CK data={this.state.desc_1} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                            </div>
                        </div>
                    </div>}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections">
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps" onClick={() => this.setState({ isFlipped: !this.state.isFlipped })}>
                        <FlipIcon />
                        <font>SPIEGELEN</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 713x772, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default CollectieEdit
