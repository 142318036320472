import React, { Component } from 'react'
import Navbar from '../../Components/Navbar'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Helmet } from "react-helmet";
import { gv } from '../../Components/globalvar'
import './index.css'
import Leftmenu from '../../Components/Leftmenu';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

export class Reservationlist extends Component {

    constructor() {
        super()
        this.state = {
            modal: false,
            wholeData: '',
            arrPopup: [],
            isAdmin: false,
            heading: "",
            headingInput: ""
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getData()

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        let loginData = localStorage.getItem("headerData")
        if (loginData) {
            loginData = JSON.parse(loginData)
            if (!loginData.onliny_payment) {
                window.location.href = "/"
            }
        }

    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType })

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };


            fetch(`${gv}/api/v1/auth/get/customer/reservations?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    console.log(resp)
                    this.setState({ wholeData: resp, heading: resp.heading })
                })
                .catch(error => console.log('error', error));
        }
    }

    updateSec() {
        const { heading } = this.state

        let arr = [
            { key: "order_history_page_heading", value: heading },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    render() {
        return (
            <div className="amdiv">
                <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>
                <Navbar />
                <div className="account-main">
                    <div className="account-wrapper">
                        <h1 className="account-title" style={{ display: "flex", alignItems: "center" }}>
                            <span className="icon-user">
                                <AccountCircleOutlinedIcon style={{ fontSize: 34 }} />
                            </span>
                            <font style={{ textTransform: "uppercase" }}>{this.state.wholeData.page_title}</font>
                        </h1>
                        <div className="account-left-content">
                            <Leftmenu />
                        </div>
                        <div className="account-right-content">
                            <div className="content-wrapper-rc">
                                <div className="section-title">
                                    <h2>
                                        <span>
                                            <ShoppingCartIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2 }} />
                                        </span>
                                        <span onClick={() => this.setState({ headingInput: true })}>
                                            {!this.state.headingInput ? this.state.heading : <input type="text" autoFocus value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                        </span>
                                    </h2>
                                </div>
                                <div className="orders-container">
                                    {this.state.wholeData && this.state.wholeData.booking_details.length > 0 ? this.state.wholeData.booking_details.map((e, i) => {
                                        console.log(e)
                                        return <div className="all-orders-list" key={Math.random()}>
                                            <div className="order-block">
                                                <div className="order-block-inner" style={{ padding: 20 }}>
                                                    <div className="order-details">
                                                        <div className="top-row">
                                                            <div className="order-detail-number">
                                                                <span>Datum: {e.booking_date}</span>
                                                            </div>
                                                            <div className="order-detail-number" style={{ marginBottom: 0 }}>
                                                                <span>Tijd: {e.time}</span>
                                                            </div>
                                                        </div>
                                                        {/* <div className="bottom-row">
                                                            <div className="order-detail-price">
                                                                <span>€ {e[1].total_price}</span>
                                                            </div>
                                                            <div className="order-detail-size">
                                                                <span>{e[1].total_products} products</span>
                                                            </div>
                                                            <div className="order-detail-status">
                                                                <span>Status: {e[1].status}</span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    {/* <div className="order-arrow">
                                                        <span>
                                                            <ExpandMoreIcon style={{ fontSize: 30 }} />
                                                        </span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    }) : <div className="no-orders">
                                            <p>There are no previous orders for this account.</p>
                                            <div className="btn-container" onClick={() => window.location.href = "/"}>
                                                <button>SHOP</button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default Reservationlist
