import React, { Component } from 'react'
import "./index.css";

export class CollectieData extends Component {

    constructor() {
        super()
        this.state = {
            data: '',
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })
    }


    createMarkup() {
        if (this.props.data.desc_1) {
            return { __html: this.props.data.desc_1 };
        }
    }

    render() {
        return (
            <div className="collectie-main" style={{ flexDirection: this.state.data.is_flipped && "row-reverse", marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                <div className="right-cmain">
                    <img src={this.state.data.right_image} alt="rightcollectie" />
                </div>
                <div className="left-cmain" style={{ marginRight: this.state.data.is_flipped && -150 }}>
                    <h3>{this.state.data.heading}</h3>
                    <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                </div>
            </div>
        )
    }
}

export default CollectieData
