import React, { Component } from 'react'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class CropperFile extends Component {

    constructor() {
        super()
        this.state = {
            crop: "",
            src: "",
        }
    }

    componentDidMount() {
        this.setState({ crop: this.props.crop, src: this.props.src })
    }

    componentWillReceiveProps(e) {
        this.setState({ crop: e.crop, src: e.src })
    }

    onCropComplete = crop => {
        this.props.makeClientCrop(crop);
        this.setState({ crop });
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    render() {
        return (
            <div>
                <ReactCrop
                    src={this.state.src}
                    crop={this.state.crop}
                    // ruleOfThirds
                    onImageLoaded={this.props.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                    locked={true}
                    keepSelection
                // maxHeight={500}
                />
                <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                    let elem = document.getElementsByClassName("ReactCrop__image")[0]
                    if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                        if (this.state.crop.unit === "%") {
                            this.state.crop.width = Number(this.state.crop.width) + 2
                            this.state.crop.height = Number(this.state.crop.height) + 2
                            let obj = {
                                unit: "%",
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        } else {
                            this.state.crop.width = Number(this.state.crop.width) + 25
                            this.state.crop.height = Number(this.state.crop.height) + 25
                            let obj = {
                                unit: "px",
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }
                }}>
                    <ZoomInIcon style={{ fontSize: 36 }} />
                </div>
                <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                    if (this.state.crop.unit === "%") {
                        this.state.crop.width = Number(this.state.crop.width) - 2
                        this.state.crop.height = Number(this.state.crop.height) - 2
                        let obj = {
                            unit: "%",
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                            y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                        }
                        this.setState({ crop: obj })
                    } else {
                        this.state.crop.width = Number(this.state.crop.width) - 25
                        this.state.crop.height = Number(this.state.crop.height) - 25
                        let obj = {
                            unit: "px",
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                            y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                        }
                        this.setState({ crop: obj })
                    }
                }}>
                    <ZoomOutIcon style={{ fontSize: 36 }} />
                </div>
            </div>
        )
    }
}

export default CropperFile
