import React, { Component } from 'react'

export class InputComponent extends Component {

    constructor() {
        super()
        this.state = {
            val: ""
        }
    }

    componentDidMount() {
        this.setState({ val: this.props.dataText })
    }


    render() {
        return (
            <input autoFocus value={this.state.val} onChange={(e) => this.setState({ val: e.target.value })} style={{ border: "none", outline: "none", width: 300 }} onBlur={() => this.props.blurInput(this.state.val)} />
        )
    }
}

export default InputComponent
