import React, { Component } from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export class SlidesData extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: [],
            arrInputs: [],
            spaceTop: true,
            spaceBottom: true,
        }
    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    componentDidMount() {
        this.setState({ wholeData: this.props.data.wholeData })
        let generateId = Date.now() + '' + this.randomStr(6)
        this.setState({ generateId })
    }

    createMarkup(e) {
        return { __html: e };
    }

    slideLeft() {
        let element = document.getElementById(`first-slider${this.state.generateId}`)
        element.scrollLeft -= 600
        this.setState({ slideLefting: true })
        if (element.scrollLeft > 250) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }

    slideRight() {
        let element = document.getElementById(`first-slider${this.state.generateId}`)
        element.scrollLeft += 600
        this.setState({ slideLefting: true })
        if (element.scrollLeft > -100) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }


    render() {
        return (
            <div>
                <div className="sixth-sec" id="projecten" style={{ padding: "20px 0px", marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60 }}>
                    {
                        this.state.leesmeerPopup && <div className="bg-darker">
                        </div>
                    }
                    {/* Lees meer */}
                    {
                        this.state.leesmeerPopup && <div className="lees-meer">
                            <CloseIcon className="close-ep" style={{ right: 10, cursor: "pointer", position: "absolute" }} onClick={() => this.setState({ leesmeerPopup: false })} />
                            <div className="img-lm">
                                <img src={this.state.leesMeerData.image_url} />
                            </div>
                            <div className="content-lm">
                                <h3 className="title" style={{ textAlign: "left" }}>
                                    {this.state.leesMeerData.heading_1}
                                </h3>
                                <h5 style={{ textAlign: "left" }}>{this.state.leesMeerData.heading_2}</h5>
                                {/* <p style={{ textAlign: "left" }}>{this.state.leesMeerData.desc}</p> */}
                                <div dangerouslySetInnerHTML={this.createMarkup(this.state.leesMeerData.desc)} style={{ textAlign: "left" }} ></div>
                            </div>
                        </div>
                    }
                    {/* Lees meer */}
                    {this.state.wholeData && this.state.wholeData.length > 3 && <ChevronLeftIcon className="slider-left" onClick={this.slideLeft.bind(this)} />}
                    {this.state.wholeData && this.state.wholeData.length > 3 && <ChevronRightIcon className="slider-right" onClick={this.slideRight.bind(this)} />}
                    <FiberManualRecordIcon className="slider-left mobileleft" onClick={this.slideLeft.bind(this)} />
                    <FiberManualRecordIcon className="slider-right mobileright" onClick={this.slideRight.bind(this)} />
                    <div className="all-cards-ss" id={`first-slider${this.state.generateId}`} style={{ marginTop: 50, justifyContent: this.state.wholeData.length < 4 && "center" }}>
                        {this.state.wholeData && this.state.wholeData.length > 0 && this.state.wholeData.map((e, i) => {
                            return <div className="card-ss" data-aos="fade-in" key={Math.random()} style={{ width: 500 }}>
                                <h3 className="title">
                                    {e.heading_1}
                                </h3>
                                <h5>
                                    {e.heading_2}
                                </h5>
                                <div dangerouslySetInnerHTML={this.createMarkup(e.descSec)}></div>
                                {/* <div dangerouslySetInnerHTML={this.createMarkup(e.descSec)} style={{ minHeight: 90 }}></div> */}
                                <div className="img-scss" style={{ backgroundImage: `url(${e.image_url})`, position: "relative" }}>
                                </div>
                                <button onClick={() => this.setState({ leesmeerPopup: true, leesMeerData: e, currentIndex: i })}>LEES MEER</button>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default SlidesData
