import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import FlipIcon from '@material-ui/icons/Flip';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ColorizeIcon from '@material-ui/icons/Colorize';
import { SketchPicker } from 'react-color'
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class FooterContact extends Component {

    constructor() {
        super()
        this.state = {
            left_title_1: "Demo title",
            left_title_1Input: false,
            left_title_2: "Demo title",
            left_title_2Input: false,
            left_image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_11_left_image.png",
            right_title: "CONTACT",
            left_title: "SOCIAL",
            phone_number: "",
            email: "123@gmail.com",
            address: "abc street",
            right_image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_11_right_image.png",
            spaceTop: true,
            spaceBottom: true,
            isFlipped: false,
            delete_image: false,
            delete_imageSec: false,
            crop: {
                // unit: '%',
                width: 460,
                height: 460,
                aspect: 16 / 9,
            },
            createLoader: true,
            footerFirstColour: "blue",
            footerSecondColour: "pink",
            currentIndex: 0,
            wholeDataContact: [],
        }
    }

    componentDidMount() {
        let headerData = localStorage.getItem("headerData")
        let accesstoken = localStorage.getItem("adminKey")

        if (headerData) {
            headerData = JSON.parse(headerData)
            this.setState({ phone_number: headerData.phone, email: headerData.email, linkedInUrl: headerData.linked_in_url, fb_url: headerData.fb_url, insta_url: headerData.instagram_url, })

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/shops/locations`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let data = result.data.shop_locations[this.state.currentIndex]
                    this.setState({ address: data.section_address, city: data.city, zipCode: data.zipcode, wholeDataContact: result.data.shop_locations })
                })
                .catch(error => console.log('error', error));
        }


        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { left_title_1, left_title_2, left_image, wholeData, right_title, left_title, right_image } = this.state

        let obj = {
            type: "layout_11",
            left_title_1,
            left_title_2,
            left_image,
            right_title,
            left_title,
            right_image,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            is_flipped: this.state.isFlipped,
            leftColor: this.state.footerFirstColour,
            rightColor: this.state.footerSecondColour,
            currentIndex: this.state.currentIndex,
            city: this.state.city,
            address: this.state.address,
            zip_code: this.state.zipCode,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(Number(currentIndex) + 1, 0, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => console.log('error', error));
    }

    uploadImg(e, f) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0], selectedType: f })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (f === "left") {
                        if (width > height) {
                            if (width > 503 && height > 850) {
                                let crop = {
                                    unit: 'px',
                                    width: 503,
                                    height: 510,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            } else {
                                let crop = {
                                    unit: 'px',
                                    width: 503,
                                    height: 310,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            }
                        } else {
                            let crop = {
                                unit: '%',
                                width: 100,
                                height: 80,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        if (width > height) {
                            if (width > 800 && height > 850) {
                                let crop = {
                                    unit: 'px',
                                    width: 729,
                                    height: 700,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            } else {
                                let crop = {
                                    unit: 'px',
                                    width: 723,
                                    height: 510,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            }
                        } else {
                            let crop = {
                                unit: '%',
                                width: 100,
                                height: 80,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("section_type", "layout_8");
        formdata.append("image", file);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    this.setState({ left_image: result.data.image_url, delete_image: true, cropper: false, croppedImageUrl: "" })
                }
            })
            .catch(error => console.log('error', error));
    }

    removeImage() {
        const { left_image } = this.state
        let splitted = left_image.split("/")
        if (splitted[splitted.length - 1] !== "layout_11_left_image.png") {
            this.setState({ loader: true })
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_8");
            formdata.append("image_name", splitted[splitted.length - 1]);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ left_image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_11_left_image.png", delete_image: false, loader: false, })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    doneImageSec() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("section_type", "layout_8");
        formdata.append("image", file);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.code === 200) {
                    this.setState({ right_image: result.data.image_url, delete_imageSec: true, cropper: false, croppedImageUrl: "" })
                }
            })
            .catch(error => console.log('error', error));
    }

    removeImageSec() {
        const { right_image } = this.state
        let splitted = right_image.split("/")
        if (splitted[splitted.length - 1] !== "layout_11_right_image.png") {
            this.setState({ loaderSec: true })
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_8");
            formdata.append("image_name", splitted[splitted.length - 1]);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ right_image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_11_right_image.png", delete_imageSec: false, loaderSec: false, })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    showCurrentImage() {
        this.setState({ cropper: false, croppedImageUrl: "" })
    }

    handleChangeComplete = (color) => {
        this.setState({ footerFirstColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteSec = (color) => {
        this.setState({ footerSecondColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    render() {
        return (
            <div className="footer-contact" style={{ paddingTop: 30 }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div style={{ zIndex: 9999 }}>
                    <div style={{ width: "100%", padding: 20 }}>
                        <select className="bodyText" style={{ width: "100%", padding: 10, textTransform: "uppercase", letterSpacing: 1 }} onChange={(e) => {
                            let data = this.state.wholeDataContact[Number(e.target.value)]
                            this.setState({ address: data.section_address, city: data.city, zipCode: data.zipcode, currentIndex: Number(e.target.value) })
                        }}>
                            {this.state.wholeDataContact.length > 0 && this.state.wholeDataContact.map((e, i) => {
                                return <option value={i} selected={this.state.currentIndex === i}>{e.city}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="overlay-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }}>

                </div>
                <div className="popup-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }} >
                    <CloseIcon style={{ position: "absolute", top: 20, right: 20 }} onClick={() => this.setState({ themePickerOpen: false })} />
                    <div className="color-working-popup">
                        <div className="first-div-cwp">
                            <label>Right section bottom colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.footerSecondColour}
                                onChangeComplete={this.handleChangeCompleteSec}
                            />
                        </div>
                        <div className="first-div-cwp">
                            <label>Right section top colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.footerFirstColour}
                                onChangeComplete={this.handleChangeComplete}
                            />
                        </div>
                    </div>
                </div>
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    {this.state.selectedType === "left" ? <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e, "left")} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div> : <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                            <input type="file" onChange={(e) => this.uploadImg(e, "right")} accept="image/*" />
                            <PublishIcon style={{ color: "white" }} />
                        </div>}
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        {this.state.selectedType === "left" ? <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div> : <div className="inner-faps" onClick={this.doneImageSec.bind(this)}>
                                <font>DONE</font>
                            </div>}
                    </div>
                </div> : <div className="seventh-sec-fc" id="contact">
                        <div className="contact-card-ss-fc" style={{ flexDirection: this.state.isFlipped && "row-reverse" }}>
                            <div className="left-ccss-fc" data-aos="fade-right">
                                {this.state.left_title_1 ? <h5 onClick={() => this.setState({ left_title_1Input: true })}>
                                    {!this.state.left_title_1Input ? this.state.left_title_1 : <input autoFocus value={this.state.left_title_1} onBlur={() => this.setState({ left_title_1Input: false })} onChange={(e) => this.setState({ left_title_1: e.target.value })} style={{ backgroundColor: "transparent", border: "none", outline: "none" }} />}
                                </h5> : <input autoFocus value={this.state.left_title_1} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onBlur={() => this.setState({ left_title_1Input: false })} onChange={(e) => this.setState({ left_title_1: e.target.value, left_title_1Input: true })} />}
                                {this.state.left_title_2 ? <h5 onClick={() => this.setState({ left_title_2Input: true })}>
                                    {!this.state.left_title_2Input ? this.state.left_title_2 : <input autoFocus value={this.state.left_title_2} onBlur={() => this.setState({ left_title_2Input: false })} onChange={(e) => this.setState({ left_title_2: e.target.value })} style={{ backgroundColor: "transparent", border: "none", outline: "none" }} />}
                                </h5> : <input autoFocus value={this.state.left_title_2} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onBlur={() => this.setState({ left_title_2Input: false })} onChange={(e) => this.setState({ left_title_2: e.target.value, left_title_2Input: true })} />}
                                <div className="img-lccss-fc" style={{ backgroundImage: `url(${this.state.left_image})`, position: "relative" }}>
                                    <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                                        <input type="file" onChange={(e) => this.uploadImg(e, "left")} accept="image/*" />
                                        <PublishIcon style={{ color: "white" }} />
                                    </div>
                                    {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                                        <DeleteIcon onClick={this.removeImage.bind(this)} />
                                    </div>}
                                    {this.state.loader && <div className="loader-working-fb">
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </div>}
                                </div>
                            </div>
                            <div className="right-ccss-fc" style={{ backgroundImage: `url(${this.state.right_image})`, position: "relative" }}>
                                <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                                    <input type="file" onChange={(e) => this.uploadImg(e, "right")} accept="image/*" />
                                    <PublishIcon style={{ color: "white" }} />
                                </div>
                                {this.state.delete_imageSec && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                                    <DeleteIcon onClick={this.removeImageSec.bind(this)} />
                                </div>}
                                {this.state.loaderSec && <div className="loader-working-fb">
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                </div>}
                                <div className="first-sccss-fc" style={{ backgroundColor: this.state.footerFirstColour }}>
                                    {this.state.right_title ? <h3 className="title" onClick={() => this.setState({ right_titleInput: true })}>
                                        {!this.state.right_titleInput ? this.state.right_title : <input autoFocus value={this.state.right_title} onBlur={() => this.setState({ right_titleInput: false })} onChange={(e) => this.setState({ right_title: e.target.value })} style={{ backgroundColor: "transparent", border: "none", outline: "none", color: "white" }} />}
                                    </h3> : <input autoFocus style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} value={this.state.right_title} onBlur={() => this.setState({ right_titleInput: false })} onChange={(e) => this.setState({ right_title: e.target.value, right_titleInput: true })} />}
                                    <ul>
                                        <li>
                                            <div>
                                                <img alt="phone" src="./assets/phone.png" />
                                            </div>
                                            
                                            <font><a style={{color: "#ffffff", textDecoration: 'none'}} href='tel:{this.state.phone_number}'>{this.state.phone_number}</a></font>
                                        </li>
                                        <li>
                                            <div>
                                                <img alt="mail" src="./assets/mail.png" />
                                            </div>

                                            <font><a style={{color: "#ffffff", textDecoration: 'none'}} href="mailto:{this.state.email}">{this.state.email}</a></font>
                                        </li>
                                        <li>
                                            <div>
                                                <img alt="location" src="./assets/location.png" />
                                            </div>
                                            <font>{this.state.address}</font>
                                        </li>
                                        <li style={{ paddingLeft: 35 }}>
                                            <font>{this.state.zipCode}</font>&nbsp;
                                            <font>{this.state.city}</font>
                                        </li>
                                    </ul>
                                </div>
                                <div className="second-sccss-fc" style={{ backgroundColor: this.state.footerSecondColour }}>
                                    {this.state.left_title ? <h3 className="title" onClick={() => this.setState({ left_titleInput: true })}>
                                        {!this.state.left_titleInput ? this.state.left_title : <input autoFocus value={this.state.left_title} onBlur={() => this.setState({ left_titleInput: false })} onChange={(e) => this.setState({ left_title: e.target.value })} style={{ backgroundColor: "transparent", border: "none", outline: "none", color: "white" }} />}
                                    </h3> : <input autoFocus style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} value={this.state.left_title} onBlur={() => this.setState({ left_titleInput: false })} onChange={(e) => this.setState({ left_title: e.target.value, left_titleInput: true })} />}
                                    <ul>
                                        {this.state.linkedInUrl && <li>
                                            <div style={{ marginRight: 10 }}>
                                                <img alt="insta" src="./assets/linkedin.png" style={{ maxWidth: 30 }} />
                                            </div>
                                            <font>Linkedin</font>
                                        </li>}
                                        {this.state.insta_url && <li>
                                            <div style={{ marginRight: 10 }}>
                                                <img alt="insta" src="./assets/instagram.png" style={{ maxWidth: 30 }} />
                                            </div>
                                            <font>Instagram</font>
                                        </li>}
                                        {this.state.fb_url && <li>
                                            <div style={{ marginRight: 10 }}>
                                                <img alt="insta" src="./assets/fb.png" style={{ maxWidth: 30 }} />
                                            </div>
                                            <font>Facebook</font>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections" style={{ bottom: -60 }}>
                    <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                        <ColorizeIcon />
                        <font>Button Colour</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps" onClick={() => this.setState({ isFlipped: !this.state.isFlipped })}>
                        <FlipIcon />
                        <font>SPIEGELEN</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    {this.state.selectedType === "left" ? <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 680x501, kindly provide valid image</DialogContentText>
                    </DialogContent> : <DialogContent>
                            <DialogContentText id="alert-dialog-description">Minimum size for this image should be 950x700, kindly provide valid image</DialogContentText>
                        </DialogContent>}
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default FooterContact
