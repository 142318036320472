import React, { Component } from 'react'

export class TwoTileData extends Component {

    constructor() {
        super()
        this.state = {
            data: '',
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })
    }

    createMarkup() {
        if (this.props.data.right_desc) {
            return { __html: this.props.data.right_desc };
        }
    }

    render() {
        return (
            <section className="two-tile-horizontal" style={{ padding: 0, marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                <div className="inner-wrapper" style={{ flexDirection: this.state.data.is_flipped ? "row-reverse" : "row" }} >
                    <div className="image-holder">
                        <img src={this.state.data.left_image} alt="about" />
                    </div>
                    <div className="text-holder">
                        <div className="text-inner">
                            <h3 className="product-name">{this.state.data.right_heading}</h3>
                            <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default TwoTileData
