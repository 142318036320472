import React, { Component } from 'react'

export class BrandsShowData extends Component {
    constructor() {
        super()
        this.state = {
            wholeData: [1, 2, 3, 4],
            section: [],
            top_image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_right_top_image.jpg",
            bottom_image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_right_bottom_image.jpg",
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })
    }

    render() {
        return (
            <div style={{ margin: "0px auto", marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, width: this.props.data.full_width ? "100%" : "80%" }}>
                <div className="brandsshow-main">
                    {this.props.data.left_content && this.props.data.center_content && <div className="bsm-mainf">
                        <div className="first-sec-bsm" style={{ backgroundImage: `url(${this.props.data.left_content.delete_image ? this.props.data.left_content.image : this.props.data.left_content.image[this.props.data.left_content.onImageIndex]})` }} onClick={() => {
                            this.props.data.button_url.includes(".com") ? window.location.href = `https://${this.props.data.button_url}` : window.location.href = this.props.data.button_url
                        }}>
                            <label className="label-bsm" style={{ backgroundColor: this.props.data.left_content.button_colour }} >{this.props.data.left_content.label}</label>
                        </div>
                        <div className="first-sec-bsm" style={{ backgroundImage: `url(${this.props.data.center_content.delete_image ? this.props.data.center_content.image : this.props.data.center_content.image[this.props.data.center_content.onImageIndex]})` }} onClick={() => {
                            this.props.data.button_urlCenter.includes(".com") ? window.location.href = `https://${this.props.data.button_urlCenter}` : window.location.href = this.props.data.button_urlCenter
                        }}>
                            <label className="label-bsm" style={{ backgroundColor: this.props.data.center_content.button_colour }} >{this.props.data.center_content.label}</label>
                        </div>
                    </div>}
                    {this.props.data.top_content && this.props.data.bottom_content && <div className="bsm-mains">
                        <div className="third-sec-bsm" style={{ backgroundImage: `url(${this.props.data.top_content.delete_image ? this.props.data.top_content.image : this.props.data.top_content.image[this.props.data.top_content.onImageIndex]})` }} onClick={() => {
                            this.props.data.button_urlTop.includes(".com") ? window.location.href = `https://${this.props.data.button_urlTop}` : window.location.href = this.props.data.button_urlTop
                        }}>
                            <label className="label-bsm" style={{ backgroundColor: this.props.data.top_content.button_colour }} >{this.props.data.top_content.label}</label>
                        </div>
                        <div className="fourth-sec-bsm" style={{ backgroundImage: `url(${this.props.data.bottom_content.delete_image ? this.props.data.bottom_content.image : this.props.data.bottom_content.image[this.props.data.bottom_content.onImageIndex]})` }} onClick={() => {
                            this.props.data.button_urlBottom.includes(".com") ? window.location.href = `https://${this.props.data.button_urlBottom}` : window.location.href = this.props.data.button_urlBottom
                        }}>
                            <label className="label-bsm" style={{ backgroundColor: this.props.data.bottom_content.button_colour }} >{this.props.data.bottom_content.label}</label>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

export default BrandsShowData
