import React, { Component } from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import { gv } from './globalvar'
import './index.css'

export class Footer extends Component {

    constructor() {
        super()
        this.state = {
            fb_url: '',
            insta_url: '',
            email: '',
            store_name: '',
            blaiz_link: '',
            render: true
        }
    }

    componentDidMount() {
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let resp = JSON.parse(headerData)
            this.setState({ fb_url: resp.fb_url, insta_url: resp.instagram_url, email: resp.retailer_email, store_name: resp.store_name, blaiz_link: resp.blaiz_link, iconFav: resp.fav_icon })

            let favicon = document.querySelector('link[rel="icon"]')
            if (favicon) {
                favicon.href = resp.fav_icon;
                document.head.appendChild(favicon);
            }

            var myDynamicManifest = {
                "name": resp.retailer_name,
                "short_name": resp.retailer_name,
                "description": "Something dynamic",
                "icons": [
                    {
                        "src": resp.fav_icon,
                        "sizes": "64x64 32x32 24x24 16x16",
                        "type": "image/x-icon"
                    },
                    {
                        "src": resp.fav_icon,
                        "type": "image/png",
                        "sizes": "192x192"
                    },
                    {
                        "src": resp.fav_icon,
                        "type": "image/png",
                        "sizes": "512x512"
                    }
                ],
                "display": "standalone",
                "start_url": ".",
                "background_color": "#ffffff",
                "theme_color": "#000000",
            }
            const stringManifest = JSON.stringify(myDynamicManifest);
            const blob = new Blob([stringManifest], { type: 'application/json' });
            const manifestURL = URL.createObjectURL(blob);
            document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL)

            let theme_settings = JSON.parse(localStorage.getItem("theme_settings"))

            if (theme_settings) {
                let headerColor = theme_settings.header_background_color
                let footerColor = theme_settings.footer_background_color
                let menuColor = theme_settings.menu_background_color

                let hcc = document.getElementById("headercolourchanging")
                if (hcc) {
                    hcc.style.backgroundColor = headerColor
                    document.getElementById("headercolourchangingSec").style.backgroundColor = headerColor
                    document.getElementById("headercolourchangingTr").style.backgroundColor = headerColor
                    document.getElementById("headercolourchangingFr").style.backgroundColor = headerColor
                    document.getElementById("headercolourchangingFi").style.backgroundColor = headerColor
                }
                document.getElementById("footercolour").style.backgroundColor = footerColor

                let buttonColor = theme_settings.button_background_color
                let titleFont = theme_settings.title_font_family
                let bodyFont = theme_settings.body_font_famiy
                let productDetailF = theme_settings.product_detail_colour
                let productDetailS = theme_settings.product_detail_right_colour
                let menuFontColour = theme_settings.menu_font_colour
                let menuIconColour = theme_settings.menu_icon_colour
                let leftMenuFont = theme_settings.left_menu_font_colour
                let leftMenuSize = `${theme_settings.left_menu_font_size}px`
                let menuSize = `${theme_settings.menu_font_size}px`
                let menuHeight = `${theme_settings.menu_height}px`
                let saleLabel = theme_settings.sale_label_colour
                let menuIconMobile = theme_settings.menu_icon_mobile
                let brandPageColor = theme_settings.brand_page_colour
                let overlayColor = theme_settings.overlay_color
                let overlayTextColor = theme_settings.overlay_text_color
                let backgroundOpacity = theme_settings.background_opacity
                let menuColorClose = theme_settings.menu_close_colour
                let giftCardButtonsColor = theme_settings.gift_card_buttons_color
                let giftCardCartColor = theme_settings.gift_card_cart_color
                let giftCardTitleColor = theme_settings.gift_card_title_color
                let giftCardDescriptionColor = theme_settings.gift_card_desc_color

                document.write('<style type="text/css">	:root {  --header-main-color: ' + headerColor + '; --background-color-button: ' + buttonColor + '; --menu-color-close: ' + menuColorClose + '; --background-opacity: ' + backgroundOpacity + ';  --overlay-color: ' + overlayColor + ';  --overlay-text-color: ' + overlayTextColor + '; --brand-detail-page: ' + brandPageColor + '; --left-menu-font-colour: ' + leftMenuFont + '; --color-menu-font: ' + menuFontColour + '; --color-menu-icon: ' + menuIconColour + '; --background-color-header: ' + headerColor + '; --font-family-title: ' + titleFont + '; --font-family-body: ' + bodyFont + '; --product-detail-f: ' + productDetailF + '; --product-detail-s: ' + productDetailS + ';--left-menu-font-size: ' + leftMenuSize + ';--menu-font-size: ' + menuSize + ';--menu-height: ' + menuHeight + ';--menu-background: ' + menuColor + ';--label-background: ' + saleLabel + ';--menu-icon-mobile: ' + menuIconMobile + ';--gift-card-title: ' + giftCardTitleColor + ';--gift-card-desc: ' + giftCardDescriptionColor + ';--gift-card-cart: ' + giftCardCartColor + ';--gift-card-button: ' + giftCardButtonsColor + ';}</style>');
            }
        }
    }

    getData() {
        console.log("Get data work")
        // fetch(`${gv}/api/v1/list/of/values`)
        //     .then(response => response.json())
        //     .then(result => {
        //         let resp = result.data
        //         localStorage.setItem("headerData", JSON.stringify(resp))
        //         this.setState({ fb_url: resp.fb_url, insta_url: resp.instagram_url, email: resp.retailer_email, store_name: resp.store_name, blaiz_link: resp.blaiz_link, iconFav: resp.fav_icon })
        //         localStorage.setItem("theme_settings", JSON.stringify(resp.theme_settings))
        //         localStorage.setItem("tocp", true)
        //         window.location.reload()
        //     })
        //     .catch(error => console.log('error', error));
    }

    render() {
        return (
            this.state.render && <div className="footer-main" id="footercolour" style={{ position: "static" }}>
                {/* <Helmet>
                    <link rel="icon" href={this.state.iconFav} />
                </Helmet> */}
                <div className="content-footer-tab" style={{ left: 20, display: "flex", maxWidth: 500 }}>
                    <p onClick={() => window.location.href = "/terms-and-conditions"} style={{ cursor: "pointer" }} >VOORWAARDEN&nbsp;|&nbsp;</p>
                    <p onClick={() => window.location.href = "/privacy-policy"} style={{ cursor: "pointer" }}>PRIVACY POLICY</p>
                </div>
                <div className="icons-footer-tab">
                    {this.state.fb_url && <FacebookIcon style={{ color: "white", margin: 4, cursor: "pointer" }} onClick={() => window.open(this.state.fb_url, "_blank")} />}
                    {this.state.insta_url && <InstagramIcon style={{ color: "white", margin: 4, cursor: "pointer" }} onClick={() => window.open(this.state.insta_url, "_blank")} />}
                    <EmailIcon style={{ color: "white", margin: 4, cursor: "pointer" }} onClick={() => window.location.href = this.state.email} />
                </div>
                <div className="content-footer-tab" style={{ cursor: "pointer" }}>
                    <p onClick={() => window.location.href = this.state.blaiz_link} >{this.state.store_name}</p>
                </div>
            </div>
        )
    }
}

export default Footer
