import React, { Component } from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import './index.css'
import InputComponent from '../Components/InputComponent';
import CK from '../Components/Ck';
import { SketchPicker } from 'react-color'
import ColorizeIcon from '@material-ui/icons/Colorize';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import { gv } from '../Components/globalvar'
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import NoteAddIcon from '@material-ui/icons/NoteAdd';

export class FaqTemplateEdit extends Component {

    constructor() {
        super()
        this.state = {
            arrFaqs: [
                { title: "Demo title one", text: "<p>Demo Body</p>" },
                { title: "Demo title two", text: "<p>Demo Body Second</p>" },
                { title: "Demo title three", text: "<p>Demo Body</p>" },
                { title: "Demo title four", text: "<p>Demo Body</p>" },
                { title: "Demo title", text: "<p>Demo Body</p>" },
                { title: "Demo title", text: "<p>Demo Body</p>" },
                { title: "Demo title", text: "<p>Demo Body</p>" },
                { title: "Demo title", text: "<p>Demo Body</p>" },
                { title: "Demo title", text: "<p>Demo Body</p>" },
                { title: "Demo title", text: "<p>Demo Body</p>" },
            ],
            arrPops: [
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
                { titleInput: false, bodyInput: false },
            ],
            buttonColour: "#79a3a2",
            iconColour: "#fff",
            spaceTop: true,
            spaceBottom: true,
            createLoader: true,
        }
    }

    componentDidMount() {

        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)
            this.setState({
                arrFaqs: parsedData.wholeData,
                buttonColour: parsedData.button_colour,
                iconColour: parsedData.icon_colour,
                spaceTop: parsedData.space_top,
                spaceBottom: parsedData.space_bottom,
            })
        }

        let accesstoken = localStorage.getItem("adminKey")

        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    createMarkup(e) {
        return { __html: e };
    }

    closeInput(e, f) {
        this.state.arrFaqs[f].title = e
        this.state.arrPops[f].titleInput = false
        this.setState({ arrFaqs: this.state.arrFaqs, arrPops: this.state.arrPops })
    }

    changeAlert(e, f) {
        this.state.arrFaqs[e].text = f
        this.setState({ arrFaqs: this.state.arrFaqs })
    }

    close(e) {
        this.state.arrPops[e].bodyInput = false
        this.setState({ arrPops: this.state.arrPops })
    }

    handleChangeCompleteSec = (color) => {
        this.setState({ buttonColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeComplete = (color) => {
        this.setState({ iconColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    saveSection() {
        const { arrFaqs, wholeData } = this.state
        let obj = {
            type: "layout_16",
            wholeData: arrFaqs,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            button_colour: this.state.buttonColour,
            icon_colour: this.state.iconColour,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    deleteFaq(e) {
        this.state.arrFaqs.splice(e, 1)
        this.state.arrPops.splice(e, 1)
        this.setState({ arrFaqs: this.state.arrFaqs, arrPops: this.state.arrPops })
    }

    leftFaq(e) {
        let arrFaqs = this.state.arrFaqs
        arrFaqs.splice(e - 1, 0, arrFaqs[e])
        arrFaqs.splice(e + 1, 1)
        this.setState({ arrFaqs: this.state.arrFaqs })
    }

    rightFaq(e) {
        let arrFaqs = this.state.arrFaqs
        arrFaqs.splice(e + 2, 0, arrFaqs[e])
        arrFaqs.splice(e, 1)
        this.setState({ arrFaqs: this.state.arrFaqs })
    }


    leftFaq(e) {
        let arrFaqs = this.state.arrFaqs
        arrFaqs.splice(e - 1, 0, arrFaqs[e])
        arrFaqs.splice(e + 1, 1)
        this.setState({ arrFaqs: this.state.arrFaqs })
    }

    upFaq(e) {
        let arrFaqs = this.state.arrFaqs
        arrFaqs.splice(e - 2, 0, arrFaqs[e])
        arrFaqs.splice(e + 1, 1)
        arrFaqs.splice(e + 1, 0, arrFaqs[e - 1])
        arrFaqs.splice(e - 1, 1)
        this.setState({ arrFaqs: this.state.arrFaqs })
    }

    downFaq(e) {
        let arrFaqs = this.state.arrFaqs
        arrFaqs.splice(e + 2, 0, arrFaqs[e])
        arrFaqs.splice(e, 1)
        arrFaqs.splice(e, 0, arrFaqs[e + 2])
        arrFaqs.splice(e + 3, 1)
        this.setState({ arrFaqs: this.state.arrFaqs })
    }

    render() {
        return (
            <div className="faq-main" style={{ paddingTop: 40, minHeight: 500, marginBottom: 80, position: "relative" }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="overlay-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }}>

                </div>
                <div className="popup-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }} >
                    <CloseIcon style={{ position: "absolute", top: 20, right: 20 }} onClick={() => this.setState({ themePickerOpen: false })} />
                    <div className="color-working-popup">
                        <div className="first-div-cwp">
                            <label>Button colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.buttonColour}
                                onChangeComplete={this.handleChangeCompleteSec}
                            />
                        </div>
                        <div className="first-div-cwp">
                            <label>Icon colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.iconColour}
                                onChangeComplete={this.handleChangeComplete}
                            />
                        </div>
                    </div>
                </div>
                <div className="row faqacc">
                    {this.state.arrFaqs.map((e, i) => {
                        return <div className="card">
                            <div className="card-header">
                                <a>
                                    <p onClick={() => {
                                        this.state.arrPops[i].titleInput = true
                                        this.setState({ arrPops: this.state.arrPops })
                                    }}>
                                        {!this.state.arrPops[i].titleInput ? e.title : <InputComponent dataText={e.title} blurInput={(e) => this.closeInput(e, i)} />}
                                    </p>
                                    <div className="actions-faqwork">
                                        <DeleteIcon onClick={this.deleteFaq.bind(this, i)} />
                                        {i > 0 && <ChevronLeftIcon onClick={this.leftFaq.bind(this, i)} />}
                                        {i < this.state.arrFaqs.length - 1 && <ChevronRightIcon onClick={this.rightFaq.bind(this, i)} />}
                                        {i > 1 && <KeyboardArrowUpIcon onClick={this.upFaq.bind(this, i)} />}
                                        {i < this.state.arrFaqs.length - 2 && <KeyboardArrowDownIcon onClick={this.downFaq.bind(this, i)} />}
                                    </div>
                                    <div className="btn-iconc" style={{ backgroundColor: this.state.buttonColour }}>
                                        <a data-toggle="collapse" href={`#collapseExample${i}`} role="button" aria-expanded="false" aria-controls="collapseExample" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <AddCircleOutlineIcon style={{ fill: this.state.iconColour }} />
                                        </a>
                                    </div>
                                </a>
                            </div>
                            <div className="collapse" id={`collapseExample${i}`}>
                                <div className="card-body">
                                    {!this.state.arrPops[i].bodyInput ? <div dangerouslySetInnerHTML={this.createMarkup(e.text)} onClick={() => {
                                        this.state.arrPops[i].bodyInput = true
                                        this.setState({ arrPops: this.state.arrPops })
                                    }}></div> : <CK data={e.text} onChildChange={this.changeAlert.bind(this, i)} onChildBlur={this.close.bind(this, i)} />}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                {!this.state.createLoader && <div className="footer-allpopups-sections" style={{ bottom: -60 }}>
                    <div className="inner-faps" onClick={() => {
                        let objFaq = { title: "Demo title", text: "<p>Demo Body</p>" }
                        let obj = { titleInput: false, bodyInput: false }
                        this.state.arrFaqs.push(objFaq)
                        this.state.arrPops.push(obj)
                        this.setState({ arrFaqs: this.state.arrFaqs, arrPops: this.state.arrPops })
                    }}>
                        <NoteAddIcon />
                        <font>CREATE FAQ</font>
                    </div>
                    <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                        <ColorizeIcon />
                        <font>Button Colour</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default FaqTemplateEdit
