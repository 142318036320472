import React, { Component } from 'react'
import './index.css'
import logo from '../assets/logocheck.png'
import basket from '../assets/basketiconsec.png'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export class NavbarSecondMobile extends Component {

    constructor() {
        super()
        this.state = {
            menuOpen: false,
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%", position: "fixed", backgroundColor: "white" }} className="hidingfooter">
                <div className="navbar-main-sec-other">
                    <div className="top-nmso-mobile">
                        <div className="logo-tnmm">
                            <img src={logo} />
                            <p>WATCHME OFFICIAL DEALER</p>
                        </div>
                        <div className="content-tnmm">
                            <div className="icon-ctnmm">
                                <div className="basket-icon">
                                    <span>20</span>
                                </div>
                                <img src={basket} />
                                <p>Mandje</p>
                            </div>
                            <div className="icon-ctnmm">
                                <PermIdentityOutlinedIcon />
                                <p>Account</p>
                            </div>
                            {this.state.menuOpen ? <div className="icon-ctnmm" onClick={() => {
                                this.setState({ menuOpen: false, nestedMenuOpen: false })
                            }}>
                                <CloseIcon />
                                <p>Close</p>
                            </div> : <div className="icon-ctnmm" onClick={() => this.setState({ menuOpen: true })}>
                                    <MenuIcon />
                                    <p>Menu</p>
                                </div>}
                        </div>
                    </div>
                    <div className="bottom-nmso-mobile" style={{ position: this.state.menuOpen && "fixed", top: this.state.menuOpen && "90px", height: this.state.menuOpen && "100%" }}>
                        <div className="input-nmsom">
                            <input type="text" placeholder="Waar ben je naar op zoek?" />
                            <SearchIcon />
                        </div>
                        {this.state.nestedMenuOpen ? <div className="lists-menu-mobile nestedmobilemenu">
                            <button onClick={() => this.setState({ nestedMenuOpen: false })}><ArrowBackIosIcon />  Vorige pagina</button>
                            <ul>
                                <li>Home</li>
                                <li>nested Horloges</li>
                                <li>nested Horloges</li>
                                <li>nested Horloges</li>
                                <li>nested Horloges</li>
                                <li>nested Horloges</li>
                                <li>nested Horloges</li>
                                <li>nested Horloges</li>
                            </ul>
                        </div> : this.state.menuOpen && <div className="lists-menu-mobile">
                            <ul>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Home</li>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Horloges</li>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Horloges</li>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Horloges</li>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Horloges</li>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Horloges</li>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Horloges</li>
                                <li onClick={() => this.setState({ nestedMenuOpen: true })}>Horloges</li>
                            </ul>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default NavbarSecondMobile
