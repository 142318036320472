import React, { Component } from 'react'
import Navbar from '../../Components/Navbar'
import HttpsIcon from '@material-ui/icons/Https';
import CloseIcon from '@material-ui/icons/Close';
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import './index.css'
import DatePicker from "react-datepicker";
import DayPicker from 'react-day-picker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import "react-datepicker/dist/react-datepicker.css";
import 'react-day-picker/lib/style.css';
import CompleteNavbar from '../../Components/CompleteNavbar';

export class Cart extends Component {

    constructor() {
        super()
        this.state = {
            individualpopup: false,
            giftwrapping: false,
            addCode: false,
            cartData: '',
            getDataHeading: false,
            coupon_code: '',
            browserType: '',
            codePopup: false,
            deliveryOption: "home_delivery",
            selectedOption: "",
            arrCheck: [],
            arrPopupItems: [],
            allItemsChecked: false,
            gift_note: '',
            shouldUpdate: false,
            // Edit Working
            isAdmin: false,
            heading_1: "",
            heading_1Input: false,
            timingsExact: [],
            selectedTime: "",
            selectedDay: new Date(),
            selectedTimeAlert: false,
        }
        this.getData = this.getData.bind(this)
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    componentDidMount() {
        this.getData()

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }
    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType })

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(`${gv}/api/v1/auth/customer/get/shopping/cart?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.data.cart_items.length > 0) {
                        if (result.data.data.coupon_settings && result.data.data.coupon_settings.coupon_code) {
                            this.setState({ addCode: true, coupon_code: result.data.data.coupon_settings.coupon_code })
                        }
                        if (result.data.data.gift_settings && result.data.data.gift_settings.gift_type) {
                            this.setState({ giftwrapping: true, selectedOption: result.data.data.gift_settings.gift_type })
                        } else {
                            this.setState({ giftwrapping: false })
                        }
                        let arrCheck = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                checked: false,
                            }
                            arrCheck.push(obj)
                        })
                        let arrPopupItems = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                popupnotification: false,
                                popupaction: "",
                            }
                            arrPopupItems.push(obj)
                        })

                        this.setState({
                            cartData: result.data.data,
                            arrCheck,
                            arrPopupItems,
                            gift_note: result.data.data.gift_settings.gift_note_text,
                            getDataHeading: false,
                            heading_1: result.data.data.cart_heading_1,
                            deliveryOption: result.data.data.delivery_settings.delivery_option
                        })
                        if (result.data.data.gift_settings.gift_type === "wrap_individually") {
                            let giftProducts = JSON.parse(result.data.data.gift_settings.gift_products)
                            giftProducts.map((e) => {
                                result.data.data.cart_items.map((g, i) => {
                                    if (g.product_id === e) {
                                        this.state.arrCheck[i].checked = true
                                        this.setState({ arrCheck: this.state.arrCheck })
                                    }
                                })
                            })
                        }

                        if (result.data.data.order_timings) {
                            let parsed = JSON.parse(result.data.data.order_timings)
                            this.setState({ selectedDay: new Date(parsed.day), selectedTime: parsed.time })
                            let currentDateCheck = new Date(parsed.day)
                            let days = [];
                            let currentDay = new Date(parsed.day)
                            let date = new Date(currentDateCheck.getFullYear(), currentDateCheck.getMonth(), 1);
                            while (date.getMonth() === currentDay.getMonth()) {
                                days.push(new Date(date));
                                date.setDate(date.getDate() + 1);
                            }
                            var options = { weekday: 'long' };
                            let daysToClose = []
                            days.map((g) => {
                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                let timingsToRender = result.data.data.shop_timings
                                if (timingsToRender) {
                                    let objected = Object.entries(timingsToRender)
                                    let filtered = objected.filter((g) => {
                                        return g[0] === dutchName
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(g)
                                        }
                                    }
                                }
                            })
                            let arrOfDays = []
                            daysToClose.map((h, i) => {
                                arrOfDays.push(h.getDate())
                            })
                            if (currentDay.getMonth() === new Date().getMonth()) {
                                daysToClose.push({ before: new Date() })
                            }
                            this.setState({ daysToClose })
                            let currentDaySelected = currentDay
                            if (arrOfDays.includes(currentDay.getDate())) {
                                currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                let daysToClose = []
                                let openedDays = []
                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                    let timingsToRender = result.data.data.shop_timings
                                    if (timingsToRender) {
                                        let objected = Object.entries(timingsToRender)
                                        let filtered = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let filteredTimings = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let currenthour = date.getHours()
                                        if (date.getDate() === new Date().getDate()) {
                                            currenthour = new Date().getHours()
                                        }
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > currenthour
                                        })
                                        if (filteredTimings.length > 0) {
                                            if (filteredTimings[0][1] !== "Gesloten") {
                                                if (filtered.length > 0) {
                                                    if (filtered[0][1] === "Gesloten") {
                                                        daysToClose.push(date)
                                                    } else {
                                                        if (filteredMain.length === 0) {
                                                            daysToClose.push(date)
                                                        } else {
                                                            if (new Date().getMonth() === date.getMonth()) {
                                                                if (date.getDate() >= new Date().getDate()) {
                                                                    openedDays.push(date)
                                                                }
                                                            } else {
                                                                openedDays.push(date)
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                daysToClose.push(date)
                                            }
                                        } else {
                                            daysToClose.push(date)
                                        }
                                    }
                                }
                                currentDay = openedDays[0]
                                daysToClose.push({ before: openedDays[0] })
                                this.setState({ daysToClose })
                            }
                            this.setState({ selectedDay: currentDay, selectedDayDefault: currentDay })

                            // above good (closed days move further)

                            // Date WOrk
                            let currentDate = currentDay
                            let timingsExact = []
                            var options = { weekday: 'long' };
                            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                            let timingsToRender = result.data.data.shop_timings
                            if (timingsToRender) {
                                let objected = Object.entries(timingsToRender)
                                let filtered = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                if (filtered.length > 0) {
                                    let date = new Date(parsed.day)
                                    if (currentDate.getDate() === new Date(parsed.day).getDate()) {
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > new Date(parsed.day).getHours()
                                        })
                                        if (filteredMain.length === 0) {
                                            date.setDate(date.getDate() + 1)
                                            this.setState({ selectedDay: date })
                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                            filtered = objected.filter((g) => {
                                                return g[0] === dutchName
                                            })
                                        }
                                    }
                                    this.setState({ timingsExact: filtered[0][1] })
                                }
                            }
                        } else {
                            let days = [];
                            let currentDay = new Date()
                            let date = new Date();
                            while (date.getMonth() === currentDay.getMonth()) {
                                days.push(new Date(date));
                                date.setDate(date.getDate() + 1);
                            }
                            var options = { weekday: 'long' };
                            let daysToClose = []
                            days.map((g) => {
                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                let timingsToRender = result.data.data.shop_timings
                                if (timingsToRender) {
                                    let objected = Object.entries(timingsToRender)
                                    let filtered = objected.filter((g) => {
                                        return g[0] === dutchName
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(g)
                                        }
                                    }
                                }
                            })
                            let arrOfDays = []
                            daysToClose.map((h, i) => {
                                arrOfDays.push(h.getDate())
                            })
                            daysToClose.push({ before: currentDay })
                            this.setState({ daysToClose })
                            let currentDaySelected = currentDay
                            if (arrOfDays.includes(currentDay.getDate())) {
                                currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                let daysToClose = []
                                let openedDays = []
                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                    let timingsToRender = result.data.data.shop_timings
                                    if (timingsToRender) {
                                        let objected = Object.entries(timingsToRender)
                                        let filtered = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let filteredTimings = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let currenthour = date.getHours()
                                        if (date.getDate() === new Date().getDate()) {
                                            currenthour = new Date().getHours()
                                        }
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > currenthour
                                        })
                                        if (filteredTimings.length > 0) {
                                            if (filteredTimings[0][1] !== "Gesloten") {
                                                if (filtered.length > 0) {
                                                    if (filtered[0][1] === "Gesloten") {
                                                        daysToClose.push(date)
                                                    } else {
                                                        if (filteredMain.length === 0) {
                                                            daysToClose.push(date)
                                                        } else {
                                                            if (new Date().getMonth() === date.getMonth()) {
                                                                if (date.getDate() >= new Date().getDate()) {
                                                                    openedDays.push(date)
                                                                }
                                                            } else {
                                                                openedDays.push(date)
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                daysToClose.push(date)
                                            }
                                        } else {
                                            daysToClose.push(date)
                                        }
                                    }
                                }
                                currentDay = openedDays[0]
                                daysToClose.push({ before: openedDays[0] })
                                this.setState({ daysToClose })
                            }
                            this.setState({ selectedDay: currentDay, selectedDayDefault: currentDay })

                            // above good (closed days move further)

                            // Date WOrk
                            let currentDate = currentDay
                            let timingsExact = []
                            var options = { weekday: 'long' };
                            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                            let timingsToRender = result.data.data.shop_timings
                            if (timingsToRender) {
                                let objected = Object.entries(timingsToRender)
                                let filtered = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                if (filtered.length > 0) {
                                    let date = new Date()
                                    if (currentDate.getDate() === new Date().getDate()) {
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > new Date().getHours()
                                        })
                                        if (filteredMain.length === 0) {
                                            date.setDate(date.getDate() + 1)
                                            this.setState({ selectedDay: date })
                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                            filtered = objected.filter((g) => {
                                                return g[0] === dutchName
                                            })
                                        }
                                    }
                                    this.setState({ timingsExact: filtered[0][1] })
                                }
                            }
                        }
                    } else {
                        window.location.href = "/"
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            let cookie_id = localStorage.getItem("guestUser")
            fetch(`${gv}/api/v1/customer/get/shopping/cart?browser_type=${browserType}&cart_items_cookie=${cookie_id}`)
                .then(response => response.json())
                .then(result => {
                    if (result.data.data.cart_items.length > 0) {
                        if (result.data.data.coupon_settings && result.data.data.coupon_settings.coupon_code) {
                            this.setState({ addCode: true, coupon_code: result.data.data.coupon_settings.coupon_code })
                        }
                        if (result.data.data.gift_settings && result.data.data.gift_settings.gift_type) {
                            this.setState({ giftwrapping: true, selectedOption: result.data.data.gift_settings.gift_type })
                        } else {
                            this.setState({ giftwrapping: false })
                        }
                        let arrCheck = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                checked: false,
                            }
                            arrCheck.push(obj)
                        })
                        let arrPopupItems = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                popupnotification: false,
                                popupaction: "",
                            }
                            arrPopupItems.push(obj)
                        })

                        this.setState({
                            cartData: result.data.data,
                            arrCheck,
                            arrPopupItems,
                            gift_note: result.data.data.gift_settings.gift_note_text,
                            getDataHeading: false,
                            heading_1: result.data.data.cart_heading_1,
                            deliveryOption: result.data.data.delivery_settings.delivery_option
                        })
                        if (result.data.data.gift_settings.gift_type === "wrap_individually") {
                            let giftProducts = JSON.parse(result.data.data.gift_settings.gift_products)
                            giftProducts.map((e) => {
                                result.data.data.cart_items.map((g, i) => {
                                    if (g.product_id === e) {
                                        this.state.arrCheck[i].checked = true
                                        this.setState({ arrCheck: this.state.arrCheck })
                                    }
                                })
                            })
                        }

                        if (result.data.data.order_timings) {
                            let parsed = JSON.parse(result.data.data.order_timings)
                            this.setState({ selectedDay: new Date(parsed.day), selectedTime: parsed.time })
                            let currentDateCheck = new Date(parsed.day)
                            let days = [];
                            let currentDay = new Date(parsed.day)
                            let date = new Date(currentDateCheck.getFullYear(), currentDateCheck.getMonth(), 1);
                            while (date.getMonth() === currentDay.getMonth()) {
                                console.log(date)
                                days.push(new Date(date));
                                date.setDate(date.getDate() + 1);
                            }
                            var options = { weekday: 'long' };
                            let daysToClose = []
                            days.map((g) => {
                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                let timingsToRender = result.data.data.shop_timings
                                if (timingsToRender) {
                                    let objected = Object.entries(timingsToRender)
                                    let filtered = objected.filter((g) => {
                                        return g[0] === dutchName
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(g)
                                        }
                                    }
                                }
                            })
                            let arrOfDays = []
                            daysToClose.map((h, i) => {
                                arrOfDays.push(h.getDate())
                            })
                            if (currentDay.getMonth() === new Date().getMonth()) {
                                daysToClose.push({ before: new Date() })
                            }
                            this.setState({ daysToClose })
                            let currentDaySelected = currentDay
                            if (arrOfDays.includes(currentDay.getDate())) {
                                currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                let daysToClose = []
                                let openedDays = []
                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                    let timingsToRender = result.data.data.shop_timings
                                    if (timingsToRender) {
                                        let objected = Object.entries(timingsToRender)
                                        let filtered = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let filteredTimings = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let currenthour = date.getHours()
                                        if (date.getDate() === new Date().getDate()) {
                                            currenthour = new Date().getHours()
                                        }
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > currenthour
                                        })
                                        if (filteredTimings.length > 0) {
                                            if (filteredTimings[0][1] !== "Gesloten") {
                                                if (filtered.length > 0) {
                                                    if (filtered[0][1] === "Gesloten") {
                                                        daysToClose.push(date)
                                                    } else {
                                                        if (filteredMain.length === 0) {
                                                            daysToClose.push(date)
                                                        } else {
                                                            if (new Date().getMonth() === date.getMonth()) {
                                                                if (date.getDate() >= new Date().getDate()) {
                                                                    openedDays.push(date)
                                                                }
                                                            } else {
                                                                openedDays.push(date)
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                daysToClose.push(date)
                                            }
                                        } else {
                                            daysToClose.push(date)
                                        }
                                    }
                                }
                                currentDay = openedDays[0]
                                daysToClose.push({ before: openedDays[0] })
                                this.setState({ daysToClose })
                            }
                            this.setState({ selectedDay: currentDay, selectedDayDefault: currentDay })

                            // above good (closed days move further)

                            // Date WOrk
                            let currentDate = currentDay
                            let timingsExact = []
                            var options = { weekday: 'long' };
                            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                            let timingsToRender = result.data.data.shop_timings
                            if (timingsToRender) {
                                let objected = Object.entries(timingsToRender)
                                let filtered = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                if (filtered.length > 0) {
                                    let date = new Date(parsed.day)
                                    if (currentDate.getDate() === new Date(parsed.day).getDate()) {
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > new Date(parsed.day).getHours()
                                        })
                                        if (filteredMain.length === 0) {
                                            date.setDate(date.getDate() + 1)
                                            this.setState({ selectedDay: date })
                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                            filtered = objected.filter((g) => {
                                                return g[0] === dutchName
                                            })
                                        }
                                    }
                                    this.setState({ timingsExact: filtered[0][1] })
                                }
                            }
                        } else {
                            let days = [];
                            let currentDay = new Date()
                            let date = new Date();
                            while (date.getMonth() === currentDay.getMonth()) {
                                days.push(new Date(date));
                                date.setDate(date.getDate() + 1);
                            }
                            var options = { weekday: 'long' };
                            let daysToClose = []
                            days.map((g) => {
                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                let timingsToRender = result.data.data.shop_timings
                                if (timingsToRender) {
                                    let objected = Object.entries(timingsToRender)
                                    let filtered = objected.filter((g) => {
                                        return g[0] === dutchName
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(g)
                                        }
                                    }
                                }
                            })
                            let arrOfDays = []
                            daysToClose.map((h, i) => {
                                arrOfDays.push(h.getDate())
                            })
                            daysToClose.push({ before: currentDay })
                            this.setState({ daysToClose })
                            console.log(daysToClose)
                            let currentDaySelected = currentDay
                            if (arrOfDays.includes(currentDay.getDate())) {
                                currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                let daysToClose = []
                                let openedDays = []
                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                    let timingsToRender = result.data.data.shop_timings
                                    if (timingsToRender) {
                                        let objected = Object.entries(timingsToRender)
                                        let filtered = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let filteredTimings = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let currenthour = date.getHours()
                                        if (date.getDate() === new Date().getDate()) {
                                            currenthour = new Date().getHours()
                                        }
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > currenthour
                                        })
                                        if (filteredTimings.length > 0) {
                                            if (filteredTimings[0][1] !== "Gesloten") {
                                                if (filtered.length > 0) {
                                                    if (filtered[0][1] === "Gesloten") {
                                                        daysToClose.push(date)
                                                    } else {
                                                        if (filteredMain.length === 0) {
                                                            daysToClose.push(date)
                                                        } else {
                                                            if (new Date().getMonth() === date.getMonth()) {
                                                                if (date.getDate() >= new Date().getDate()) {
                                                                    openedDays.push(date)
                                                                }
                                                            } else {
                                                                openedDays.push(date)
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                daysToClose.push(date)
                                            }
                                        } else {
                                            daysToClose.push(date)
                                        }
                                    }
                                }
                                currentDay = openedDays[0]
                                daysToClose.push({ before: openedDays[0] })
                                this.setState({ daysToClose })
                            } else {
                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                let daysToClose = []
                                let openedDays = []
                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                    let timingsToRender = result.data.data.shop_timings
                                    let arrNotTime = []
                                    if (timingsToRender) {
                                        let objected = Object.entries(timingsToRender)
                                        let filtered = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        let filteredTimings = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        if (filteredTimings.length > 0) {
                                            if (filteredTimings[0][1] !== "Gesloten") {
                                                let currenthour = date.getHours()
                                                if (date.getDate() === new Date().getDate()) {
                                                    currenthour = new Date().getHours()
                                                }
                                                let filteredMain = filtered[0][1].filter((h) => {
                                                    let splitted = h.split(":")
                                                    return Number(splitted[0]) > currenthour
                                                })
                                                if (filtered.length > 0) {
                                                    if (filtered[0][1] === "Gesloten") {
                                                        daysToClose.push(date)
                                                    } else {
                                                        if (filteredMain.length === 0) {
                                                            daysToClose.push(date)
                                                        } else {
                                                            if (new Date().getMonth() === date.getMonth()) {
                                                                if (date.getDate() >= new Date().getDate()) {
                                                                    openedDays.push(date)
                                                                }
                                                            } else {
                                                                openedDays.push(date)
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                daysToClose.push(date)
                                            }
                                        } else {
                                            daysToClose.push(date)
                                        }
                                    }
                                }
                                console.log(openedDays)
                                currentDay = openedDays[0]
                                daysToClose.push({ before: openedDays[0] })
                                this.setState({ daysToClose })
                            }
                            this.setState({ selectedDay: currentDay, selectedDayDefault: currentDay })

                            // above good (closed days move further)

                            // Date WOrk
                            let currentDate = currentDay
                            let timingsExact = []
                            var options = { weekday: 'long' };
                            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                            let timingsToRender = result.data.data.shop_timings
                            if (timingsToRender) {
                                let objected = Object.entries(timingsToRender)
                                let filtered = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                if (filtered.length > 0) {
                                    let date = new Date()
                                    if (currentDate.getDate() === new Date().getDate()) {
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > new Date().getHours()
                                        })
                                        if (filteredMain.length === 0) {
                                            date.setDate(date.getDate() + 1)
                                            this.setState({ selectedDay: date })
                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                            filtered = objected.filter((g) => {
                                                return g[0] === dutchName
                                            })
                                        }
                                    }
                                    this.setState({ timingsExact: filtered[0][1] })
                                }
                            }
                        }
                    } else {
                        window.location.href = "/"
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    removeItem(e) {
        if (e.product_type === "gift") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("price", e.retail_price);
                formdata.append("product_type", "gift");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }

                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }

                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    applyCode() {
        if (this.state.coupon_code !== "") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("coupon_code", this.state.coupon_code);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/redeem/coupon?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.message === "Coupon is redeemed") {
                            let price = result.data.data.default_total
                            this.state.cartData.default_total = price
                            this.setState({ cartData: this.state.cartData, codePopup: false })
                            this.getData()
                        } else {
                            this.setState({ codePopup: true, coupon_wrong_message: result.data[0] })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("coupon_code", this.state.coupon_code);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/redeem/coupon?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.message === "Coupon is redeemed") {
                            let price = result.data.data.default_total
                            this.state.cartData.default_total = price
                            this.setState({ cartData: this.state.cartData, codePopup: false })
                            this.getData()
                        } else {
                            this.setState({ codePopup: true, coupon_wrong_message: result.data[0] })
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            this.setState({ codePopup: true, coupon_wrong_message: "Coupon should not be empty" })
        }
    }

    setDeliveryOption(e, time) {
        let usertoken = localStorage.getItem("usertoken")
        if (e === "collection_point") {
            let obj = {
                "day": `${this.state.selectedDay}`,
                "time": `${time}`
            }
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("delivery_option", e);
                formdata.append("order_timings", JSON.stringify(obj));

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("delivery_option", e);
                formdata.append("cart_items_cookie", cookie_id);
                formdata.append("order_timings", JSON.stringify(obj));

                console.log(formdata)
                console.log("working")

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("delivery_option", e);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("delivery_option", e);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    itemUpdate(e, f, g, indexOfItem) {
        if (e.product_type === "gift") {
            if (Number(g) > 1 && f === "subtraction") {
                this.setState({ getDataHeading: true })
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            } else if (f === "addition") {
                this.setState({ getDataHeading: true })
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })
                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                this.setState({ getDataHeading: true })
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", 1);
                    formdata.append("product_type", "gift");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }

                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.setState({ getDataHeading: false })
                            let resp = result.data.data
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", 1);
                    formdata.append("product_type", "gift");
                    formdata.append("cart_items_cookie", cookie_id);
                    formdata.append("price", e.retail_price);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }

                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.setState({ getDataHeading: false })
                            let resp = result.data.data
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else {
            if (Number(g) > 1 && f === "subtraction") {
                this.setState({ getDataHeading: true })

                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "product");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "product");
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            } else if (f === "addition") {
                this.setState({ getDataHeading: true })

                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "product");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "product");
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                this.setState({ getDataHeading: true })

                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("product_type", "product");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }
                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            this.setState({ getDataHeading: false })
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("product_type", "product");
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }
                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            this.setState({ getDataHeading: false })
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    checkGift(e) {
        if (e === "wrap_every_thing") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("gift_option", "wrap_every_thing");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("gift_option", "wrap_every_thing");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            let arr = []
            this.state.arrCheck.map((e, i) => {
                if (e.checked === true) {
                    let name = this.state.cartData.cart_items[i]
                    arr.push(name.product_id)
                }
            })
            if (arr.length > 0) {
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("gift_option", "wrap_individually");
                    formdata.append("gift_products", JSON.stringify(arr));

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.setState({ individualpopup: false })
                            document.getElementById("mainbody").style.position = "static"
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("gift_option", "wrap_individually");
                    formdata.append("gift_products", JSON.stringify(arr));
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.setState({ individualpopup: false })
                            document.getElementById("mainbody").style.position = "static"
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    sendGiftEmpty() {
        let usertoken = localStorage.getItem("usertoken")
        this.setState({ selectedOption: "" })
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var formdata = new FormData();
            formdata.append("gift_option", "empty");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    this.getData()
                    this.setState({ giftwrapping: false })
                })
                .catch(error => console.log('error', error));
        } else {
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("gift_option", "empty");
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    this.getData()
                    this.setState({ giftwrapping: false })
                })
                .catch(error => console.log('error', error));
        }
    }

    checkoutContinue() {
        let usertoken = localStorage.getItem("usertoken")
        if (this.state.gift_note === undefined) {
            this.state.gift_note = ""
        }
        if (this.state.deliveryOption === "collection_point") {
            if (this.state.selectedTime) {
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    if (!this.state.cartData.delivery_settings.delivery_option) {
                        var formdata = new FormData();
                        formdata.append("delivery_option", "home_delivery");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                            .then(response => response.json())
                            .then(() => {
                                fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                                    .then(response => response.json())
                                    .then(() => {
                                        window.location.href = "/delivery"
                                    })
                                    .catch(error => console.log('error', error));
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                            .then(response => response.json())
                            .then(() => {
                                window.location.href = "/delivery"
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    if (this.state.gift_note) {
                        formdata.append("gift_note_text", this.state.gift_note);
                    }
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    if (!this.state.cartData.delivery_settings.delivery_option) {
                        var formdataSec = new FormData();
                        formdataSec.append("delivery_option", "home_delivery");
                        formdataSec.append("cart_items_cookie", cookie_id);

                        var requestOptionsSec = {
                            method: 'POST',
                            body: formdataSec,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptionsSec)
                            .then(response => response.json())
                            .then(() => {
                                fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                                    .then(response => response.json())
                                    .then((result) => {
                                        window.location.href = "/delivery"
                                    })
                                    .catch(error => console.log('error', error));
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                            .then(response => response.json())
                            .then(() => {
                                window.location.href = "/delivery"
                            })
                            .catch(error => console.log('error', error));
                    }
                }
            } else {
                this.setState({ selectedTimeAlert: true })
            }
        } else {
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                if (!this.state.cartData.delivery_settings.delivery_option) {
                    var formdata = new FormData();
                    formdata.append("delivery_option", "home_delivery");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                                .then(response => response.json())
                                .then(() => {
                                    window.location.href = "/delivery"
                                })
                                .catch(error => console.log('error', error));
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            window.location.href = "/delivery"
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                if (this.state.gift_note) {
                    formdata.append("gift_note_text", this.state.gift_note);
                }
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                if (!this.state.cartData.delivery_settings.delivery_option) {
                    var formdataSec = new FormData();
                    formdataSec.append("delivery_option", "home_delivery");
                    formdataSec.append("cart_items_cookie", cookie_id);

                    var requestOptionsSec = {
                        method: 'POST',
                        body: formdataSec,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptionsSec)
                        .then(response => response.json())
                        .then(() => {
                            fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                                .then(response => response.json())
                                .then((result) => {
                                    window.location.href = "/delivery"
                                })
                                .catch(error => console.log('error', error));
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            window.location.href = "/delivery"
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    componentDidUpdate(props, state) {
        if (this.state.shouldUpdate) {
            setTimeout(() => {
                state.arrPopupItems.map((e) => {
                    if (e.popupnotification === true) {
                        e.popupnotification = false
                        e.popupaction = ""
                    }
                })
                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: false })
            }, 5000);
        }
    }

    removeCoupon() {
        let usertoken = localStorage.getItem("usertoken")

        if (!usertoken) {
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));

        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));
        }
    }

    getMonthNameinDutch(e) {
        e = e.toLowerCase()
        if (e === "monday") {
            return "MAANDAG"
        } else if (e === "tuesday") {
            return "DINSDAG"
        } else if (e === "wednesday") {
            return "WOENSDAG"
        } else if (e === "thursday") {
            return "DONDERDAG"
        } else if (e === "friday") {
            return "VRIJDAG"
        } else if (e === "saturday") {
            return "ZATERDAG"
        } else if (e === "sunday") {
            return "ZONDAG"
        }
    }

    handleDayClick(day) {
        let currentDate = new Date(day)
        let allDates = []
        this.state.daysToClose.map((g, i) => {
            if (i !== this.state.daysToClose.length - 1) {
                allDates.push(g.getDate())
            } else {
                if (i === this.state.daysToClose.length - 1) {
                    let objected = Object.entries(this.state.daysToClose[i])
                    if (objected.length > 0) {
                        if (objected[0][0] === "before") {
                            for (var i = 0; i < objected[0][1].getDate(); i++) {
                                allDates.push(i)
                            }
                        }
                    }
                }
            }
        })
        if (allDates.includes(currentDate.getDate())) {
            console.log("included")
        } else {
            var options = { weekday: 'long' };
            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
            let timingsToRender = this.state.cartData.shop_timings
            if (timingsToRender) {
                let objected = Object.entries(timingsToRender)
                let filtered = objected.filter((g) => {
                    return g[0] === dutchName
                })
                console.log(objected)
                console.log(filtered)
                if (filtered.length > 0) {
                    // let arr = []
                    // filtered[0][1].map((g) => {
                    //     let splitted = g.split(":")
                    //     arr.push(splitted[0])
                    // })
                    // let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                    // let arrSec = []
                    // allKeys.map((g) => {
                    //     let filteredSecond = filtered[0][1].filter((h) => {
                    //         let splitted = h.split(":")
                    //         return splitted[0] === g
                    //     })
                    //     arrSec.push(filteredSecond)
                    // })
                    this.setState({ timingsExact: filtered[0][1], selectedTime: "" })
                }
            }
            this.setState({ selectedDay: day, dateDropDown: false });
        }
    }

    render() {
        return (
            <div className="checkoutfooterhide">
                <Navbar getData={this.state.getDataHeading} removeItem={this.getData.bind(this)} />
                <Helmet>
                    <title>{this.state.cartData.page_title}</title>
                </Helmet>
                <div className="cart-main">
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            {/* layout left */}
                            <div className="page-layout-left">
                                <div className="cart-header">
                                    <h1>{this.state.heading_1}</h1>
                                    <span>{this.state.cartData.total_products} products</span>
                                </div>
                                <div className="cart-body">
                                    <table className="table-cb">
                                        <thead>
                                            <tr className="cart-product cp-head">
                                                <th className="cp-image cp-sec-head">PRODUCT</th>
                                                <th className="cp-desc cp-sec-head">&nbsp;</th>
                                                <th className="cp-qty cp-sec-head">Aantal</th>
                                                <th className="cp-price cp-sec-head">Prijs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.cartData && this.state.cartData.cart_items.map((e, i) => {
                                                return <tr className="cart-product cp-body" key={Math.random()}>
                                                    <td className="cp-image">
                                                        <img src={e.image_url} alt="cart" />
                                                    </td>
                                                    <td className="cp-desc">
                                                        <div className="cp-dtitle">
                                                            <div className="product-list-item">
                                                                <div className="name">
                                                                    <p>{e.name}</p>
                                                                </div>
                                                                <div className="description">
                                                                    <p>{e.plain_sku}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cp-qty">
                                                            <div className="quantity-block">
                                                                <button className="qty-minus" onClick={this.itemUpdate.bind(this, e, "subtraction", e.qty, i)}>-</button>
                                                                <input size="3" maxLength="3" className="qty-input" readOnly defaultValue={e.qty} />
                                                                <button style={{ marginBottom: 30 }} className="qty-plus" onClick={this.itemUpdate.bind(this, e, "addition", e.qty, i)}>+</button>
                                                                {this.state.arrPopupItems[i].popupaction === "addition" ? <span className="dropdown-err">
                                                                    Wij hebben helaas geen extra voorraad beschikbaar van dit artikel
                                                                </span> : this.state.arrPopupItems[i].popupaction === "addition" && <span>
                                                                    </span>}
                                                            </div>
                                                        </div>
                                                        <div className="cp-uc">
                                                            <div className="cp-ci">
                                                                <div className="item-user-actions">
                                                                    <button className="link link_large" onClick={this.removeItem.bind(this, e)}>
                                                                        <span>
                                                                            Verwijder
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="dangerousgoods-error">
                                                            <p className="error"></p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td className="cp-price" style={{ paddingTop: 15 }}>
                                                        {Number(e.sale_price) !== 0 ? <span className="price-value">€ {e.show_sale_price}</span> : <span className="price-value">€ {e.show_retail_price}</span>}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    {/* <div className="giftwrap-block">
                                        {this.state.cartData.check_wrapping !== 0 && <div className="giftwrap-block-toggle">
                                            <input type="checkbox" checked={this.state.giftwrapping} onChange={(e) => e.target.checked ? this.setState({ giftwrapping: true }) : this.sendGiftEmpty()} />
                                            <label>{this.state.cartData.gift_wrapping_text}</label>
                                        </div>}
                                        {this.state.giftwrapping && this.state.cartData.gift_option_1 && <div className="giftwrap-block-options">
                                            <div className="giftwrap-block-radio-wrapper">
                                                <div className="giftwrap-block-radio">
                                                    <input type="radio" name="giftwrap-type" checked={this.state.selectedOption === "wrap_every_thing"} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.checkGift("wrap_every_thing")
                                                            this.setState({ individualpopup: false, selectedOption: "wrap_every_thing" })
                                                        }
                                                    }} />
                                                    <label>
                                                        <span>{this.state.cartData.gift_option_1.value}</span>
                                                        <span className="gift-wrap-price">{this.state.cartData.gift_option_1.price}</span>
                                                    </label>
                                                </div>
                                                {this.state.cartData.cart_items.length > 1 && <div className="giftwrap-block-radio">
                                                    <input type="radio" name="giftwrap-type" className="radio-individual" checked={this.state.selectedOption === "wrap_individually"} onClick={() => {
                                                        document.getElementById("mainbody").style.position = "fixed"
                                                        document.getElementById("mainbody").style.width = "100%"
                                                        this.setState({ individualpopup: true, selectedOption: "wrap_individually" });
                                                    }} />
                                                    <label>
                                                        <span>{this.state.cartData.gift_option_2.value}</span>
                                                        <span className="gift-wrap-price">{this.state.cartData.gift_option_2.price}</span>
                                                    </label>
                                                </div>}
                                            </div>
                                            {this.state.cartData.show_gift_note === "yes" && <div className="giftwrap-block-personal-message">
                                                <label>{this.state.cartData.gift_note.value}</label>
                                                <div className="giftwrap-block-textarea">
                                                    <span className="line-block">
                                                        <span className="line"></span>
                                                        <span className="line"></span>
                                                        <span className="line"></span>
                                                        <span className="line"></span>
                                                        <span className="line"></span>
                                                        <span className="line"></span>
                                                        <span className="line"></span>
                                                    </span>
                                                    <textarea rows="4" maxLength="240" name="giftwrap-message" placeholder={this.state.cartData.gift_note.placeholder !== undefined && !!this.state.cartData.gift_note.placeholder && this.state.cartData.gift_note.placeholder} value={this.state.gift_note} onChange={(e) => this.setState({ gift_note: e.target.value })}></textarea>
                                                </div>
                                            </div>}
                                        </div>}
                                    </div> */}
                                    {!this.state.cartData.hide_delivery_text && <div className="gwp-cart-banner">
                                        <div className="gwp-banner-progress-holder">
                                            <span style={{ width: "59.9999%" }}></span>
                                        </div>
                                        <div>
                                            <div className="buttons-total">
                                                <button className="btn-continue" onClick={() => window.location.href = "/"}>VERDER WINKELEN</button>
                                                <p>{this.state.cartData.bottom_text}</p>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            {/* Layout Right */}
                            <div className="page-layout-right">
                                <div className="cs-service-block">

                                </div>
                                {/* Bottom Cart */}
                                <div className="ct-bottom bg-color-white">
                                    <div className="cart-footer">
                                        <div className="cart-order-totals">
                                            <div className="order-subtotal">
                                                <div className="ositem-name">
                                                    <span>SUBTOTAAL</span>
                                                </div>
                                                <div className="ositem-val">
                                                    <span>€ {this.state.cartData.total_retail_price}</span>
                                                </div>
                                            </div>
                                            <div className="shipping-options-ct">
                                                <div>
                                                    <div className="cart-delivery-options" onClick={() => {
                                                        this.setState({ deliveryOption: "home_delivery" })
                                                        this.setDeliveryOption("home_delivery")
                                                    }}>
                                                        <label className="cart-delivery-option-label">
                                                            <input type="radio" name="shippingOption" defaultChecked={this.state.cartData.delivery_settings && this.state.cartData.delivery_settings.delivery_option === "home_delivery" ? true : !this.state.cartData.delivery_settings && true} />
                                                            <span className="option-title">
                                                                <font>{this.state.cartData.delivery_desc_1}</font>
                                                            </span>
                                                            <span className="option-price">
                                                                {this.state.cartData.delivery_desc_1_price}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="cart-delivery-options" onClick={() => {
                                                        this.setState({ deliveryOption: "local_delivery" })
                                                        this.setDeliveryOption("local_delivery")
                                                    }}>
                                                        <label className="cart-delivery-option-label">
                                                            <input type="radio" name="shippingOption" defaultChecked={this.state.cartData.delivery_settings && this.state.cartData.delivery_settings.delivery_option === "local_delivery" && true} />
                                                            <span className="option-title">
                                                                <font>{this.state.cartData.delivery_desc_3}</font>
                                                            </span>
                                                            <span className="option-price">
                                                                {this.state.cartData.delivery_desc_3_price}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="cart-delivery-options" onClick={() => {
                                                        this.setState({ deliveryOption: "collection_point" })
                                                    }}>
                                                        <label className="cart-delivery-option-label">
                                                            <input type="radio" name="shippingOption" defaultChecked={this.state.cartData.delivery_settings && this.state.cartData.delivery_settings.delivery_option === "collection_point" && true} />
                                                            <span className="option-title">
                                                                <font>{this.state.cartData.delivery_desc_2}</font>
                                                            </span>
                                                            <span className="option-price">
                                                                {this.state.cartData.delivery_desc_2_price}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Gift Card Coupon */}
                                            <div className="coupon-giftcard-row">
                                                {this.state.cartData.check_coupon !== 0 && <div className="cart-coupon-code-trigger">
                                                    <label>Discount Code</label>
                                                    {!this.state.addCode && <button onClick={() => this.setState({ addCode: true })}>ADD CODE</button>}
                                                </div>}
                                                {this.state.addCode && <div className="field-wrapper" style={{ marginTop: 10 }}>
                                                    <input placeholder="Coupon code" type="text" value={this.state.coupon_code} onChange={(e) => this.setState({ coupon_code: e.target.value })} />
                                                    {this.state.codePopup && <span className="alert-fields">{this.state.coupon_wrong_message}</span>}
                                                    <button style={{
                                                        minWidth: "70%", padding: 10, margin: "0px auto", backgroundColor: "transparent", border: "1px solid #b4b4b4", letterSpacing: 2, color: "#1C1C1C", fontSize: 11, textTransform: "uppercase", marginTop: 15, fontFamily: "Roboto"
                                                    }} onClick={this.applyCode.bind(this)} className="bodyText">CODE TOEVOEGEN</button>
                                                </div>}
                                                {this.state.deliveryOption === "collection_point" && <div className="delivery-work" style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}>
                                                    <p style={{ marginBottom: 0, marginRight: 8, fontSize: 12, letterSpacing: 1 }}>Datum: </p>
                                                    <div className="timepickercart" onClick={() => this.setState({ dateDropDown: !this.state.dateDropDown })}>
                                                        <p>{this.state.selectedDay.getDate() + "-" + `${this.state.selectedDay.getMonth() + 1}` + "-" + this.state.selectedDay.getFullYear()}</p>
                                                        <ExpandMoreIcon />
                                                    </div>
                                                    {this.state.dateDropDown && <div className="inner-work-timepicker" style={{ top: "95%", zIndex: 99, maxHeight: "none" }}>
                                                        <DayPicker initialMonth={this.state.selectedDay} selectedDays={[
                                                            this.state.selectedDay
                                                        ]} fromMonth={new Date()} onDayClick={this.handleDayClick}
                                                            disabledDays={this.state.daysToClose}
                                                            onMonthChange={(e) => {
                                                                let result = this.state.cartData
                                                                let arrNotTime = []
                                                                if (result) {
                                                                    let currentDay = e
                                                                    let shop_timings = result.shop_timings
                                                                    if (shop_timings) {
                                                                        if (e.getMonth() === new Date().getMonth()) {
                                                                            let days = [];
                                                                            let date = new Date();
                                                                            while (date.getMonth() === currentDay.getMonth()) {
                                                                                days.push(new Date(date));
                                                                                date.setDate(date.getDate() + 1);
                                                                            }
                                                                            var options = { weekday: 'long' };
                                                                            let daysToClose = []
                                                                            days.map((g) => {
                                                                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                                                                let timingsToRender = result.shop_timings
                                                                                if (timingsToRender) {
                                                                                    let objected = Object.entries(timingsToRender)
                                                                                    let filtered = objected.filter((g) => {
                                                                                        return g[0] === dutchName
                                                                                    })
                                                                                    if (filtered.length > 0) {
                                                                                        if (filtered[0][1] === "Gesloten") {
                                                                                            daysToClose.push(g)
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                            let arrOfDays = []
                                                                            daysToClose.map((h, i) => {
                                                                                arrOfDays.push(h.getDate())
                                                                            })
                                                                            daysToClose.push({ before: currentDay })
                                                                            this.setState({ daysToClose })
                                                                            let currentDaySelected = currentDay
                                                                            if (arrOfDays.includes(currentDay.getDate())) {
                                                                                currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                                                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                                                let daysToClose = []
                                                                                let openedDays = []
                                                                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                                                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                                                    let timingsToRender = result.shop_timings
                                                                                    let arrNotTime = []
                                                                                    if (timingsToRender) {
                                                                                        let objected = Object.entries(timingsToRender)
                                                                                        let filtered = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        let filteredTimings = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        if (filteredTimings[0][1] !== "Gesloten") {
                                                                                            let currenthour = date.getHours()
                                                                                            if (date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()) {
                                                                                                currenthour = new Date().getHours()
                                                                                            }
                                                                                            let filteredMain = filtered[0][1].filter((h) => {
                                                                                                let splitted = h.split(":")
                                                                                                return Number(splitted[0]) > currenthour
                                                                                            })
                                                                                            if (filtered.length > 0) {
                                                                                                if (filtered[0][1] === "Gesloten") {
                                                                                                    daysToClose.push(date)
                                                                                                } else {
                                                                                                    if (filteredMain.length === 0) {
                                                                                                        daysToClose.push(date)
                                                                                                    } else {
                                                                                                        if (new Date().getMonth() === date.getMonth()) {
                                                                                                            if (date.getDate() >= new Date().getDate()) {
                                                                                                                openedDays.push(date)
                                                                                                            }
                                                                                                        } else {
                                                                                                            openedDays.push(date)
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        } else {
                                                                                            daysToClose.push(date)
                                                                                        }
                                                                                    }
                                                                                }
                                                                                currentDay = openedDays[0]
                                                                                this.setState({ daysToClose })
                                                                            } else {
                                                                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                                                let daysToClose = []
                                                                                let openedDays = []
                                                                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                                                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                                                    let timingsToRender = result.shop_timings
                                                                                    let arrNotTime = []
                                                                                    if (timingsToRender) {
                                                                                        let objected = Object.entries(timingsToRender)
                                                                                        let filtered = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        let filteredTimings = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        if (filteredTimings.length > 0) {
                                                                                            if (filteredTimings[0][1] !== "Gesloten") {
                                                                                                let currenthour = date.getHours()
                                                                                                if (date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()) {
                                                                                                    currenthour = new Date().getHours()
                                                                                                }
                                                                                                let filteredMain = filtered[0][1].filter((h) => {
                                                                                                    let splitted = h.split(":")
                                                                                                    return Number(splitted[0]) > currenthour
                                                                                                })
                                                                                                if (filtered.length > 0) {
                                                                                                    if (filtered[0][1] === "Gesloten") {
                                                                                                        daysToClose.push(date)
                                                                                                    } else {
                                                                                                        if (filteredMain.length === 0) {
                                                                                                            daysToClose.push(date)
                                                                                                        } else {
                                                                                                            if (new Date().getMonth() === date.getMonth()) {
                                                                                                                if (date.getDate() >= new Date().getDate()) {
                                                                                                                    openedDays.push(date)
                                                                                                                }
                                                                                                            } else {
                                                                                                                openedDays.push(date)
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            } else {
                                                                                                daysToClose.push(date)
                                                                                            }
                                                                                        } else {
                                                                                            daysToClose.push(date)
                                                                                        }
                                                                                    }
                                                                                }
                                                                                currentDay = openedDays[0]
                                                                                daysToClose.push({ before: openedDays[0] })
                                                                                this.setState({ daysToClose })
                                                                            }
                                                                            this.setState({ selectedDay: currentDay })

                                                                            // above good (closed days move further)

                                                                            // Date WOrk
                                                                            let currentDate = currentDay
                                                                            let timingsExact = []
                                                                            var options = { weekday: 'long' };
                                                                            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                                                                            let timingsToRender = result.shop_timings
                                                                            if (timingsToRender) {
                                                                                let objected = Object.entries(timingsToRender)
                                                                                let filtered = objected.filter((g) => {
                                                                                    return g[0] === dutchName
                                                                                })
                                                                                if (filtered.length > 0) {
                                                                                    let arr = []
                                                                                    filtered[0][1].map((g) => {
                                                                                        let splitted = g.split(":")
                                                                                        arr.push(splitted[0])
                                                                                    })
                                                                                    let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                                    let arrSec = []
                                                                                    allKeys.map((g) => {
                                                                                        let filteredSecond = filtered[0][1].filter((h) => {
                                                                                            let splitted = h.split(":")
                                                                                            return splitted[0] === g
                                                                                        })
                                                                                        arrSec.push(filteredSecond)
                                                                                    })
                                                                                    this.setState({ timingsExact: arrSec, allKeys })
                                                                                }
                                                                            }
                                                                            // Date WOrk
                                                                        } else {
                                                                            let days = [];
                                                                            let date = new Date(e.getFullYear(), e.getMonth(), 1);
                                                                            while (date.getMonth() === currentDay.getMonth()) {
                                                                                days.push(new Date(date));
                                                                                date.setDate(date.getDate() + 1);
                                                                            }
                                                                            var options = { weekday: 'long' };
                                                                            let daysToClose = []
                                                                            days.map((g) => {
                                                                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                                                                let timingsToRender = result.shop_timings
                                                                                if (timingsToRender) {
                                                                                    let objected = Object.entries(timingsToRender)
                                                                                    let filtered = objected.filter((g) => {
                                                                                        return g[0] === dutchName
                                                                                    })
                                                                                    if (filtered.length > 0) {
                                                                                        if (filtered[0][1] === "Gesloten") {
                                                                                            daysToClose.push(g)
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                            let arrOfDays = []
                                                                            daysToClose.map((h, i) => {
                                                                                arrOfDays.push(h.getDate())
                                                                            })
                                                                            this.setState({ daysToClose })
                                                                            let currentDaySelected = currentDay
                                                                            if (arrOfDays.includes(currentDay.getDate())) {
                                                                                currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                                                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                                                let daysToClose = []
                                                                                let openedDays = []
                                                                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                                                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                                                    let timingsToRender = result.shop_timings
                                                                                    let arrNotTime = []
                                                                                    if (timingsToRender) {
                                                                                        let objected = Object.entries(timingsToRender)
                                                                                        let filtered = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        let filteredTimings = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        if (filteredTimings[0][1] !== "Gesloten") {
                                                                                            let currenthour = date.getHours()
                                                                                            if (date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()) {
                                                                                                currenthour = new Date().getHours()
                                                                                            }
                                                                                            let filteredMain = filtered[0][1].filter((h) => {
                                                                                                let splitted = h.split(":")
                                                                                                return Number(splitted[0]) > currenthour
                                                                                            })
                                                                                            if (filtered.length > 0) {
                                                                                                if (filtered[0][1] === "Gesloten") {
                                                                                                    daysToClose.push(date)
                                                                                                } else {
                                                                                                    if (filteredMain.length === 0) {
                                                                                                        daysToClose.push(date)
                                                                                                    } else {
                                                                                                        if (new Date().getMonth() === date.getMonth()) {
                                                                                                            if (date.getDate() >= new Date().getDate()) {
                                                                                                                openedDays.push(date)
                                                                                                            }
                                                                                                        } else {
                                                                                                            openedDays.push(date)
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        } else {
                                                                                            daysToClose.push(date)
                                                                                        }
                                                                                    }
                                                                                }
                                                                                currentDay = openedDays[0]
                                                                                this.setState({ daysToClose })
                                                                            } else {
                                                                                let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                                                let daysToClose = []
                                                                                let openedDays = []
                                                                                for (let i = 0; i < daysOfWholeMonth; i++) {
                                                                                    let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                                                    let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                                                    let timingsToRender = result.shop_timings
                                                                                    let arrNotTime = []
                                                                                    if (timingsToRender) {
                                                                                        let objected = Object.entries(timingsToRender)
                                                                                        let filtered = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        let filteredTimings = objected.filter((g) => {
                                                                                            return g[0] === dutchName
                                                                                        })
                                                                                        if (filteredTimings.length > 0) {
                                                                                            if (filteredTimings[0][1] !== "Gesloten") {
                                                                                                let currenthour = date.getHours()
                                                                                                if (date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()) {
                                                                                                    currenthour = new Date().getHours()
                                                                                                }
                                                                                                let filteredMain = filtered[0][1].filter((h) => {
                                                                                                    let splitted = h.split(":")
                                                                                                    return Number(splitted[0]) > currenthour
                                                                                                })
                                                                                                if (filtered.length > 0) {
                                                                                                    if (filtered[0][1] === "Gesloten") {
                                                                                                        daysToClose.push(date)
                                                                                                    } else {
                                                                                                        if (filteredMain.length === 0) {
                                                                                                            daysToClose.push(date)
                                                                                                        } else {
                                                                                                            if (new Date().getMonth() === date.getMonth()) {
                                                                                                                if (date.getDate() >= new Date().getDate()) {
                                                                                                                    openedDays.push(date)
                                                                                                                }
                                                                                                            } else {
                                                                                                                openedDays.push(date)
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            } else {
                                                                                                daysToClose.push(date)
                                                                                            }
                                                                                        } else {
                                                                                            daysToClose.push(date)
                                                                                        }
                                                                                    }
                                                                                }
                                                                                currentDay = openedDays[0]
                                                                                daysToClose.push({ before: openedDays[0] })
                                                                                this.setState({ daysToClose })
                                                                            }
                                                                            this.setState({ selectedDay: currentDay })

                                                                            // above good (closed days move further)

                                                                            // Date WOrk
                                                                            let currentDate = currentDay
                                                                            let timingsExact = []
                                                                            var options = { weekday: 'long' };
                                                                            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                                                                            let timingsToRender = result.shop_timings
                                                                            if (timingsToRender) {
                                                                                let objected = Object.entries(timingsToRender)
                                                                                let filtered = objected.filter((g) => {
                                                                                    return g[0] === dutchName
                                                                                })
                                                                                if (filtered.length > 0) {
                                                                                    let arr = []
                                                                                    filtered[0][1].map((g) => {
                                                                                        let splitted = g.split(":")
                                                                                        arr.push(splitted[0])
                                                                                    })
                                                                                    let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                                    let arrSec = []
                                                                                    allKeys.map((g) => {
                                                                                        let filteredSecond = filtered[0][1].filter((h) => {
                                                                                            let splitted = h.split(":")
                                                                                            return splitted[0] === g
                                                                                        })
                                                                                        arrSec.push(filteredSecond)
                                                                                    })
                                                                                    this.setState({ timingsExact: arrSec, allKeys })
                                                                                }
                                                                            }
                                                                            // Date WOrk
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>}
                                                    {/* <DatePicker selected={this.state.startDate} onChange={(date) => {
                                                        let currentDate = new Date(date)
                                                        var options = { weekday: 'long' };
                                                        let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                                                        let timingsToRender = this.state.cartData.shop_timings
                                                        if (timingsToRender) {
                                                            let objected = Object.entries(timingsToRender)
                                                            let filtered = objected.filter((g) => {
                                                                return g[0] === dutchName
                                                            })
                                                            if (filtered.length > 0) {
                                                                this.setState({ timingsExact: filtered[0][1], selectedTime: filtered[0][1][0] })
                                                            }
                                                        }
                                                        this.setState({ startDate: date })
                                                    }} />
                                                    <ExpandMoreIcon style={{ position: "absolute", right: 5, top: "50%", transform: "translateY(-50%)" }} /> */}
                                                </div>}
                                                {this.state.deliveryOption === "collection_point" && this.state.timingsExact.length > 0 && <div className="delivery-work" style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}>
                                                    <p style={{ marginBottom: 0, marginRight: 8, fontSize: 12, letterSpacing: 1, marginTop: 6 }}>Tijd: </p>
                                                    <div className="timepickercart" style={{ paddingLeft: 43 }} onClick={() => this.setState({ timerDropDown: !this.state.timerDropDown })}>
                                                        <p>{this.state.selectedTime ? this.state.selectedTime : "Selecteer uw tijd"}</p>
                                                        <ExpandMoreIcon />
                                                    </div>
                                                    {this.state.timerDropDown && <div className="inner-work-timepicker">
                                                        <ul>
                                                            <li>Selecteer uw tijd</li>
                                                            {this.state.timingsExact.length > 0 && this.state.timingsExact.map((h) => {
                                                                if (this.state.selectedDay.getMonth() === new Date().getMonth() && this.state.selectedDay.getDate() === new Date().getDate()) {
                                                                    let splitted = h.split(":")
                                                                    if (Number(splitted[0]) > new Date().getHours()) {
                                                                        return <li key={Math.random()} className={this.state.selectedTime === h ? "activated" : ""} onClick={() => {
                                                                            this.setDeliveryOption("collection_point", h)
                                                                            this.setState({ selectedTime: h, timerDropDown: false })
                                                                        }}>{h}</li>
                                                                    }
                                                                } else {
                                                                    return <li key={Math.random()} className={this.state.selectedTime === h ? "activated" : ""} onClick={() => {
                                                                        this.setDeliveryOption("collection_point", h)
                                                                        this.setState({ selectedTime: h, timerDropDown: false })
                                                                    }}>{h}</li>
                                                                }
                                                            })}
                                                        </ul>
                                                    </div>}
                                                </div>}
                                                {this.state.selectedTimeAlert && <div className="delivery-work" style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}>
                                                    <p style={{ marginBottom: 0, marginRight: 8, fontSize: 12, letterSpacing: 1, marginTop: 9, color: "red" }}>Please Selecteer uw tijd to continue</p>
                                                </div>}
                                            </div>
                                            {/* Order Total */}
                                            <div className="order-total">
                                                {this.state.cartData.delivery_settings && <div className="cart-shipping-option-wrapper" style={{ marginBottom: 0, paddingBottom: 5 }}>

                                                    {(function () {
                                                        if (this.state.cartData.delivery_settings.delivery_option === "collection_point") {
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_2}
                                                            </span>
                                                        } else if (this.state.cartData.delivery_settings.delivery_option === "local_delivery") {
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_3}
                                                            </span>
                                                        } else {
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_1}
                                                            </span>
                                                        }
                                                    }).call(this)}

                                                    {(function () {

                                                        if (this.state.cartData.delivery_settings.delivery_prices) {
                                                            if (this.state.cartData.delivery_settings.delivery_option === "collection_point") {
                                                                return <span className="shipping-opt-price">
                                                                    {this.state.cartData.delivery_desc_2_price}
                                                                </span>
                                                            } else if (this.state.cartData.delivery_settings.delivery_option === "local_delivery") {
                                                                return <span className="shipping-opt-price">
                                                                    {this.state.cartData.delivery_desc_3_price}
                                                                </span>
                                                            } else {
                                                                return <span className="shipping-opt-price">
                                                                    {this.state.cartData.delivery_desc_1_price}
                                                                </span>
                                                            }
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_2}
                                                            </span>
                                                        } else {
                                                            return <span className="shipping-opt-price">
                                                                {this.state.cartData.delivery_desc_1_price}
                                                            </span>
                                                        }

                                                    }).call(this)}


                                                </div>}
                                                {this.state.cartData.coupon_settings && this.state.cartData.coupon_settings.coupon_discount && <div className="cart-shipping-option-wrapper" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                    <span className="shipping-option" style={{ width: "50%" }}>
                                                        Kortingscode
                                                    </span>
                                                    <span className="shipping-opt-price" style={{ width: "50%", justifyContent: "space-between", alignItems: "center" }}>
                                                        {this.state.cartData.coupon_settings.coupon_code && <font style={{ color: "#865", textDecoration: "underline", textTransform: "uppercase", fontSize: 12, cursor: "pointer" }} className="bodyText" onClick={this.removeCoupon.bind(this)}>
                                                            verwijderen
                                                        </font>}
                                                        € {this.state.cartData.coupon_settings.coupon_discount}
                                                    </span>
                                                </div>}
                                                {this.state.cartData.gift_settings && this.state.cartData.gift_settings.gift_price && <div className="cart-shipping-option-wrapper" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                    <span className="shipping-option">
                                                        Cadeauverpakking
                                                    </span>
                                                    <span className="shipping-opt-price">
                                                        {this.state.cartData.gift_settings.gift_price}
                                                    </span>
                                                </div>}
                                                <div className="cf-total" style={{ marginTop: 30 }}>
                                                    <div className="cft-itemname">
                                                        <font>TOTAAL</font>
                                                        <span className="taxes-included">
                                                            (incl. btw)
                                                        </span>
                                                    </div>
                                                    <div className="cft-itemval">
                                                        <font style={{ fontWeight: 700, fontSize: 18, letterSpacing: 1 }}>{this.state.cartData.default_total}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* cart actions */}
                                <div className="cart-actions">
                                    <p className="secure-checkout">
                                        <HttpsIcon style={{ marginRight: 5, fontSize: 15 }} />
                                        Safe Shopping
                                    </p>
                                </div>
                            </div>
                            {/* Layout Right End */}
                        </div>
                    </div>
                </div>
                <div className="sticky-footer-cart">
                    <div className="sticky-order-container">
                        <div className="details-container">
                            <span style={{ marginRight: 7 }}>{this.state.cartData.total_products}</span>
                            <span>PRODUCTS</span>
                        </div>
                        <div className="price-container">
                            <div style={{ display: "flex", alignItems: "center", marginleft: 10 }} >
                                <div className="order-total-label">
                                    <span>Total</span>
                                </div>
                                <div className="order-total-amount">
                                    <span>
                                        {this.state.cartData.default_total}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-button-container" onClick={this.checkoutContinue.bind(this)}>
                        <span>{this.state.cartData.cart_page_next_button_text}</span>
                    </div>
                </div>
                {
                    this.state.individualpopup && <div className="bg-overlaycart" onClick={() => {
                        let checkArr = this.state.arrCheck.filter((e) => {
                            return e.checked === true
                        })
                        if (this.state.selectedOption === "wrap_individually" && checkArr.length < 1) {
                            this.setState({ selectedOption: "wrap_every_thing" })
                        } else {
                            this.setState({ selectedOption: "wrap_individually" })
                        }
                        this.setState({ individualpopup: false });
                        document.getElementById("mainbody").style.position = "static"
                    }}>
                    </div>
                }
                <div className={`giftwrap-container-popup ${this.state.individualpopup ? "open" : null}`}>
                    <div className="gcp-inner">
                        <div className="giftwrap-title">
                            <CloseIcon className="close-cion-gt" onClick={() => {
                                let checkArr = this.state.arrCheck.filter((e) => {
                                    return e.checked === true
                                })
                                if (this.state.selectedOption === "wrap_individually" && checkArr.length < 1) {
                                    this.setState({ selectedOption: "wrap_every_thing" })
                                } else {
                                    this.setState({ selectedOption: "wrap_individually" })
                                }
                                this.setState({ individualpopup: false });
                                document.getElementById("mainbody").style.position = "static"
                            }} />
                            <h2>{this.state.cartData.cart_page_right_heading}</h2>
                            <p>
                                {this.state.cartData.cart_page_right_desc_1}
                                <br />
                                {this.state.cartData.cart_page_right_desc_2}
                            </p>
                        </div>
                        <div className="form-row">
                            <div className="checkall">
                                <input type="checkbox" checked={this.state.allItemsChecked} onChange={(e) => {
                                    if (e.target.checked) {
                                        this.state.arrCheck.map((e) => {
                                            e.checked = true
                                        })
                                        this.setState({ arrCheck: this.state.arrCheck, allItemsChecked: true })
                                    } else {
                                        this.state.arrCheck.map((e) => {
                                            e.checked = false
                                        })
                                        this.setState({ arrCheck: this.state.arrCheck, allItemsChecked: false })
                                    }
                                }} />
                                <label>
                                    Select all/Deselect all
                                 </label>
                            </div>
                        </div>
                        <ul className="giftwrap-products" style={{ paddingBottom: 60 }}>
                            {this.state.cartData && this.state.cartData.cart_items.map((e, i) => {
                                return <li key={Math.random()}>
                                    <input type="checkbox" checked={this.state.arrCheck[i].checked} onChange={(e) => {
                                        if (e.target.checked) {
                                            this.state.arrCheck[i].checked = true
                                            this.setState({ arrCheck: this.state.arrCheck })
                                        } else {
                                            this.state.arrCheck[i].checked = false
                                            this.setState({ arrCheck: this.state.arrCheck })
                                        }
                                        let checkedFilters = this.state.arrCheck.filter((e) => {
                                            return e.checked === true
                                        })
                                        if (checkedFilters.length === this.state.cartData.cart_items.length) {
                                            this.setState({ allItemsChecked: true })
                                        } else {
                                            this.setState({ allItemsChecked: false })
                                        }
                                    }} />
                                    <label>
                                        <img src={e.image_url} />
                                    </label>
                                </li>
                            })}
                        </ul>
                        <div className="giftwrap-bottom-controls">
                            <div className="buttons-total">
                                <button className="btn-continue" onClick={() => this.setState({ individualpopup: false })}>CLOSE</button>
                                <button className="btn-cart" onClick={() => {
                                    this.checkGift("wrap_individually")
                                }}>SAVE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Cart
