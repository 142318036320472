import React, { Component } from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import InputComponent from "../Components/InputComponent";
import CK from "../Components/Ck";
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import { gv } from '../Components/globalvar'
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class Slides extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: [
                {
                    heading_1: "DEMO TITLE",
                    heading_2: "DEMO TITLE",
                    desc: "<p>Demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title</p>",
                    image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_10_image_1.jpg",
                    delete_image: false,
                },
                {
                    heading_1: "DEMO TITLE",
                    heading_2: "DEMO TITLE",
                    desc: "<p>Demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title</p>",
                    image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_10_image_1.jpg",
                    delete_image: false,
                },
                {
                    heading_1: "DEMO TITLE",
                    heading_2: "DEMO TITLE",
                    desc: "<p>Demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title</p>",
                    image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_10_image_1.jpg",
                    delete_image: false,
                }
            ],
            arrInputs: [],
            data_heading_1: "DEMO TITLE",
            data_heading_2: "DEMO TITLE",
            data_desc: "<p>Demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title</p>",
            data_image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_10_image_1.jpg",
            spaceTop: true,
            spaceBottom: true,
            crop: {
                // unit: '%',
                width: 460,
                height: 350,
                aspect: 16 / 9,
            },
            createLoader: true,
            pageData: '',
        }
    }

    componentDidMount() {
        let arr = []
        this.state.wholeData.map((e) => {
            if (e.desc.length > 99) {
                e.descSec = `${e.desc.substr(0, 100)} ....`
            } else {
                e.descSec = `${e.desc.substr(0, 100)}`
            }
            let obj = {
                heading_1Input: false,
                heading_2Input: false,
                descInput: false,
                loader: false,
            }
            arr.push(obj)
        })
        this.setState({ wholeData: this.state.wholeData, arrInputs: arr })

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ pageData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ pageData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    slideLeft() {
        let element = document.getElementById(`first-slider`)
        element.scrollLeft -= 600
        this.setState({ slideLefting: true })
        if (element.scrollLeft > 250) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }

    slideRight() {
        let element = document.getElementById(`first-slider`)
        element.scrollLeft += 600
        this.setState({ slideLefting: true })
        if (element.scrollLeft > -100) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }

    createMarkup(e) {
        return { __html: e };
    }

    closeInput(e, f) {
        this.state.wholeData[f].heading_1 = e
        this.state.arrInputs[f].heading_1Input = false
        this.setState({ wholeData: this.state.wholeData, arrInputs: this.state.arrInputs })
    }

    closeInputSec(e, f) {
        this.state.wholeData[f].heading_2 = e
        this.state.arrInputs[f].heading_2Input = false
        this.setState({ wholeData: this.state.wholeData, arrInputs: this.state.arrInputs })
    }

    changeAlert(e, f) {
        this.state.wholeData[e].desc = f
        if (f.length > 99) {
            this.state.wholeData[e].descSec = `${f.substr(0, 100)} ....`
        } else {
            this.state.wholeData[e].descSec = `${f.substr(0, 100)}`
        }
        this.setState({ wholeData: this.state.wholeData })
    }

    close(e) {
        this.state.arrInputs[e].descInput = false
        this.setState({ arrInputs: this.state.arrInputs })
    }

    changeAlertCreate(e) {
        this.setState({ data_desc: e })
    }

    closeCreate() {
        this.setState({ data_descInput: false })
    }

    leftSlide(e) {
        let wholeData = this.state.wholeData
        wholeData.splice(e - 1, 0, wholeData[e])
        wholeData.splice(e + 1, 1)
        this.setState({ wholeData: this.state.wholeData })
    }

    rightSlide(e) {
        let wholeData = this.state.wholeData
        wholeData.splice(e + 2, 0, wholeData[e])
        wholeData.splice(e, 1)
        this.setState({ wholeData: this.state.wholeData })
    }

    deleteSlide(g) {
        this.state.wholeData.splice(g, 1)
        this.setState({ wholeData: this.state.wholeData })
    }

    uploadImageSection(e, f) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0], selectedSlideIndex: f })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 460 || height > 350) {
                            let crop = {
                                unit: 'px',
                                width: 460,
                                height: 350,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });
        let i = this.state.selectedSlideIndex

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("section_type", "layout_10");
        formdata.append("image_type", `layout_10_image_${i}`);
        formdata.append("image", file);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    this.state.arrInputs[i].loader = false
                    this.state.wholeData[i].image_url = result.data.image_url
                    this.state.wholeData[i].delete_image = true
                    this.setState({ arrInputs: this.state.arrInputs, wholeData: this.state.wholeData, cropper: false, croppedImageUrl: "" })
                }
            })
            .catch(error => console.log('error', error));
    }

    deleteItemSection(i) {
        this.state.arrInputs[i].loader = true
        this.setState({ arrInputs: this.state.arrInputs })

        let image_url = this.state.wholeData[i].image_url
        let splitted = image_url.split("/")

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("section_type", "layout_10");
        formdata.append("image_type", `layout_10_image_${i}`);
        formdata.append("image_name", splitted[splitted.length - 1]);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    this.state.arrInputs[i].loader = false
                    this.state.wholeData[i].image_url = result.data.default_url
                    this.state.wholeData[i].delete_image = false
                    this.setState({ arrInputs: this.state.arrInputs, wholeData: this.state.wholeData })
                }
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { wholeData, pageData } = this.state
        let obj = {
            type: "layout_10",
            wholeData,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        pageData.sections.splice(Number(currentIndex) + 1, 0, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, pageData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    showCurrentImage() {
        this.setState({ cropper: false, croppedImageUrl: "" })
    }

    render() {
        return (
            <div>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImageSection(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="sixth-sec" id="projecten" style={{ padding: "20px 0px", marginBottom: 40 }}>
                        <ChevronLeftIcon className="slider-left" onClick={this.slideLeft.bind(this)} />
                        <ChevronRightIcon className="slider-right" onClick={this.slideRight.bind(this)} />
                        <div className="all-cards-ss" id="first-slider" style={{ marginTop: 50 }}>
                            {this.state.wholeData && this.state.arrInputs.length > 0 && this.state.wholeData.map((e, i) => {
                                return <div className="card-ss" key={Math.random()} style={{ width: 500 }}>
                                    <div className="icons-scss">
                                        {i > 0 && <ChevronLeftIcon onClick={this.leftSlide.bind(this, i, e)} />}
                                        {i < this.state.wholeData.length - 1 && <ChevronRightIcon onClick={this.rightSlide.bind(this, i, e)} />}
                                        <DeleteIcon onClick={this.deleteSlide.bind(this, i)} />
                                    </div>
                                    <h3 className="title" onClick={() => {
                                        this.state.arrInputs[i].heading_1Input = true
                                        this.setState({ arrInputs: this.state.arrInputs })
                                    }}>
                                        {!this.state.arrInputs[i].heading_1Input ? e.heading_1 : <InputComponent dataText={e.heading_1} blurInput={(e) => this.closeInput(e, i)} />}
                                    </h3>
                                    <h5 onClick={() => {
                                        this.state.arrInputs[i].heading_2Input = true
                                        this.setState({ arrInputs: this.state.arrInputs })
                                    }}>
                                        {!this.state.arrInputs[i].heading_2Input ? e.heading_2 : <InputComponent dataText={e.heading_2} blurInput={(e) => this.closeInputSec(e, i)} />}
                                    </h5>
                                    {!this.state.arrInputs[i].descInput ? <div dangerouslySetInnerHTML={this.createMarkup(e.descSec)} onClick={() => {
                                        this.state.arrInputs[i].descInput = true
                                        this.setState({ arrInputs: this.state.arrInputs })
                                    }}></div> : <CK data={e.desc} onChildChange={this.changeAlert.bind(this, i)} onChildBlur={this.close.bind(this, i)} />}
                                    {/* <div dangerouslySetInnerHTML={this.createMarkup(e.descSec)} style={{ minHeight: 90 }}></div> */}
                                    <div className="img-scss" style={{ backgroundImage: `url(${e.image_url})`, position: "relative" }}>
                                        <div className="image_uploadingsicon">
                                            <div>
                                                <PublishIcon />
                                                <input type="file" onChange={(e) => this.uploadImageSection(e, i)} />
                                            </div>
                                            {e.delete_image && <div><DeleteIcon onClick={this.deleteItemSection.bind(this, i)} /></div>}
                                        </div>
                                        {this.state.arrInputs[i].loader && <div className="loader-working-fb">
                                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                                        </div>}
                                    </div>
                                    <button>LEES MEER</button>
                                </div>
                            })}
                        </div>
                    </div>}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections">
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps" onClick={() => {
                        let obj = {
                            heading_1: "DEMO TITLE",
                            heading_2: "DEMO TITLE",
                            desc: "<p>Demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title demo title</p>",
                            image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_10_image_1.jpg",
                            delete_image: false,
                        }
                        this.state.wholeData.splice(0, 0, obj)
                        let arr = []
                        this.state.wholeData.map((e) => {
                            if (e.desc.length > 99) {
                                e.descSec = `${e.desc.substr(0, 100)} ....`
                            } else {
                                e.descSec = `${e.desc.substr(0, 100)}`
                            }
                            let obj = {
                                heading_1Input: false,
                                heading_2Input: false,
                                descInput: false,
                                loader: false,
                            }
                            arr.push(obj)
                        })
                        this.setState({ wholeData: this.state.wholeData, arrInputs: arr })
                    }}>
                        <AddIcon />
                        <font>
                            CREATE NEW SLIDE
                        </font>
                    </div>
                    {this.state.pageData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 662x662, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default Slides
