import React, { Component } from 'react'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { gv } from '../Components/globalvar'
import FlipIcon from '@material-ui/icons/Flip';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import CK from '../Components/Ck';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class OntdekEdit extends Component {
    constructor() {
        super()
        this.state = {
            image_url: "",
            heading: "",
            headingInput: false,
            desc: "",
            descInput: false,
            buttonText: "",
            buttonInput: false,
            videoUrl: "",
            delete_image: false,
            wholeData: '',
            allRoutes: [],
            buttonUrl: '',
            selectRoute: true,
            selectedRoute: "",
            urlValidate: false,
            brandRoute: false,
            brandCategories: [],
            selectedCategory: "horloges",
            allBrands: [],
            childmenulists: [],
            selectedRouteBrand: "",
            selectedBrandList: "",
            brandlists: [],
            listRoute: false,
            selectedRouteurl: '',
            soortRoute: false,
            soortArr: [],
            selectedSoort: "Ringen",
            imageUploading: false,
            // footer section work
            spaceTop: false,
            isFlipped: false,
            spaceBottom: false,
            image_url_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/images/last.jpg",
            delete_image_dup: false,
            is_brand: false,
            is_soort: false,
            crop: {
                // unit: '%',
                width: 519,
                height: 519,
                aspect: 16 / 9,
            },
            createLoader: true,
            onImageIndex: 0,
        }
    }

    componentDidMount() {

        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let parsed = JSON.parse(headerData)
            let child_menus = Object.entries(parsed.menus.child_menus);
            let childmenulists = []
            let hello = child_menus.filter((f) => {
                return f[0] === parsed.main_categories[0].id
            });
            childmenulists = hello[0][1]

            let helloSec = child_menus.filter((f) => {
                return f[0] === "soort"
            });

            let brandlists = child_menus.filter((f) => {
                return f[0] === "merken"
            })

            fetch(`${gv}/api/v1/${parsed.main_categories[0].id}/merken?browser_type=${browserType}`)
                .then(response => response.json())
                .then(result => {
                    let childmenulists = result.data.data
                    this.setState({ brandDetailsList: childmenulists, selectedBrandList: childmenulists[0].id })
                })
                .catch(error => console.log('error', error));

            this.setState({
                brandCategories: parsed.main_categories, selectedCategory: parsed.main_categories[0].id, childmenulists, soortArr: helloSec[0][1], selectedSoort: helloSec[0][1][0].id, brandlists: brandlists[0][1]
            })
        }

        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)

            if (!Array.isArray(parsedData.left_image)) {
                let splitted = parsedData.left_image.split('/')
                if (splitted[splitted.length - 1] !== "last.jpg" && !parsedData.is_brand && !parsedData.is_soort) {
                    this.setState({ delete_image: true })
                }
            }

            this.setState({
                heading: parsedData.right_heading1,
                desc: parsedData.right_desc,
                image_url: parsedData.left_image,
                buttonText: parsedData.button_text,
                selectRoute: parsedData.urlType,
                isFlipped: parsedData.is_flipped,
                spaceTop: parsedData.space_top,
                spaceBottom: parsedData.space_bottom,
                is_brand: parsedData.is_brand,
                is_soort: parsedData.is_soort,
                onImageIndex: parsedData.onImageIndex,
                sectionType: parsedData.section_type,
                delete_image: parsedData.checkImageUpload,
            })
            if (parsedData.urlType) {
                let splitted = parsedData.button_url.split("/")
                this.setState({ selectedRouteurl: parsedData.button_url })
                if (splitted[1] === "brand-products") {
                    this.setState({ selectedRoute: `/${splitted[1]}`, brandRoute: true, selectedCategory: splitted[2], selectedBrand: splitted[3] })
                    let data = JSON.parse(headerData)
                    let inArr = Object.entries(data.menus.child_menus)
                    inArr.map((f) => {
                        if (f[0] === splitted[2]) {
                            this.setState({ childmenulists: f[1] })
                        }
                    })

                    if (!parsedData.checkImageUpload) {
                        var formdata = new FormData();
                        formdata.append("main_category", splitted[2]);
                        formdata.append("image_type", "left_banner");
                        formdata.append("brand_id", splitted[3]);

                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                console.log(result)
                                if (result.message === "its demo image.") {
                                    this.setState({ image_url: result.data.image_url, delete_image: false })
                                } else {
                                    this.setState({ image_url: result.data.image_url, delete_image: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else if (splitted[2] === "merken") {
                    this.setState({ selectedBrandList: splitted[1], selectedRoute: `/${splitted[2]}`, listRoute: true })
                } else if (splitted[1] === "soort") {
                    this.setState({ selectedSoort: splitted[2], selectedRoute: `/soort`, soortRoute: true })
                } else if (splitted[1] === "collection-story") {
                    fetch(`${gv}/api/v1/${splitted[2]}/merken?browser_type=${browserType}`)
                        .then(response => response.json())
                        .then(result => {
                            let childmenulists = result.data.data
                            this.setState({ brandDetailsList: childmenulists })
                        })
                        .catch(error => console.log('error', error));
                    this.setState({ selectedRoute: `/brand-detail`, detailRoute: true, selectedCategory: splitted[2], selectedBrand: splitted[3] })
                } else {
                    if (splitted[1] === "") {
                        this.setState({ selectedRoute: `/landing-page` })
                    } else {
                        this.setState({ selectedRoute: `/${splitted[1]}` })
                    }
                }
            } else {
                this.setState({ buttonUrl: parsedData.button_url })
            }
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));


        let allRoutes = localStorage.getItem("allRoutes")
        if (allRoutes) {
            let parsed = JSON.parse(allRoutes)
            let arr = ["brand-products", "soort", "merken", "brand-detail"]
            parsed.map((f) => {
                if (f.split("/")[1] === "") {
                    arr.push("landing-page")
                } else {
                    arr.push(f.split("/")[1])
                }
            })
            this.setState({ allRoutes: arr, browserType })
        }
    }

    uploadImg(e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0] })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 700 && height > 850) {
                            let crop = {
                                unit: 'px',
                                width: 700,
                                height: 700,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        } else {
                            let crop = {
                                unit: 'px',
                                width: 700,
                                height: 620,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    removeImage() {
        if (this.state.is_soort) {
            let imageName = this.state.image_url.split('/')

            this.setState({ imageUploading: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", this.state.sectionType);
            formdata.append("image_name", imageName[imageName.length - 1]);
            formdata.append("brand_id", this.state.selectedSoort);
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: result.data.default_url, imageUploading: false, delete_image: false })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            if (this.state.is_brand) {
                this.setState({ imageUploading: true })
                if (this.state.selectedBrand) {
                    let imageName = this.state.image_url.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_name", imageName[imageName.length - 1]);
                    formdata.append("brand_id", this.state.selectedBrand);
                    formdata.append("main_category", this.state.selectedCategory);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ image_url: result.data.images, onImageIndex: 0, imageUploading: false, delete_image: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let imageName = this.state.image_url.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_name", imageName[imageName.length - 1]);
                    formdata.append("brand_id", this.state.childmenulists[0].id);
                    formdata.append("main_category", this.state.selectedCategory);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ image_url: result.data.images, onImageIndex: 0, imageUploading: false, delete_image: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                const { image_url } = this.state
                let splitted = image_url.split("/")
                if (splitted[splitted.length - 1] !== "last.jpg") {
                    this.setState({ loader: true })

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_8");
                    formdata.append("image_name", splitted[splitted.length - 1]);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/images/last.jpg", delete_image: false, imageUploading: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    getBrand(e, g) {
        this.setState({ is_brand: true, imageUploading: true, is_soort: false, delete_image: false })
        if (e === "firstTime") {
            this.setState({ image_url_duplicate: this.state.image_url, delete_image_dup: this.state.delete_image, })
            this.setState({ selectedRouteurl: `/brand-products/${this.state.selectedCategory}/${this.state.childmenulists[0].brand_id}`, selectedBrand: this.state.childmenulists[0].brand_id })

            var formdata = new FormData();
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.childmenulists[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ image_url: result.data.image_url, imageUploading: false })
                })
                .catch(error => console.log('error', error));
        } else if (e === "secTime") {
            var selectedCateogrySend = ""
            selectedCateogrySend = this.state.selectedCategory
            this.setState({ selectedRouteurl: `/brand-products/${this.state.selectedCategory}/${g.target.value}`, selectedBrand: g.target.value })

            var formdata = new FormData();
            formdata.append("main_category", selectedCateogrySend);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", g.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ image_url: result.data.image_url, imageUploading: false })
                })
                .catch(error => console.log('error', error));
        } else {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let data = JSON.parse(headerData)
                let inArr = Object.entries(data.menus.child_menus)
                inArr.map((f) => {
                    if (f[0] === e.target.value) {
                        this.setState({ childmenulists: f[1], selectedCategory: e.target.value, selectedBrand: "" })
                        this.setState({ selectedRouteurl: `/brand-products/${e.target.value}/${f[1][0].id}`, selectedBrand: f[1][0].id })

                        var formdata = new FormData();
                        formdata.append("main_category", e.target.value);
                        formdata.append("image_type", "banner");
                        formdata.append("brand_id", f[1][0].id.toLowerCase());

                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                this.setState({ image_url: result.data.image_url, imageUploading: false })
                            })
                            .catch(error => console.log('error', error));
                    }
                })
            }
        }
    }

    getBrandDetailsList(e, f) {
        this.setState({ selectedCategory: e.target.value })
        let val = e.target.value

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/${val}/merken?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let childmenulists = result.data.data
                this.setState({ brandDetailsList: childmenulists, selectedRouteurl: `/${f}/${val}/${childmenulists[0].brand_id}` })
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { heading, desc, buttonText, image_url, videoUrl, wholeData, buttonUrl } = this.state
        let urlBtn = ""
        let is_video = false
        if (videoUrl) {
            is_video = true
        } else {
            is_video = false
        }
        let is_button_route = false
        if (this.state.selectRoute) {
            if (this.state.selectedRoute) {
                is_button_route = true
                if (this.state.selectedRoute === "/landing-page") {
                    urlBtn = "/"
                } else if (this.state.selectedRoute === "/brand-products" || this.state.selectedRoute === "/merken" || this.state.selectedRoute === "/soort" || this.state.selectedRoute === "/brand-detail") {
                    urlBtn = this.state.selectedRouteurl
                } else {
                    urlBtn = this.state.selectedRoute
                }
            } else {
                is_button_route = false
                urlBtn = this.state.selectedRoute
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrl) {
                if (buttonUrl.match(regex)) {
                    urlBtn = buttonUrl
                    is_button_route = true
                    this.setState({ urlValidate: false })
                } else {
                    urlBtn = ""
                    this.setState({ urlValidate: true })
                }
            } else {
                urlBtn = ""
                is_button_route = false
            }
        }

        this.setState({ createLoader: true })

        let obj = {}
        if (Array.isArray(this.state.image_url)) {
            obj = {
                type: "layout_3",
                right_heading1: heading,
                right_desc: desc,
                left_image: image_url[this.state.onImageIndex],
                button_text: buttonText,
                is_button_route,
                button_url: urlBtn,
                urlType: this.state.selectRoute,
                is_flipped: this.state.isFlipped,
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
                is_brand: this.state.is_brand,
                is_soort: this.state.is_soort,
                onImageIndex: this.state.onImageIndex,
                section_type: this.state.sectionType,
                checkImageUpload: this.state.delete_image,
            }
        } else {
            obj = {
                type: "layout_3",
                right_heading1: heading,
                right_desc: desc,
                left_image: image_url,
                button_text: buttonText,
                is_button_route,
                button_url: urlBtn,
                urlType: this.state.selectRoute,
                is_flipped: this.state.isFlipped,
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
                is_brand: this.state.is_brand,
                is_soort: this.state.is_soort,
                onImageIndex: this.state.onImageIndex,
                section_type: this.state.sectionType,
                checkImageUpload: this.state.delete_image,
            }
        }

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });

    }

    resetBrand() {
        this.setState({ image_url: this.state.image_url_duplicate, delete_image: this.state.delete_image_dup, is_brand: false, is_soort: false })
    }

    getSoort(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurl: `/soort/${this.state.soortArr[0].id}`, selectedSoort: this.state.soortArr[0].id, is_soort: true, is_brand: false, })
            this.setState({ image_url_duplicate: this.state.image_url, banner_type_duplicate: this.state.banner_type, delete_image_dup: this.state.delete_image, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ image_url: result.data.image_url, delete_image: false })
                    } else {
                        this.setState({ image_url: result.data.image_url, delete_image: true })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurl: `/soort/${e.target.value}`, selectedSoort: e.target.value, is_soort: true, is_brand: false })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ image_url: result.data.image_url, delete_image: false })
                    } else {
                        this.setState({ image_url: result.data.image_url, delete_image: true })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }


    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (file) {
            if (this.state.is_soort) {
                let accesstoken = localStorage.getItem("adminKey")

                this.setState({ imageUploading: true })

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image", file);
                formdata.append("brand_id", this.state.selectedSoort);
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.setState({ imageUploading: false })
                        if (result.data.sucess !== 0) {
                            this.setState({ image_url: result.data.image_url, delete_image: true, imageUploading: false })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                let accesstoken = localStorage.getItem("adminKey")

                this.setState({ imageUploading: true })

                if (this.state.is_brand) {
                    if (this.state.selectedBrand) {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.selectedBrand);
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ imageUploading: false })
                                if (result.data.sucess !== 0) {
                                    this.setState({ image_url: result.data.image_url, delete_image: true, imageUploading: false, croppedImageUrl: "", cropper: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.childmenulists[0].id);
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ imageUploading: false })
                                if (result.data.sucess !== 0) {
                                    this.setState({ image_url: result.data.image_url, delete_image: true, imageUploading: false, croppedImageUrl: "", cropper: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    this.setState({ imageUploading: true })

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image", file);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ image_url: result.data.image_url, delete_image: true, imageUploading: false, croppedImageUrl: "", cropper: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    showCurrentImage() {
        this.setState({ cropper: false, image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/images/last.jpg", croppedImageUrl: "" })
    }

    createMarkup() {
        if (this.state.desc) {
            return { __html: this.state.desc };
        }
    }

    changeAlert(e) {
        this.setState({ desc: e })
    }

    close() {
        this.setState({ descInput: false })
    }


    render() {
        return (
            <div className="last-sec-home sectiles ontdek-cr" style={{ width: this.state.isFlipped && "90%", marginLeft: this.state.isFlipped && "auto", marginRight: this.state.isFlipped && "auto" }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="closer-working" onClick={() => this.setState({ headingInput: false, descInput: false, buttonInput: false })}>

                </div>
                <div className="inner-wrapper" style={{ flexDirection: this.state.isFlipped ? "row-reverse" : "row", paddingBottom: 50 }}>
                    {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                        <ReactCrop
                            src={this.state.src}
                            crop={this.state.crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            locked={true}
                            keepSelection
                        />
                        <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                            let elem = document.getElementsByClassName("ReactCrop__image")[0]
                            if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                                this.state.crop.width = this.state.crop.width + 25
                                this.state.crop.height = this.state.crop.height + 25
                                let obj = {
                                    width: this.state.crop.width,
                                    height: this.state.crop.height,
                                    aspect: 16 / 9,
                                    x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                    y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                                }
                                this.setState({ crop: obj })
                            }
                        }}>
                            <ZoomInIcon style={{ fontSize: 36 }} />
                        </div>
                        <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                            this.state.crop.width = this.state.crop.width - 25
                            this.state.crop.height = this.state.crop.height - 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x,
                                y: this.state.crop.y,
                            }
                            this.setState({ crop: obj })
                        }}>
                            <ZoomOutIcon style={{ fontSize: 36 }} />
                        </div>
                        <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                            <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                            <PublishIcon style={{ color: "white" }} />
                        </div>
                        <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                            <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                        </div>
                        <div className="footer-allpopups-sections">
                            <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                                <font>DONE</font>
                            </div>
                        </div>
                    </div> : <div className="img-lah" style={{ position: "relative" }}>
                            <div className="file-uploadimg" style={{ zIndex: 9999 }}>
                                <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                                <PublishIcon style={{ color: "white" }} />
                            </div>
                            {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, margin: "0px 10px" }}>
                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                            </div>}
                            {Array.isArray(this.state.image_url) && this.state.image_url.length > 1 && this.state.onImageIndex > 0 && <div className="file-uploadimg" style={{ top: 88, zIndex: 9999, left: "96%" }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex - 1 })}>
                                <ChevronLeftIcon />
                            </div>}
                            {Array.isArray(this.state.image_url) && this.state.image_url.length > 1 && this.state.onImageIndex < this.state.image_url.length - 1 && <div className="file-uploadimg" style={{ top: 88, left: "97%", zIndex: 9999 }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex + 1 })}>
                                <ChevronRightIcon />
                            </div>}
                            <img alt="showingyet" src={Array.isArray(this.state.image_url) ? this.state.image_url[this.state.onImageIndex] : this.state.image_url} style={{ width: "100%" }} />
                            {this.state.imageUploading && <div className="loader-working-fb">
                                <div className="lds-facebook"><div></div><div></div><div></div></div>
                            </div>}
                        </div>}
                    {!this.state.cropper && <div className="content-lah">
                        <div className="secchecks">
                            {this.state.heading ? <h3 onClick={() => this.setState({ headingInput: true })} onBlur={() => this.setState({ headingInput: false })}>
                                {!this.state.headingInput ? this.state.heading : <input autoFocus type="text" value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} />}
                            </h3> : <input type="text" value={this.state.heading} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ heading: e.target.value, headingInput: true })} onBlur={() => this.setState({ headingInput: false })} />}
                        </div>
                        <div className="secchecks">
                            {!this.state.descInput && this.state.desc ? <div dangerouslySetInnerHTML={this.createMarkup()} onClick={() => this.setState({ descInput: true })} onBlur={() => this.setState({ descInput: false })}></div> : <CK data={this.state.desc} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                        </div>
                        <div className="secchecks" style={{ textAlign: "center" }}>
                            {!this.state.buttonInput ? <button onClick={() => this.setState({ buttonInput: true })} onBlur={() => this.setState({ buttonInput: false })}>
                                {this.state.buttonText}
                            </button> : <div style={{ width: "100%" }}>
                                    <select style={{ marginBottom: 10 }} value={this.state.selectRoute ? "ps" : "link"} onChange={(e) => {
                                        if (e.target.value === "ps") {
                                            this.setState({ selectRoute: true })
                                        } else {
                                            this.setState({ selectRoute: false })
                                        }
                                    }}>
                                        <option value="ps">Page selection</option>
                                        <option value="link">Own link</option>
                                    </select>
                                    {this.state.selectRoute ? <div style={{ width: "100%" }}>
                                        <select style={{ marginBottom: 10 }} value={this.state.selectedRoute} onChange={(e) => {
                                            this.setState({ selectedRoute: e.target.value })
                                            if (e.target.value === "/brand-products") {
                                                this.getBrand("firstTime")
                                                this.setState({ brandRoute: true, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/brand-products/${this.state.selectedCategory}/${this.state.childmenulists[0].id}` })
                                            } else if (e.target.value === "/merken") {
                                                this.setState({ listRoute: true, detailRoute: false, brandRoute: false, soortRoute: false, selectedRouteurl: `/${this.state.brandlists[0].id}/merken` })
                                                this.resetBrand()
                                            } else if (e.target.value === "/soort") {
                                                this.setState({ soortRoute: true, detailRoute: false, listRoute: false, brandRoute: false, selectedRouteurl: `/soort/${this.state.soortArr[0].id}` })
                                                this.getSoort("firstTime")
                                            } else if (e.target.value === "/brand-detail") {
                                                this.setState({ detailRoute: true, soortRoute: false, listRoute: false, brandRoute: false, selectedRouteurl: `/collection-story/${this.state.selectedCategory}/${this.state.childmenulists[0].id}` })
                                                this.resetBrand()
                                            } else {
                                                this.setState({ brandRoute: false, listRoute: false, selectedRouteurl: "", detailRoute: false, soortRoute: false })
                                                this.resetBrand()
                                            }
                                        }}>
                                            <option value="">Select Route</option>
                                            {this.state.allRoutes.map((e) => {
                                                return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                            })}
                                        </select>
                                        {this.state.brandRoute && <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10 }} onChange={(e) => this.getBrand(e, "brand-products", "left")} value={this.state.selectedCategory}>
                                                {this.state.brandCategories.map((e) => {
                                                    if (e.show_for_products) {
                                                        return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <select style={{ marginBottom: 10 }} value={this.state.selectedBrand} onChange={(e) => this.getBrand("secTime", e, "left")}>
                                                {this.state.childmenulists.map((e) => {
                                                    return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                })}
                                            </select>
                                        </div>}
                                        {this.state.detailRoute && <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10 }} onChange={(e) => this.getBrandDetailsList(e, "collection-story")} value={this.state.selectedCategory}>
                                                {this.state.brandCategories.map((e) => {
                                                    if (e.show_for_brands) {
                                                        return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <select style={{ marginBottom: 10 }} value={this.state.selectedBrand} onChange={(e) => this.setState({ selectedRouteurl: `/collection-story/${this.state.selectedCategory}/${e.target.value}`, selectedBrand: e.target.value })}>
                                                {this.state.brandDetailsList.map((e) => {
                                                    return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                })}
                                            </select>
                                        </div>}
                                        {this.state.listRoute && <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10 }} value={this.state.selectedBrandList} onChange={(e) => this.setState({ selectedRouteurl: `/${e.target.value}/merken`, selectedBrandList: e.target.value })}>
                                                {this.state.brandlists.map((e) => {
                                                    return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                })}
                                            </select>
                                        </div>}
                                        {this.state.soortRoute && <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedSoort} onChange={(e) => this.getSoort(e)}>
                                                {this.state.soortArr.map((e) => {
                                                    return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                })}
                                            </select>
                                        </div>}
                                    </div> : <input placeholder="URL" className="bodyText" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20 }} value={this.state.buttonUrl} onChange={(e) => {
                                        if (e.target.value !== "") {
                                            this.setState({ buttonUrl: e.target.value })
                                        } else {
                                            this.setState({ buttonUrl: e.target.value })
                                        }
                                    }} />}
                                    {this.state.urlValidate && <p className="bodyText" style={{ color: "red", fontFamily: "Roboto", fontSize: 12, textAlign: "left" }}>Please enter valid URL</p>}
                                    <button>
                                        <input style={{ color: "white" }} value={this.state.buttonText} onChange={(e) => this.setState({ buttonText: e.target.value })} onBlur={() => this.setState({ buttonInput: false })} />
                                    </button>
                                </div>}
                        </div>
                    </div>}
                </div>
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections" style={{ width: "95%" }}>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps" onClick={() => this.setState({ isFlipped: !this.state.isFlipped })}>
                        <FlipIcon />
                        <font>SPIEGELEN</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 520x520, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default OntdekEdit
