import React, { Component } from 'react'
import Navbar from '../../Components/Navbar'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@material-ui/icons/Check';
import { gv } from '../../Components/globalvar'
import './index.css'
import { Helmet } from "react-helmet";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

export class Login extends Component {

    constructor() {
        super()
        this.state = {
            email: '',
            password: '',
            passwordType: true,
            emails: ['fahim@gmail.com', 'maurice@gmail.com', 'sarmad@gmail.com'],
            doorgaan: true,
            emailPopup: false,
            loginData: '',
            forgetWorking: false,
            forgetemail: "",
            forgetPopup: false,
            forgetStatus: "",
            forgetMessage: "",
            // Edit data
            isAdmin: false,
            heading: "",
            headingInput: false,
            desc: "",
            descInput: false,
            right_heading_1: "",
            right_heading_1Input: false,
            right_heading_2: "",
            right_heading_2Input: false,
            login_info_1: "",
            login_info_1Input: false,
            login_info_2: "",
            login_info_2Input: false,
            login_info_3: "",
            login_info_3Input: false,
            login_right_text: "",
            login_right_textInput: false,
            online_payment: true,
        }
    }

    componentDidMount() {
        let accessToken = localStorage.getItem("usertoken")
        if (accessToken) {
            window.location.href = "/account"
        }
        localStorage.setItem("loginEmail", '')


        let loginData = localStorage.getItem("headerData")
        if (loginData) {
            loginData = JSON.parse(loginData)
            this.setState({
                loginData,
                heading: loginData.login_page_heading,
                desc: loginData.login_page_desc,
                right_heading_1: loginData.heading_1,
                right_heading_2: loginData.heading_2,
                login_info_1: loginData.login_info1,
                login_info_2: loginData.login_info2,
                login_info_3: loginData.login_info3,
                login_right_text: loginData.real_login_right_text,
                online_payment: loginData.onliny_payment,
            })
            if (!loginData.onliny_payment) {
                window.location.href = "/"
            }
        }

        fetch(`${gv}/api/v1/list/of/values`)
            .then(response => response.json())
            .then(result => {
                let loginData = result.data
                localStorage.setItem("headerData", JSON.stringify(loginData))
                this.setState({
                    loginData,
                    heading: loginData.login_page_heading,
                    desc: loginData.login_page_desc,
                    right_heading_1: loginData.heading_1,
                    right_heading_2: loginData.heading_2,
                    login_info_1: loginData.login_info1,
                    login_info_2: loginData.login_info2,
                    login_info_3: loginData.login_info3,
                    login_right_text: loginData.real_login_right_text,
                    online_payment: loginData.onliny_payment,
                })
                if (!loginData.onliny_payment) {
                    window.location.href = "/"
                }
            })
            .catch(error => console.log('error', error));

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }
    }

    checkEmails() {
        const { email } = this.state
        if (email !== "" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            var formdata = new FormData();
            formdata.append("email", email);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/check/email`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "Customer is available!") {
                        this.setState({ doorgaan: false, emailPopup: false })
                    } else {
                        localStorage.setItem("loginEmail", email)
                        window.location.href = "/registration"
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ emailPopup: true })
        }
    }

    login() {
        const { email, password } = this.state
        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("password", password);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/auth/login`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    localStorage.setItem("usertoken", result.data.token)
                    this.setState({ normalPopup: false })
                    window.location.href = "/account"
                } else {
                    this.setState({ normalPopup: true })
                }
            })
            .catch(error => console.log('error', error));
    }


    updateSec() {
        const { heading, desc, right_heading_1, right_heading_2, login_info_1, login_info_2, login_info_3, login_right_text } = this.state

        let arr = [
            { key: "login_page_heading", value: heading },
            { key: "login_page_desc", value: desc },
            { key: "login_page_heading_1", value: right_heading_1 },
            { key: "login_page_heading_2", value: right_heading_2 },
            { key: "login_page_login_info1", value: login_info_1 },
            { key: "login_page_login_info2", value: login_info_2 },
            { key: "login_page_login_info3", value: login_info_3 },
            { key: "login_page_login_right_text", value: login_right_text },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                fetch(`${gv}/api/v1/list/of/values`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data
                        localStorage.setItem("headerData", JSON.stringify(resp))
                    })
                    .catch(error => {
                        this.setState({ showErrorModal: true })
                    });
            })
            .catch(error => console.log('error', error));
    }

    forgetPassword() {
        var formdata = new FormData();
        formdata.append("resend_email_input", this.state.forgetemail);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/auth/resend/password`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({ forgetPopup: true, forgetStatus: result.data.success, forgetMessage: result.data.message })
            })
            .catch(error => console.log('error', error));
    }

    render() {
        return (
            <div className="loginpage">
                <Navbar headerData={this.state.loginData} />
                <Helmet>
                    <title>{this.state.loginData.login_page_title}</title>
                </Helmet>
                <div className="login-wrapper">
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            <div className="page-layout-left">
                                <span className="page-layout-left-icon">
                                    {/* Logo */}
                                </span>
                                {!this.state.isAdmin ? <h3 className="page-layout-left-title">
                                    <font>{this.state.heading}</font>
                                </h3> : <h3 className="page-layout-left-title" onClick={() => this.setState({ headingInput: true })}>
                                        <font>
                                            {!this.state.headingInput ? this.state.heading : <input type="text" style={{ width: "100%", backgroundColor: "transparent", border: "none", outline: "none" }} autoFocus value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} />}
                                        </font>
                                    </h3>}
                                {!this.state.isAdmin ? <p className="page-layout-left-subtitle">
                                    {this.state.desc}
                                </p> : <p className="page-layout-left-subtitle" onClick={() => this.setState({ descInput: true })}>
                                        {!this.state.descInput ? this.state.desc : <input type="text" style={{ width: "100%", backgroundColor: "transparent", border: "none", outline: "none" }} autoFocus value={this.state.desc} onChange={(e) => this.setState({ desc: e.target.value })} onBlur={() => this.setState({ descInput: false })} />}
                                    </p>}
                                {!this.state.forgetWorking && <div className="login-panel_form">
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>E-mail address</label>
                                            <input type="text" placeholder="E-mail address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                            {this.state.emailPopup && <span className="alert-fields">Email should be valid.</span>}
                                        </div>
                                    </div>
                                    {!this.state.doorgaan && <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>Password</label>
                                            <input placeholder="Password..." type={this.state.passwordType ? "password" : "text"} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                            <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                {this.state.passwordType ? <VisibilityOffIcon style={{ fontSize: 20 }} /> : <VisibilityIcon style={{ fontSize: 20 }} />}
                                            </button>
                                        </div>
                                    </div>}
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            {this.state.normalPopup && <span className="alert-fields">Please provide valid data.</span>}
                                        </div>
                                    </div>
                                    {!this.state.doorgaan && <a className="password-reset" onClick={() => this.setState({ forgetWorking: true })}>Wachtwoord vergeten?</a>}
                                    {this.state.doorgaan ? <div className="form-row form-row-button">
                                        <button onClick={this.checkEmails.bind(this)}>DOORGAAN</button>
                                    </div> : <div className="form-row form-row-button">
                                            <button onClick={this.login.bind(this)}>LOGIN</button>
                                        </div>}
                                </div>}
                                {this.state.forgetWorking && <div className="login-panel_form">
                                    <button style={{ backgroundColor: "transparent", border: "none", outline: "none", padding: "10px 20px", fontFamily: "Roboto", position: "absolute", top: 100, left: 0, fontSize: 12, letterSpacing: 1, textDecorationLine: "underline", fontWeight: "bold" }} onClick={() => this.setState({ forgetWorking: false, forgetemail: "" })} className="bodyText" >BACK</button>
                                    <div className="form-row" style={{ marginTop: 100 }}>
                                        <div className="field-wrapper">
                                            <label>E-mail</label>
                                            <input type="text" placeholder="E-mail address" value={this.state.forgetemail} onChange={(e) => this.setState({ forgetemail: e.target.value })} />
                                            {this.state.forgetPopup && <span className="alert-fields" style={{ color: this.state.forgetStatus === 1 ? "#79a3a2" : "red", marginTop: 15 }}>{this.state.forgetMessage}</span>}
                                        </div>
                                    </div>
                                    <div className="form-row form-row-button" style={{ marginTop: 55 }}>
                                        <button onClick={this.forgetPassword.bind(this)}>SUBMIT</button>
                                    </div>
                                </div>}
                            </div>
                            <div className="page-layout-right">
                                <div className="account-benefits">
                                    <div className="benefits-wrapper">
                                        <div className="content-asset">
                                            <span className="benefits-subtitle">{this.state.right_heading_1}</span>
                                            <h3 className="benefits-title">{this.state.right_heading_2}</h3>
                                            <ul className="benefits-list">
                                                <li>
                                                    <span className="icon-check">
                                                        <CheckIcon />
                                                    </span>
                                                    <span>{this.state.login_info_1}</span>
                                                </li>
                                                <li>
                                                    <span className="icon-check">
                                                        <CheckIcon />
                                                    </span>
                                                    <span>{this.state.login_info_2}</span>
                                                </li>
                                                <li>
                                                    <span className="icon-check">
                                                        <CheckIcon />
                                                    </span>
                                                    <span>{this.state.login_info_3}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        {this.state.login_right_textInput && <p onClick={() => this.setState({ login_right_text: `${this.state.login_right_text} [gift_order_price]` })} style={{ width: "100%", marginTop: 30 }}>
                                            Add Gift Order Price
                                        </p>}
                                        <div className="benefits-dynamic-block">
                                            {this.state.loginData.login_right_image && <div className="image-holder">
                                                <img src={this.state.loginData.login_right_image} alt="lri" />
                                            </div>}
                                            <div className="text-holder">
                                                <p>
                                                    {this.state.loginData.login_right_text}
                                                </p>
                                                <p>
                                                    {this.state.loginData.gift_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default Login
