import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { SketchPicker } from 'react-color'
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';

import 'react-day-picker/lib/style.css';

export class AppointmentTemplateEdit extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: [],
            imageURL: "https://saastoday.nl/public/retailer_phase_assets/sections/fifth.jpg",
            timingsExact: [],
            selectedTime: "",
            selectedDay: new Date(),
            selectedTimeAlert: false,
            dateDropDown: false,
            timeDropDown: false,
            headerData: "",
            heading: "SHOPPEN OF AFSPRAAK",
            headingInput: false,
            headingSec: "MAAK EN AFSPRAAK",
            headingSecInput: false,
            descSec: "BOEK NU",
            descSecInput: false,
            delete_image: false,
            rightColor: "#7C6C56",
            spaceTop: true,
            spaceBottom: true,
            fullWidth: true,
            alertColor: "red"
        }
    }

    componentDidMount() {

        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)
            console.log(parsedData)
            this.setState({
                heading: parsedData.main_heading, headingSec: parsedData.right_heading, descSec: parsedData.right_desc, spaceTop: parsedData.space_top, spaceBottom: parsedData.space_bottom, fullWidth: parsedData.full_width, rightColor: parsedData.backgroundColor,
                leftHeadingColor: parsedData.leftHeadingColor,
                rightHeadingColor: parsedData.rightHeadingColor,
                rightDescColor: parsedData.rightDescColor,
                buttonBackgroundColor: parsedData.buttonBackgroundColor,
                buttonFontColor: parsedData.buttonFontColor,
                alertColor: parsedData.alertColor,
                imageURL: parsedData.left_image,
            })

            let splitted = parsedData.left_image.split('/')
            if (splitted[splitted.length - 1] !== "fifth.jpg") {
                this.setState({ delete_image: true })
            }
        }

        let currentPage = window.location.pathname
        let layout_type = "layout_17"
        let currentTime = Math.floor(Date.now() / 1000)
        let pageName = ""
        if (currentPage === "/") {
            pageName = "landing_page"
        } else {
            if (currentPage.includes("%20")) {
                pageName = currentPage.split("%20").join("_")
            } else {
                pageName = currentPage
            }
            pageName = pageName.split("/").join('')
        }
        let sectionType = pageName + '_' + layout_type + '_' + currentTime
        this.setState({ sectionType })

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }



    uploadImg(e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            let type = e.target.files[0].type.split('/')[0]
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0] })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    this.setState({ originalImageWidth: width, originalImageHeight: height })
                    if (width > height) {
                        if (width > 948) {
                            let crop = {
                                unit: '%',
                                width: 100,
                                height: 98,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        } else {
                            let crop = {
                                unit: 'px',
                                width: 948,
                                height: 600,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 98,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }



    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        console.log(image.naturalWidth)
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.height = crop.height;
        canvas.width = crop.width
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (file) {
            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", this.state.sectionType);
            formdata.append("image", file);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ imageURL: result.data.image_url, delete_image: true, loader: false, banner_type: "image", croppedImageUrl: "", cropper: false })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    removeImage() {
        this.setState({ loader: true })

        const { imageURL } = this.state
        let splitted = imageURL.split("/")

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("section_type", this.state.sectionType);
        formdata.append("image_name", splitted[splitted.length - 1]);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    this.setState({ imageURL: "https://saastoday.nl/public/retailer_phase_assets/sections/fifth.jpg", delete_image: false, loader: false, banner_type: "image" })
                }
            })
            .catch(error => console.log('error', error));
    }


    showCurrentImage() {
        this.setState({ cropper: false, imageURL: "https://saastoday.nl/public/retailer_phase_assets/sections/fifth.jpg", croppedImageUrl: "" })
    }


    buttonBackgroundChange = (color) => {
        this.setState({ buttonBackgroundColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    buttonFontChange = (color) => {
        this.setState({ buttonFontColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    rightHeadingChange = (color) => {
        this.setState({ rightHeadingColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    leftHeadingChange = (color) => {
        this.setState({ leftHeadingColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    rightDescChange = (color) => {
        this.setState({ rightDescColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    rightColorChange = (color) => {
        this.setState({ rightColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    alertColorChange = (color) => {
        this.setState({ alertColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    saveSection() {
        const { imageURL, heading, headingSec, descSec, wholeData } = this.state
        this.setState({ createLoader: true })
        let obj = {
            type: "layout_17",
            main_heading: heading,
            right_heading: headingSec,
            right_desc: descSec,
            left_image: imageURL,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            full_width: this.state.fullWidth,
            backgroundColor: this.state.rightColor,
            leftHeadingColor: this.state.leftHeadingColor,
            rightHeadingColor: this.state.rightHeadingColor,
            rightDescColor: this.state.rightDescColor,
            buttonBackgroundColor: this.state.buttonBackgroundColor,
            buttonFontColor: this.state.buttonFontColor,
            alertColor: this.state.alertColor,
        }

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="appointment-template">
                        <div className="at-first" style={{ backgroundImage: `url(${this.state.imageURL})`, position: "relative" }}>
                            {this.state.loader && <div className="loader-working-fb">
                                <div className="lds-facebook"><div></div><div></div><div></div></div>
                            </div>}
                            <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                                <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                                <PublishIcon style={{ color: "white" }} />
                            </div>
                            {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                            </div>}
                            <h3 style={{ color: this.state.leftHeadingColor }} onClick={() => this.setState({ headingInput: true })} onBlur={() => this.setState({ headingInput: false })}>
                                {!this.state.headingInput ? this.state.heading : <input autoFocus type="text" style={{ color: "black" }} value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} />}
                            </h3>
                        </div>
                        <div className="at-second" style={{ backgroundColor: this.state.rightColor }}>
                            <h4 style={{ color: this.state.rightHeadingColor }} onClick={() => this.setState({ headingSecInput: true })} onBlur={() => this.setState({ headingSecInput: false })}>
                                {!this.state.headingSecInput ? this.state.headingSec : <input autoFocus type="text" style={{ color: "black" }} value={this.state.headingSec} onChange={(e) => this.setState({ headingSec: e.target.value })} onBlur={() => this.setState({ headingSecInput: false })} />}
                            </h4>
                            <p style={{ color: this.state.rightDescColor }} onClick={() => this.setState({ descSecInput: true })} onBlur={() => this.setState({ descSecInput: false })}>
                                {!this.state.descSecInput ? this.state.descSec : <textarea autoFocus type="text" style={{ color: "black", width: "100%", minHeight: 200 }} value={this.state.descSec} onChange={(e) => this.setState({ descSec: e.target.value })} onBlur={() => this.setState({ descSecInput: false })}></textarea>}
                            </p>
                        </div>
                        <div className="overlay-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }}>

                        </div>
                        <div className="popup-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none", height: "auto" }} >
                            <CloseIcon style={{ position: "absolute", top: 295, right: 20 }} onClick={() => this.setState({ themePickerOpen: false })} />
                            <div className="color-working-popup" style={{ flexWrap: "wrap", height: 1200, alignItems: "center", paddingTop: 300 }}>
                                <div className="first-div-cwp">
                                    <label>Right section colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.rightColor}
                                        onChangeComplete={this.rightColorChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Left Heading colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.leftHeadingColor}
                                        onChangeComplete={this.leftHeadingChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Right Heading colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.rightHeadingColor}
                                        onChangeComplete={this.rightHeadingChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Right desc colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.rightDescColor}
                                        onChangeComplete={this.rightDescChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Button Background colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.buttonBackgroundColor}
                                        onChangeComplete={this.buttonBackgroundChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Button Font colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.buttonFontColor}
                                        onChangeComplete={this.buttonFontChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Alert Font colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.alertColor}
                                        onChangeComplete={this.alertColorChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {!this.state.cropper && !this.state.createLoader && !this.state.themePickerOpen && <div className="footer-allpopups-sections" style={{ bottom: this.state.banner_type === "video" && -15 }}>
                            <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                                <ColorizeIcon />
                                <font>Right Section Colour</font>
                            </div>
                            <div className="inner-faps">
                                <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                                    this.setState({ spaceBottom: !this.state.spaceBottom })
                                }} />
                                <font>RUIMTE ONDER</font>
                            </div>
                            <div className="inner-faps">
                                <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                                    this.setState({ spaceTop: !this.state.spaceTop })
                                }} />
                                <font>RUIMTE BOVEN</font>
                            </div>
                            <div className="inner-faps">
                                <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.fullWidth} onChange={() => {
                                    this.setState({ fullWidth: !this.state.fullWidth })
                                }} />
                                <font>PAGINABREEDTE</font>
                            </div>
                            {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                                <SaveIcon />
                                <font>SAVE</font>
                            </div>}
                        </div>}
                    </div>}
            </div>
        )
    }
}

export default AppointmentTemplateEdit
