import React, { Component } from 'react'

export class BannerSecData extends Component {

    createMarkup() {
        if (this.props.data.desc) {
            return { __html: this.props.data.desc };
        }
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <div className="bannersec-home" style={{ backgroundImage: `url(${this.props.data.image_url})`, marginTop: !this.props.data.space_top ? 0 : 60, maxWidth: !this.props.data.full_width && 1440, marginBottom: !this.props.data.space_bottom ? 0 : 60 }}>
                    <div className={`inner-bsh ${this.props.data.theming && "themingibsh"}`}>
                        <div className="overlay-bsh">

                        </div>
                        <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BannerSecData
