import React, { Component } from 'react'

export class FrameSecData extends Component {

    constructor() {
        super()
        this.state = {
            iframe: "",
            // footer section work
            spaceTop: true,
            spaceBottom: true,
        }
    }

    componentDidMount() {
        this.setState({ iframe: this.props.data.iframe })
    }

    iframe() {
        return {
            __html: `<iframe id="frame" src="${this.props.data.iframe}" width="60%" height="750" style="border: none;"></iframe>`
        }
    }

    render() {
        return (
            <div>
                <div style={{ width: "100%", height: "100%", textAlign: "center", marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60 }}>
                    <div dangerouslySetInnerHTML={this.iframe()}></div>
                </div>
            </div>
        )
    }
}

export default FrameSecData
