import React, { Component } from 'react'
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slides from '../Templates/Slides';
import TextTemplate from '../Templates/TextTemplate';
import FrameSec from '../Templates/FrameSec';
import FooterContact from '../Templates/FooterContact';
import BannerSec from '../Templates/BannerSec';
import Banner from '../Templates/Banner';
import DoubleCard from '../Templates/DoubleCard';
import HorizontalProducts from '../Templates/HorizontalProducts';
import Collectie from '../Templates/Collectie';
import Ontdek from '../Templates/Ontdek';
import ProductCollection from '../Templates/ProductCollection';
import BrandList from '../Templates/BrandList';
import Contact from '../Templates/Contact';
import BrandsShow from '../Templates/BrandsShow';
import TwoTile from '../Templates/TwoTile';
import FaqTemplate from '../Templates/FaqTemplate';

export class CreaterSection extends Component {
    constructor() {
        super()
        this.state = {
            arrTemplates: [
                { number: "4", templateType: "layout_4" },
                { number: "3", templateType: "layout_3" },
                { number: "9", templateType: "layout_9" },
                { number: "1", templateType: "layout_1" },
                { number: "5", templateType: "layout_5" },
                { number: "6", templateType: "layout_6" },
                { number: "7", templateType: "layout_7" },
                { number: "2", templateType: "layout_2" },
                { number: "8", templateType: "layout_8" },
                { number: "10", templateType: "layout_10" },
                { number: "11", templateType: "layout_11" },
                { number: "12", templateType: "layout_12" },
                { number: "13", templateType: "layout_13" },
                { number: "14", templateType: "layout_14" },
                { number: "15", templateType: "layout_15" },
                { number: "15", templateType: "layout_16" },
            ],
            arrModals: {
                createPopup: false,
                editPopup: false,
            },
            startingArray: 0,
            endingArray: 6,
        }
    }

    render() {
        return (
            <div>
                {/* Create New Modal */}
                <div className="modal-popup-new" style={{ opacity: this.state.arrModals.createPopup ? 1 : 0, pointerEvents: this.state.arrModals.createPopup ? "all" : "none" }}>

                </div>
                <div className="modal-new" style={{ opacity: this.state.arrModals.createPopup ? 1 : 0, pointerEvents: this.state.arrModals.createPopup ? "all" : "none", width: this.state.selectedTemplate === "layout_14" && "90%" }}>
                    {!this.state.arrModals.formCreate && <ClearIcon style={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={() => {
                        let arr = this.state.arrModals
                        arr.createPopup = false
                        this.setState({ arrModals: arr })
                    }} />}
                    {!this.state.arrModals.formCreate && <h3 className="heading-popup">
                        SELECTEER LAYOUT
                            </h3>}
                    {!this.state.arrModals.formCreate && <div className="create-popup" style={{ paddingTop: 20 }}>
                        <div className="pagination-cp">
                            <ul>
                                <li onClick={() => {
                                    this.setState({ startingArray: 0, endingArray: 6, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                }}>1</li>
                                <li onClick={() => {
                                    this.setState({ startingArray: 6, endingArray: 12, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                }}>2</li>
                                <li onClick={() => {
                                    this.setState({ startingArray: 12, endingArray: 16, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                }}>3</li>
                            </ul>
                        </div>
                        {this.state.arrTemplates.slice(this.state.startingArray, this.state.endingArray).map((e) => {
                            return <div className="all-templates" key={Math.random()} style={{ backgroundImage: `url(https://saastoday.nl/public/retailer_phase_assets/sections/template${e.number}.png)` }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = true
                                this.setState({ arrModals: arr, selectedTemplate: e.templateType })
                            }}></div>
                        })}
                    </div>}
                    {/* Form Create */}
                    {this.state.arrModals.formCreate && <div className="create-popup">
                        {this.state.selectedTemplate === "layout_1" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <DoubleCard />
                        </div> : this.state.selectedTemplate === "layout_2" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <Banner />
                        </div> : this.state.selectedTemplate === "layout_3" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <TwoTile />
                        </div> : this.state.selectedTemplate === "layout_4" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <ProductCollection />
                        </div> : this.state.selectedTemplate === "layout_5" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <BrandList />
                        </div> : this.state.selectedTemplate === "layout_6" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <Contact />
                        </div> : this.state.selectedTemplate === "layout_7" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <HorizontalProducts />
                        </div> : this.state.selectedTemplate === "layout_8" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <Collectie />
                        </div> : this.state.selectedTemplate === "layout_9" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <Ontdek />
                        </div> : this.state.selectedTemplate === "layout_10" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <Slides />
                        </div> : this.state.selectedTemplate === "layout_11" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <FooterContact />
                        </div> : this.state.selectedTemplate === "layout_12" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <FrameSec />
                        </div> : this.state.selectedTemplate === "layout_13" ? <div style={{ width: "100%", position: "relative", padding: 30, paddingBottom: 90 }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <BannerSec />
                        </div> : this.state.selectedTemplate === "layout_14" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <BrandsShow />
                        </div> : this.state.selectedTemplate === "layout_15" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <TextTemplate />
                        </div> : this.state.selectedTemplate === "layout_16" && <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr })
                            }} />
                            <FaqTemplate />
                        </div>}
                    </div>}
                </div>
                {/* Create New Modal */}
                <div style={{ width: "100%", height: 800, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <button onClick={() => {
                        let arr = this.state.arrModals
                        arr.createPopup = true
                        this.setState({ arrModals: arr })
                        localStorage.setItem("currentOrder", 0)
                    }} className="bodyText" style={{ backgroundColor: "#79a3a2", color: "white", letterSpacing: 1, fontSize: 13, border: "none", outline: "none", padding: "10px 15px", fontFamily: "Roboto" }}>Create Section</button>
                </div>
            </div>
        )
    }
}

export default CreaterSection
