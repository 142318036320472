import React, { Component } from 'react'
import Navbar from '../../Components/Navbar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './index.css'
import giftIcon from '../../assets/gift.png'
import { gv } from '../../Components/globalvar'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

class HomeSec extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: '',
            index: 0,
            getDataHeading: false,
            showTheWork: true,
            giftData: '',
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/gift/card`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let data = result.data.gift_card_data
                data.replace(/\/$/, "")
                let resp = JSON.parse(data)
                this.setState({ wholeData: resp, giftData: result.data })
            })
            .catch(error => console.log('error', error));
    }

    addtocart() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        let imgtodrag = document.getElementsByClassName('gift-icon')[0];
        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName('cart-icon')[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style = 'z-index: 1111; width: 150px;opacity:0.8; position:fixed; top:' + disTop + 'px;left:' + disLeft + 'px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)';

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + 'px';
            image.style.top = carttop + 'px';
            image.style.width = '40px';
            image.style.opacity = '0';
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            let price = this.state.wholeData.prices[this.state.index]

            var formdata = new FormData();
            formdata.append("product_id", 1);
            formdata.append("qty", 1);
            formdata.append("product_type", "gift");
            formdata.append("price", price);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/add/cart?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    localStorage.setItem("cartData", JSON.stringify(resp))
                    this.setState({ getDataHeading: true })
                    setTimeout(() => {
                        this.setState({ getDataHeading: false })
                    }, 400);
                })
                .catch(error => console.log('error', error));
        } else {
            let price = this.state.wholeData.prices[this.state.index]
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("product_id", 1);
            formdata.append("qty", 1);
            formdata.append("product_type", "gift");
            formdata.append("price", price);
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/add/cart?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    localStorage.setItem("cartData", JSON.stringify(resp))
                    this.setState({ getDataHeading: true })
                    setTimeout(() => {
                        this.setState({ getDataHeading: false })
                    }, 400);
                })
                .catch(error => console.log('error', error));
        }
    }

    removeItem = () => {
        return true
    }

    check = () => {
        this.setState({ showTheWork: !this.state.showTheWork })
    }

    render() {
        return (
            <div className="giftcardfooter">
                <Navbar getData={this.state.getDataHeading} removeItem={this.removeItem} checkForGC={this.check} />
                <div className="hsdiv" style={{ position: "fixed", width: "100%", height: "100%" }}>
                    {this.state.showTheWork && <div>
                        <div className="hs-main" style={{ backgroundImage: `url(${this.state.wholeData.background_image})` }}>
                            <div className="video-cardhs giftcarddynamic">
                                <h3>{this.state.giftData.gift_card_title}</h3>
                                <img src={giftIcon} style={{ position: "absolute", opacity: 0 }} className="gift-icon" />
                                <p>{this.state.wholeData.description}</p>
                                <div className="price-barhs">
                                    <div className="price-tab">
                                        <font>€ {this.state.wholeData.prices && this.state.wholeData.prices[this.state.index]}</font>
                                    </div>
                                    <div className="price-tabu" onClick={() => {
                                        let index = this.state.index
                                        if (index > 0) {
                                            index--
                                            this.setState({ index })
                                        }
                                    }}>
                                        <RemoveIcon />
                                    </div>
                                    <div className="price-tabd" onClick={() => {
                                        let index = this.state.index
                                        if (index < this.state.wholeData.prices.length - 1) {
                                            index++
                                            this.setState({ index })
                                        }
                                    }}>
                                        <AddIcon />
                                    </div>
                                </div>
                                <div className="price-barhs" onClick={this.addtocart.bind(this)}>
                                    <button>{this.state.giftData.gift_card_button_text}</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

export default HomeSec