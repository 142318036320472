import React, { Component } from 'react'
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';

export class OntdekData extends Component {

    constructor() {
        super()
        this.state = {
            data: '',
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })
    }


    createMarkup() {
        if (this.props.data.right_desc) {
            return { __html: this.props.data.right_desc };
        }
    }

    render() {
        return (
            <div className="last-sec-home" style={{ padding: 0, marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                <div className="inner-wrapper" style={{ maxWidth: 1440, margin: "0px auto", padding: 0, flexDirection: this.state.data.is_flipped ? "row-reverse" : "row" }}>
                    <div className="img-lah">
                        <img alt="showingyet" src={this.state.data.left_image} style={{ width: "72%", margin: "0px auto", display: "block" }} />
                    </div>
                    <div className="content-lah">
                        <h3>{this.state.data.right_heading1}</h3>
                        <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                        {this.state.data.is_button_route && <button onClick={() => {
                            this.state.data.button_url.includes(".com") ? window.location.href = `https://${this.state.data.button_url}` : window.location.href = this.state.data.button_url
                        }}>{this.state.data.button_text}</button>}
                        {this.state.data.right_video && <span onClick={() => {
                            window.location.href = this.state.data.video_url
                        }}>
                            <SlowMotionVideoIcon style={{ marginRight: 6, fontSize: 27 }} />Bekijk De Video
                        </span>}
                    </div>
                </div>
            </div >
        )
    }
}

export default OntdekData
