import React, { Component } from 'react'
import Navbar from '../../Components/Navbar'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import DraftsIcon from '@material-ui/icons/Drafts';
import { gv } from '../../Components/globalvar'
import './index.css'
import Leftmenu from '../../Components/Leftmenu';
import { Helmet } from "react-helmet";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

export class Newsletter extends Component {

    constructor() {
        super()
        this.state = {
            popupright: false,
            wholeData: [],
            newsletter: false,
            giftsletter: false,
            email: '',
            parsedData: [],
            // Edit Working
            isAdmin: false,
            subscription_page_title_1: "",
            subscription_page_title_1Input: false,
            subscription_page_desc_1: "",
            subscription_page_desc_1Input: false,
            subscription_page_desc_2: "",
            subscription_page_desc_2Input: false,
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        let usertoken = localStorage.getItem("usertoken")
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${usertoken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        fetch(`${gv}/api/v1/auth/getuser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data.news_letter_activated === "yes") {
                    this.setState({ newsletter: true })
                } else {
                    this.setState({ newsletter: false })
                }
                if (result.data.gifts_letter_activated === "yes") {
                    this.setState({ giftsletter: true })
                } else {
                    this.setState({ giftsletter: false })
                }
                this.setState({ email: result.data.email, userName: result.data.name, lastName: result.data.last_name })
            })
            .catch(error => console.log('error', error));

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let parsed = JSON.parse(headerData)
            this.setState({ parsedData: parsed, subscription_page_title_1: parsed.subscription_page_title_1, subscription_page_desc_1: parsed.subscription_page_desc_1, subscription_page_desc_2: parsed.subscription_page_desc_2 })
        }

        fetch(`${gv}/api/v1/list/of/values`)
            .then(response => response.json())
            .then(result => {
                let parsed = result.data
                localStorage.setItem("headerData", JSON.stringify(parsed))
                this.setState({ parsedData: parsed, subscription_page_title_1: parsed.subscription_page_title_1, subscription_page_desc_1: parsed.subscription_page_desc_1, subscription_page_desc_2: parsed.subscription_page_desc_2 })
            })
            .catch(error => console.log('error', error));
    }

    updateData() {
        let usertoken = localStorage.getItem("usertoken")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${usertoken}`);
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        let newsletter = ''
        let giftsletter = ''
        if (this.state.newsletter === true) {
            newsletter = "yes"
        } else {
            newsletter = "no"
        }
        if (this.state.giftsletter === true) {
            giftsletter = "yes"
        } else {
            giftsletter = "no"
        }
        formdata.append("news_letter_activated", newsletter);
        formdata.append("gifts_letter_activated", giftsletter);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/auth/customer/update/subscription`, requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    updateSec() {
        const { subscription_page_title_1, subscription_page_desc_1, subscription_page_desc_2 } = this.state

        let arr = [
            { key: "subscription_page_title_1", value: subscription_page_title_1 },
            { key: "subscription_page_desc_1", value: subscription_page_desc_1 },
            { key: "subscription_page_desc_2", value: subscription_page_desc_2 },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                fetch(`${gv}/api/v1/list/of/values`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data
                        localStorage.setItem("headerData", JSON.stringify(resp))
                    })
                    .catch(error => {
                        this.setState({ showErrorModal: true })
                    });
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    render() {
        return (
            <div className="newsletter">
                <Navbar headerData={this.state.loginData} />
                {this.state.parsedData && <Helmet>
                    <title>{this.state.parsedData.newsletter_page_title}</title>
                </Helmet>}
                <div className="account-main">
                    <div className="account-wrapper">
                        <h1 className="account-title" style={{ display: "flex", alignItems: "center" }}>
                            <span className="icon-user">
                                <AccountCircleOutlinedIcon style={{ fontSize: 34 }} />
                            </span>
                            <font style={{ textTransform: "uppercase" }}>{this.state.userName} {this.state.lastName}</font>
                        </h1>
                        <div className="account-left-content">
                            <Leftmenu />
                        </div>
                        <div className="account-right-content">
                            <div className="section-title">
                                <h2>
                                    <span>
                                        <DraftsIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2 }} />
                                    </span>
                                    {this.state.isAdmin ? <span onClick={() => this.setState({ subscription_page_title_1Input: true })}>
                                        {!this.state.subscription_page_title_1Input ? this.state.subscription_page_title_1 : <input type="text" autoFocus value={this.state.subscription_page_title_1} onChange={(e) => this.setState({ subscription_page_title_1: e.target.value })} onBlur={() => this.setState({ subscription_page_title_1Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                    </span> : <span>
                                            {this.state.subscription_page_title_1}
                                        </span>}
                                </h2>
                            </div>
                            <div className="main-content-wrapper">
                                <div className="newsletter">
                                    <div className="newsletter-content">
                                        {this.state.isAdmin ? <p onClick={() => this.setState({ subscription_page_desc_1Input: true })}>
                                            {!this.state.subscription_page_desc_1Input ? this.state.subscription_page_desc_1 : <textarea type="text" autoFocus value={this.state.subscription_page_desc_1} onChange={(e) => this.setState({ subscription_page_desc_1: e.target.value })} onBlur={() => this.setState({ subscription_page_desc_1Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none", height: 120 }}></textarea>}
                                        </p> : <p>
                                                {this.state.subscription_page_desc_1}
                                            </p>}
                                        {this.state.isAdmin ? <label className="signup-label">
                                            {!this.state.isAdmin && <div className="newsletter-checkbox">
                                                <div className="inputfield-row">
                                                    <input type="checkbox" checked={this.state.newsletter} onChange={(e) => {
                                                        this.setState({ newsletter: !this.state.newsletter })
                                                    }} className="input-checkbox" id="addtoemaillist" />
                                                    <label htmlFor="addtoemaillist"></label>
                                                </div>
                                            </div>}
                                            <span className="newsletter-label" onClick={() => this.setState({ subscription_page_desc_2Input: true })}>
                                                {!this.state.subscription_page_desc_2Input ? this.state.subscription_page_desc_2 : <input type="text" autoFocus value={this.state.subscription_page_desc_2} onChange={(e) => this.setState({ subscription_page_desc_2: e.target.value })} onBlur={() => this.setState({ subscription_page_desc_2Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                            </span>
                                        </label> : <label className="signup-label">
                                                <div className="newsletter-checkbox">
                                                    <div className="inputfield-row">
                                                        <input type="checkbox" checked={this.state.newsletter} onChange={(e) => {
                                                            this.setState({ newsletter: !this.state.newsletter })
                                                        }} className="input-checkbox" id="addtoemaillist" />
                                                        <label htmlFor="addtoemaillist"></label>
                                                    </div>
                                                </div>
                                                <span className="newsletter-label">
                                                    {this.state.subscription_page_desc_2}
                                                </span>
                                            </label>}
                                    </div>
                                    <div className="newsletter-row">
                                        <div>
                                            <button onClick={this.updateData.bind(this)}>SAVE</button>
                                        </div>
                                        <div className="linked-email-container">
                                            <p>
                                                linked to:
                                                <br />
                                                {this.state.email}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default Newsletter
