import React, { Component } from 'react'
import Navbar from '../../Components/Navbar'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import './index.css'
import InputComponent from '../../Components/InputComponent';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

AOS.init();

export class BrandList extends Component {
    constructor() {
        super()
        this.state = {
            arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            wholeData: [],
            child_menus: [],
            // Editing
            isAdmin: false,
            title: "",
            titleInput: false,
            description: "",
            descriptionInput: false,
        }
        // this.getData = this.getData.bind(this)
        this.getNextData = this.getNextData.bind(this)
        this.getChildMenus = this.getChildMenus.bind(this)
    }


    componentDidMount() {
        this.getChildMenus()
        this.setState({ blid: this.props.match.params.blid })

        window.onscroll = () => {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 200)) {
                if (!this.state.stopData && this.state.nextUrl !== null) {
                    this.getNextData()
                }
            }
        };

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        let browserType = ""

        // Broswe Detections
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
                this.setState({ browserType: "chrome" })
            } else {
                browserType = "safari"
                this.setState({ browserType: "safari" })
            }
        }

        this.getData(browserType)
    }

    getData(e) {
        fetch(`${gv}/api/v1/${this.props.match.params.blid}/merken?browser_type=${e}`)
            .then(response => response.json())
            .then(result => {
                let resp = result.data
                this.setState({ wholeData: resp.data, nextUrl: resp.next_page_url, page_title: resp.page_title, title: resp.title, description: resp.description, totalItems: resp.total, itemsPerPage: resp.per_page, currentPage: resp.current_page, stopData: false, nextDataShouldCome: true })
                localStorage.setItem(`${this.props.match.params.blid}/merken`, JSON.stringify(resp))
            })
            .catch(error => console.log('error', error));
    }

    getChildMenus() {
        let data = localStorage.getItem("headerData")
        if (data) {
            let parsed = JSON.parse(data)
            let resp = Object.entries(parsed.menus.child_menus)
            resp.map((e) => {
                if (e[0] === "merken") {
                    this.setState({ child_menus: e[1] })
                }
            })
        }
    }

    getNextData() {
        let wholeData = this.state.wholeData
        if (this.state.nextDataShouldCome) {
            this.setState({ stopData: true, loadingMore: true })
            fetch(`${this.state.nextUrl}&browser_type=${this.state.browserType}`)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    resp.data.forEach((e) => {
                        wholeData.push(e)
                    })
                    this.setState({ wholeData, stopData: false, loadingMore: false, nextUrl: resp.next_page_url, totalItems: resp.total, itemsPerPage: resp.per_page, currentPage: resp.current_page })
                })
                .catch(error => console.log('error', error));
        }
    }


    imgUploading(e, f) {
        let accesstoken = localStorage.getItem("adminKey")

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.blid);
            formdata.append("brand_id", f);
            formdata.append("image_type", "thumbnail");
            formdata.append("section_type", "brand_list_page_thumbnail");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        window.location.reload()
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }

    }

    removeImage(e, f) {
        let splitted = e.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.blid);
        formdata.append("brand_id", f);
        formdata.append("image_type", "thumbnail");
        formdata.append("section_type", "brand_list_page_thumbnail");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    close(e) {
        this.setState({ descriptionInput: false, description: e })
    }

    closeSec(e) {
        this.setState({ titleInput: false, title: e })
    }

    updateSec() {
        const { title, description } = this.state

        let arr = [{ key: "brand_list_page_title", value: title }, { key: "brand_list_page_description", value: description }]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("main_category", this.props.match.params.blid);
        formdata.append("brand_id", "merken");
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/dynamic/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.page_title}</title>
                </Helmet>
                <Navbar />
                <div className="brand-list-main" style={{ paddingBottom: 40 }}>
                    <div className="discover-block" style={{ paddingTop: 80 }}>
                        <div className="inner-wrapper-db" style={{ maxWidth: "100%" }}>
                            {this.state.isAdmin ? <a className="btn-scroll-down" onClick={() => this.setState({ titleInput: true })}>
                                {!this.state.titleInput ? <>
                                    {this.state.title}
                                    <br />
                                    <ExpandMoreIcon style={{ fontSize: 28, marginTop: 3 }} />
                                </> : <InputComponent dataText={this.state.title} blurInput={this.closeSec.bind(this)} />}
                            </a> : <a className="btn-scroll-down">
                                    {this.state.title}
                                    <br />
                                    <ExpandMoreIcon style={{ fontSize: 28, marginTop: 3 }} />
                                </a>}
                            <div className="short-desc">
                                {!this.state.isAdmin ? <p style={{ fontSize: 20 }}>
                                    {this.state.description}
                                </p> : this.state.description ? <p style={{ fontSize: 20 }} onClick={() => this.setState({ descriptionInput: true })}>
                                    {!this.state.descriptionInput ? this.state.description : <InputComponent dataText={this.state.description} blurInput={this.close.bind(this)} />}
                                </p> : <InputComponent dataText={this.state.description} blurInput={this.close.bind(this)} />}
                                {/* <p style={{ fontSize: 20 }}>
                                    {this.state.description}
                                </p> */}
                            </div>
                            <div className="tabs-holder">
                                <div className="tabs-items-holder">
                                    {this.state.child_menus && this.state.child_menus.length > 5 &&
                                        <ChevronLeftIcon onClick={() => {
                                            document.getElementById("tablist").scrollLeft -= 200
                                        }} />
                                    }
                                    <ul className="tabs" id="tablist" style={{ marginTop: 40, justifyContent: "center" }}>
                                        {this.state.child_menus.length > 0 && this.state.child_menus.map((e) => {
                                            return <li key={Math.random()} onClick={() => window.location.href = `/${e.id}/merken`}>
                                                <button className={this.props.match.params.blid === e.id ? "selected" : ''}>{e.name}</button>
                                            </li>
                                        })}
                                    </ul>
                                    {this.state.child_menus && this.state.child_menus.length > 5 &&
                                        <ChevronRightIcon onClick={() => {
                                            document.getElementById("tablist").scrollLeft += 200
                                        }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blists-main">
                        <div className="inner-bmain">
                            {this.state.wholeData.map((e) => {
                                return <div className="card-bmain" data-aos="fade-in" key={Math.random()} onClick={() => {
                                    if (!this.state.isAdmin) {
                                        window.location.href = `/collection-story/${this.props.match.params.blid}/${e.brand_id}`
                                    }
                                }}>
                                    <div className="card-inner-bmain">
                                        <p style={{ fontWeight: 550, fontSize: 16 }} onClick={() => {
                                            if (this.state.isAdmin) {
                                                window.location.href = `/collection-story/${this.props.match.params.blid}/${e.brand_id}`
                                            }
                                        }}>
                                            {e.name}
                                        </p>
                                        <div style={{ position: "relative" }}>
                                            {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 5 }}>
                                                <div className="uploading-beu">
                                                    <input type="file" onChange={(f) => this.imgUploading(f, e.brand_id)} accept="image/*" />
                                                    <PublishIcon />
                                                </div>
                                                {e.check_image_url && <div className="uploading-beu">
                                                    <DeleteIcon onClick={this.removeImage.bind(this, e.image_url, e.brand_id)} />
                                                </div>}
                                            </div>}
                                            <img src={e.image_url} alt="imgbl" style={{ maxWidth: "95%", minHeight: 275 }} onClick={() => {
                                                if (this.state.isAdmin) {
                                                    window.location.href = `/collection-story/${this.props.match.params.blid}/${e.brand_id}`
                                                }
                                            }} />
                                        </div>
                                        <a onClick={() => {
                                            if (this.state.isAdmin) {
                                                window.location.href = `/collection-story/${this.props.match.params.blid}/${e.brand_id}`
                                            }
                                        }}>LEES MEER</a>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.greaterSizeImage}
                    onClose={() => this.setState({ greaterSizeImage: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size exceeds 7MB</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default BrandList
