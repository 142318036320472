import React, { Component } from 'react'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from 'react-select';
import { gv } from '../Components/globalvar'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button';
import './index.css';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class BrandList extends Component {
    constructor() {
        super()
        this.state = {
            wholeData: [1, 2, 3, 4],
            firstInput: false,
            mainArrs: [],
            nestedArrs: [],
            brand_ids: [],
            arrSuggestions: [],
            selectedCategory: '',
            arrSelected: [],
            wholeData: '',
            greaterLength: false,
            limited: false,
            allBrands: [],
            open: false,
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            crop: {
                // unit: '%',
                width: 276,
                height: 276,
                aspect: 16 / 9,
            },
            createLoader: true,
            pageType: "brand_detail",
            onImageIndex: 0,
            onImageIndexSec: 0,
            onImageIndexThird: 0,
            onImageIndexFourth: 0,
            deleteImageFirst: false,
            deleteImageSec: false,
            deleteImageThird: false,
            deleteImageFourth: false,
            imageFirst: "",
            imageSecond: "",
            imageThird: "",
            imageFourth: "",
            firstImage: "",
            secondImage: "",
            thirdImage: "",
            fourthImage: "",
            imageNameFirst: "",
            imageNameSecond: "",
            imageNameThird: "",
            imageNameFourth: "",
        }
    }

    componentDidMount() {
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let data = JSON.parse(headerData)
            this.setState({ mainArrs: data.main_categories })
        }

        let currentPage = window.location.pathname
        let layout_type = "layout_1"
        let currentTime = Math.floor(Date.now() / 1000)
        let pageName = ""
        if (currentPage === "/") {
            pageName = "landing_page"
        } else {
            if (currentPage.includes("%20")) {
                pageName = currentPage.split("%20").join("_")
            } else {
                pageName = currentPage
            }
            pageName = pageName.split("/").join('')
        }
        let sectionType = pageName + '_' + layout_type + '_' + currentTime
        this.setState({ sectionType })


        // Broswe Detections
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                this.setState({ browserType: "chrome" })
            } else {
                this.setState({ browserType: "safari" })
            }
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    uploadImg(f, e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0], selectedBrandIndex: f })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 276 || height > 276) {
                            let crop = {
                                unit: 'px',
                                width: 276,
                                height: 276,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    doneImage() {
        let brand = JSON.parse(JSON.stringify(this.state.allBrands[this.state.selectedBrandIndex]))
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("section_type", this.state.sectionType);
        formdata.append("image", file);
        formdata.append("brand_id", brand.brand_id);
        formdata.append("main_category", this.state.selectedCategory);
        formdata.append("image_type", "thumbnail");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
            .then(response => response.json())
            .then((resultFirst) => {
                let arr = []
                this.state.arrSelected.map((e) => {
                    arr.push(e.value)
                })
                var formdata = new FormData();
                formdata.append("main_category", this.state.selectedCategory);
                formdata.append("brand_id", JSON.stringify(arr));
                formdata.append("section_type", this.state.sectionType);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/get/brands/by/brand_id?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        if (this.state.selectedBrandIndex === 0) {
                            this.setState({ deleteImageFirst: true, imageFirst: resultFirst.data.image_url })
                        } else if (this.state.selectedBrandIndex === 1) {
                            this.setState({ deleteImageSec: true, imageSecond: resultFirst.data.image_url })
                        } else if (this.state.selectedBrandIndex === 2) {
                            this.setState({ deleteImageThird: true, imageThird: resultFirst.data.image_url })
                        } else if (this.state.selectedBrandIndex === 3) {
                            this.setState({ deleteImageFourth: true, imageFourth: resultFirst.data.image_url })
                        }
                        this.setState({ allBrands: result.data.brands, limited: false, cropper: false, croppedImageUrl: "" })
                    })
                    .catch(error => console.log('error', error));
            })
            .catch(error => console.log('error', error));
    }

    removeImage(e, f, index) {
        let splitted = f.split("/")

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("section_type", this.state.sectionType);
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("brand_id", e);
        formdata.append("main_category", this.state.selectedCategory);
        formdata.append("image_type", "thumbnail");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then(() => {
                let arr = []
                this.state.arrSelected.map((e) => {
                    arr.push(e.value)
                })
                var formdata = new FormData();
                formdata.append("main_category", this.state.selectedCategory);
                formdata.append("brand_id", JSON.stringify(arr));
                formdata.append("section_type", this.state.sectionType);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/get/brands/by/brand_id?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.setState({ allBrands: result.data.brands, limited: false })
                        if (index === 0) {
                            this.setState({ deleteImageFirst: false, imageFirst: "" })
                        } else if (index === 1) {
                            this.setState({ deleteImageSec: false, imageSecond: "" })
                        } else if (index === 2) {
                            this.setState({ deleteImageThird: false, imageThird: "" })
                        } else if (index === 3) {
                            this.setState({ deleteImageFourth: false, imageFourth: "" })
                        }
                    })
                    .catch(error => console.log('error', error));
            })
            .catch(error => console.log('error', error));
    }

    selectChange(e) {
        this.setState({ greaterLength: false })
        if (e !== "yes" && e !== "no") {
            let target = e.target.value
            if (this.state.arrSelected.length > 0) {
                this.setState({ open: true, dataChaning: target, limited: false })
            } else {
                this.setState({ dataChaning: target })
                if (this.state.pageType === "brand_detail") {
                    fetch(`${gv}/api/v1/${e.target.value}/merken?browser_type=${this.state.browserType}`,)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            let arr = []
                            resp.map((e) => {
                                let id = e.brand_id
                                let label = e.name
                                let obj = {
                                    value: id,
                                    label
                                }
                                arr.push(obj)
                            })
                            this.setState({ nestedArrs: resp, selectedCategory: target, arrSuggestions: arr, limited: false, open: false })
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let headerData = localStorage.getItem("headerData")
                    if (headerData) {
                        let parsed = JSON.parse(headerData)
                        let child_menus = Object.entries(parsed.menus.child_menus)
                        child_menus.map((e) => {
                            if (e[0] === target) {
                                let arr = []
                                e[1].map((e) => {
                                    let id = e.id
                                    let label = e.name
                                    let obj = {
                                        value: id,
                                        label
                                    }
                                    arr.push(obj)
                                })
                                this.setState({ nestedArrs: e[1], selectedCategory: target, arrSuggestions: arr, limited: false, open: false })
                            }
                        })
                    }
                }
            }
        } else {
            if (e === "yes") {
                let target = this.state.dataChaning
                if (this.state.pageType === "brand_detail") {
                    fetch(`${gv}/api/v1/${target}/merken?browser_type=${this.state.browserType}`,)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            let arr = []
                            resp.map((e) => {
                                let id = e.brand_id
                                let label = e.name
                                let obj = {
                                    value: id,
                                    label
                                }
                                arr.push(obj)
                            })
                            this.setState({ nestedArrs: resp, selectedCategory: target, arrSuggestions: arr, arrSelected: [], allBrands: [], limited: false, open: false })
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let headerData = localStorage.getItem("headerData")
                    if (headerData) {
                        let parsed = JSON.parse(headerData)
                        let child_menus = Object.entries(parsed.menus.child_menus)
                        child_menus.map((e) => {
                            if (e[0] === target) {
                                let arr = []
                                e[1].map((e) => {
                                    let id = e.id
                                    let label = e.name
                                    let obj = {
                                        value: id,
                                        label
                                    }
                                    arr.push(obj)
                                })
                                this.setState({ nestedArrs: e[1], selectedCategory: target, arrSuggestions: arr, limited: false, open: false, arrSelected: [], allBrands: [], limited: false, open: false })
                            }
                        })
                    }
                }
            } else {
                this.setState({ open: false, limited: false })
            }
        }
    }

    showBrands(f) {
        this.setState({ arrSelected: f })
        let arr = []
        f.map((e) => {
            arr.push(e.value)
        })
        var formdata = new FormData();
        formdata.append("main_category", this.state.selectedCategory);
        formdata.append("brand_id", JSON.stringify(arr));
        formdata.append("section_type", this.state.sectionType);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/brands/by/brand_id?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.setState({ allBrands: result.data.brands, limited: false })
                result.data.brands.map((e, i) => {
                    if (i === 0) {
                        let image = e.images[this.state.onImageIndex].split('/')
                        let name = image[image.length - 1]
                        this.setState({ firstImage: e.images[this.state.onImageIndex], imageNameFirst: name })
                    } else if (i === 1) {
                        let image = e.images[this.state.onImageIndexSec].split('/')
                        let name = image[image.length - 1]
                        this.setState({ secondImage: e.images[this.state.onImageIndexSec], imageNameSecond: name })
                    } else if (i === 2) {
                        let image = e.images[this.state.onImageIndexThird].split('/')
                        let name = image[image.length - 1]
                        this.setState({ thirdImage: e.images[this.state.onImageIndexThird], imageNameThird: name })
                    } else {
                        let image = e.images[this.state.onImageIndexFourth].split('/')
                        let name = image[image.length - 1]
                        this.setState({ fourthImage: e.images[this.state.onImageIndexFourth], imageNameFourth: name })
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    saveBrands() {
        const { wholeData } = this.state
        if (this.state.arrSelected.length > 0) {
            if (this.state.arrSelected.length < 5) {
                let arr = []
                this.state.arrSelected.map((e) => {
                    arr.push(e.value)
                })
                let obj = {
                    type: "layout_5",
                    brands: arr,
                    allBrands: this.state.allBrands,
                    main_category: this.state.selectedCategory,
                    space_top: this.state.spaceTop,
                    space_bottom: this.state.spaceBottom,
                    page_type: this.state.pageType,
                    onImageIndex: this.state.onImageIndex,
                    onImageIndexSec: this.state.onImageIndexSec,
                    onImageIndexThird: this.state.onImageIndexThird,
                    onImageIndexFourth: this.state.onImageIndexFourth,
                    deleteImageFirst: this.state.deleteImageFirst,
                    deleteImageSec: this.state.deleteImageSec,
                    deleteImageThird: this.state.deleteImageThird,
                    deleteImageFourth: this.state.deleteImageFourth,
                    section_type: this.state.sectionType,
                    imageFirst: this.state.imageFirst,
                    imageSecond: this.state.imageSecond,
                    imageThird: this.state.imageThird,
                    imageFourth: this.state.imageFourth,
                    firstImage: this.state.firstImage,
                    secondImage: this.state.secondImage,
                    thirdImage: this.state.thirdImage,
                    fourthImage: this.state.fourthImage,
                    imageNameFirst: this.state.imageNameFirst,
                    imageNameSecond: this.state.imageNameSecond,
                    imageNameThird: this.state.imageNameThird,
                    imageNameFourth: this.state.imageNameFourth,
                }

                this.setState({ createLoader: true })
                let currentIndex = localStorage.getItem("currentOrder")
                wholeData.sections.splice(currentIndex + 1, 0, obj)

                this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
                let stringified = JSON.stringify(this.state.sendingData.other_pages)

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("builder_settings", stringified);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(error => {
                        this.setState({ showErrorModal: true, createLoader: false })
                    });
            } else {
                this.setState({ greaterLength: true, limited: false })
            }
        } else {
            this.setState({ limited: true })
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    showCurrentImage() {
        this.setState({ cropper: false, croppedImageUrl: "" })
    }

    render() {
        return (
            <div style={{ paddingBottom: this.state.arrSelected.length === 0 && 400 }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                {!this.state.cropper && <div style={{ width: "100%", padding: 20, paddingTop: 40 }}>
                    <select className="bodyText" style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", letterSpacing: 1 }} onChange={this.selectChange.bind(this)}>
                        <option>Select Category</option>
                        {this.state.mainArrs.map((e) => {
                            if (this.state.pageType === "brand_detail") {
                                if (e.show_for_brands) {
                                    return <option key={Math.random()} selected={this.state.selectedCategory === e.id} value={e.id}>{e.id}</option>
                                }
                            } else {
                                if (e.show_for_products) {
                                    return <option key={Math.random()} selected={this.state.selectedCategory === e.id} value={e.id}>{e.id}</option>
                                }
                            }
                        })}
                    </select>
                </div>}
                <div style={{ width: "100%", padding: 20, paddingTop: 5 }}>
                    <select className="bodyText" value={this.state.pageType} style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", letterSpacing: 1 }} onChange={(e) => {
                        this.setState({ pageType: e.target.value })
                        if (e.target.value === "brand_detail") {
                            fetch(`${gv}/api/v1/${this.state.selectedCategory}/merken?browser_type=${this.state.browserType}`,)
                                .then(response => response.json())
                                .then(result => {
                                    let resp = result.data.data
                                    let arr = []
                                    resp.map((e) => {
                                        let id = e.brand_id
                                        let label = e.name
                                        let obj = {
                                            value: id,
                                            label
                                        }
                                        arr.push(obj)
                                    })
                                    this.setState({ nestedArrs: resp, arrSuggestions: arr, arrSelected: [], allBrands: [], limited: false, open: false })
                                })
                                .catch(error => console.log('error', error));
                        } else {
                            let headerData = localStorage.getItem("headerData")
                            if (headerData) {
                                let parsed = JSON.parse(headerData)
                                let child_menus = Object.entries(parsed.menus.child_menus)
                                child_menus.map((e) => {
                                    if (e[0] === this.state.selectedCategory) {
                                        let arr = []
                                        e[1].map((e) => {
                                            let id = e.id
                                            let label = e.name
                                            let obj = {
                                                value: id,
                                                label
                                            }
                                            arr.push(obj)
                                        })
                                        this.setState({ nestedArrs: e[1], arrSuggestions: arr, arrSelected: [], allBrands: [], limited: false, open: false })
                                    }
                                })
                            }
                        }
                    }}>
                        <option value="brand_detail">Brand Detail</option>
                        <option value="brand_products">Brand Products</option>
                    </select>
                </div>
                {this.state.nestedArrs.length > 0 && !this.state.cropper && <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20 }}>
                    <Select
                        isMulti
                        name="colors"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={this.state.arrSuggestions}
                        value={this.state.arrSelected}
                        backspaceRemovesValue={false}
                        onChange={(e, f) => {
                            if (e) {
                                if (e.length < 5 && f.action !== "remove-value") {
                                    this.showBrands(e)
                                } else if (f.action === "remove-value") {
                                    this.showBrands(e)
                                } else {
                                    this.setState({ greaterLength: true })
                                }
                            } else {
                                this.setState({ arrSelected: [], allBrands: [] })
                            }
                        }}
                    />
                </div>}
                {this.state.greaterLength && <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20 }}>
                    <p className="bodyText" style={{ fontFamily: "Roboto", fontSize: 13, color: "red" }}>the maxmimum brands limit for this section is 4</p>
                </div>}
                {this.state.limited && <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20 }}>
                    <p className="bodyText" style={{ fontFamily: "Roboto", fontSize: 13, color: "red" }}>Please enter atleast 1 brand</p>
                </div>}
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                        maxHeight={276}
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="sectiles inner-bmain-section" style={{ width: "100%", backgroundColor: "#f6f4f2", display: "flex", paddingBottom: 80 }}>
                        {this.state.allBrands.length > 0 && this.state.allBrands.map((e, i) => {
                            return <div className="card-bmain" key={Math.random()}>
                                <div className="card-inner-bmain">
                                    <p style={{ fontWeight: 550, fontSize: 16 }}>
                                        {e.name}
                                    </p>
                                    {i === 0 ? <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                        {this.state.deleteImageFirst ? <img src={this.state.imageFirst} alt="imgbl" style={{ marginBottom: 10 }} /> : <img src={e.images[this.state.onImageIndex]} alt="imgbl" style={{ marginBottom: 10 }} />}
                                    </div> : i === 1 ? <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                        {this.state.deleteImageSec ? <img src={this.state.imageSecond} alt="imgbl" style={{ marginBottom: 10 }} /> : <img src={e.images[this.state.onImageIndexSec]} alt="imgbl" style={{ marginBottom: 10 }} />}
                                    </div> : i === 2 ? <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                        {this.state.deleteImageThird ? <img src={this.state.imageThird} alt="imgbl" style={{ marginBottom: 10 }} /> : <img src={e.images[this.state.onImageIndexThird]} alt="imgbl" style={{ marginBottom: 10 }} />}
                                    </div> : i === 3 && <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                        {this.state.deleteImageFourth ? <img src={this.state.imageFourth} alt="imgbl" style={{ marginBottom: 10 }} /> : <img src={e.images[this.state.onImageIndexFourth]} alt="imgbl" style={{ marginBottom: 10 }} />}
                                    </div>}
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <input type="file" onChange={(f) => {
                                                this.uploadImg(i, f)
                                            }} style={{ width: 40 }} accept="image/*" />
                                            <PublishIcon style={{ color: "white" }} />
                                        </div>
                                        {i === 0 && this.state.deleteImageFirst ? <div className="file-uploadimg" style={{ zIndex: 9999, position: "static", margin: "0px 5px" }}>
                                            <DeleteIcon onClick={this.removeImage.bind(this, e.brand_id, e.image_url, i)} />
                                        </div> : i === 1 && this.state.deleteImageSec ? <div className="file-uploadimg" style={{ zIndex: 9999, position: "static", margin: "0px 5px" }}>
                                            <DeleteIcon onClick={this.removeImage.bind(this, e.brand_id, e.image_url, i)} />
                                        </div> : i === 2 && this.state.deleteImageThird ? <div className="file-uploadimg" style={{ zIndex: 9999, position: "static", margin: "0px 5px" }}>
                                            <DeleteIcon onClick={this.removeImage.bind(this, e.brand_id, e.image_url, i)} />
                                        </div> : i === 3 && this.state.deleteImageFourth && <div className="file-uploadimg" style={{ zIndex: 9999, position: "static", margin: "0px 5px" }}>
                                            <DeleteIcon onClick={this.removeImage.bind(this, e.brand_id, e.image_url, i)} />
                                        </div>}
                                        {e.images.length > 1 && i === 0 ? this.state.onImageIndex > 0 && !this.state.deleteImageFirst && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndex - 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndex: this.state.onImageIndex - 1, firstImage: firstImage, imageNameFirst: imageName })
                                            }} />
                                        </div> : i === 1 ? this.state.onImageIndexSec > 0 && !this.state.deleteImageSec && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndexSec - 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndexSec: this.state.onImageIndexSec - 1, secondImage: firstImage, imageNameSecond: imageName })
                                            }} />
                                        </div> : i === 2 ? this.state.onImageIndexThird > 0 && !this.state.deleteImageThird && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndexThird - 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndexThird: this.state.onImageIndexThird - 1, thirdImage: firstImage, imageNameThird: imageName })
                                            }} />
                                        </div> : i === 3 && this.state.onImageIndexFourth > 0 && !this.state.deleteImageFourth && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndexFourth - 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndexFourth: this.state.onImageIndexFourth - 1, fourthImage: firstImage, imageNameFourth: imageName })
                                            }} />
                                        </div>}
                                        {e.images.length > 1 && i === 0 ? this.state.onImageIndex < e.images.length - 1 && !this.state.deleteImageFirst && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndex + 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndex: this.state.onImageIndex + 1, firstImage: firstImage, imageNameFirst: imageName })

                                            }} />
                                        </div> : i === 1 ? this.state.onImageIndexSec < e.images.length - 1 && !this.state.deleteImageSec && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndexSec + 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndexSec: this.state.onImageIndexSec + 1, secondImage: firstImage, imageNameSecond: imageName })
                                            }} />
                                        </div> : i === 2 ? this.state.onImageIndexThird < e.images.length - 1 && !this.state.deleteImageThird && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndexThird + 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndexThird: this.state.onImageIndexThird + 1, thirdImage: firstImage, imageNameThird: imageName })
                                            }} />
                                        </div> : i === 3 && this.state.onImageIndexFourth < e.images.length - 1 && !this.state.deleteImageFourth && <div className="file-uploadimg" style={{ zIndex: 99999, position: "static", margin: "0px 5px" }}>
                                            <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                                let firstImage = e.images[this.state.onImageIndexFourth + 1]
                                                let splittedName = firstImage.split('/')
                                                let imageName = splittedName[splittedName.length - 1]
                                                this.setState({ onImageIndexFourth: this.state.onImageIndexFourth + 1, fourthImage: firstImage, imageNameFourth: imageName })
                                            }} />
                                        </div>}
                                    </div>
                                    <a>LEES MEER</a>
                                </div>
                            </div>
                        })}
                    </div >}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections">
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    {this.state.allBrands.length > 0 && <div className="inner-faps" onClick={this.saveBrands.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Als je gaat wijzigen van categorie, dan dien je opnieuw de merken te selecteren.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.selectChange.bind(this, "no")} color="primary">No</Button>
                        <Button onClick={this.selectChange.bind(this, "yes")} color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 276x276, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default BrandList
