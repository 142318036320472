import React, { Component } from 'react'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { gv } from '../../Components/globalvar'
import EventNoteIcon from '@material-ui/icons/EventNote';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import './index.css'
import Leftmenu from '../../Components/Leftmenu';
import cities from '../../Data/cities.json'
import countries from '../../Data/countries.json'
import statesCountry from '../../Data/states.json'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Helmet } from "react-helmet";
import Navbar from '../../Components/Navbar';

export class Account extends Component {

    constructor() {
        super()
        this.state = {
            popupright: false,
            wholeData: [],
            addressType: '',
            country: '',
            region: '',
            countries: [],
            countryid: '155',
            allCities: [],
            allCitiesSec: [],
            stateid: '',
            statePopup: false,
            firstName: '',
            fnPopup: false,
            lastName: '',
            lnPopup: false,
            zipcode: '',
            zipPopup: false,
            houseNumber: '',
            hnPopup: false,
            country: '',
            countryPopup: false,
            city: '',
            cityPopup: false,
            street: '',
            streetPopup: false,
            phone: '',
            phonePopup: false,
            sex: '',
            sexPopup: false,
            dob: '',
            dobPopup: false,
            passwordType: true,
            passwordTypeSec: true,
            nestedFields: false,
            countryidSec: '',
            countryPopupSec: false,
            streetSec: '',
            streetPopupSec: false,
            zipcodeSec: '',
            zipPopupSec: false,
            houseNumberSec: '',
            hnPopupSec: false,
            citySec: '',
            cityPopupSec: false,
            diffrent_shippingtype: false,
            parsedData: [],
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        let usertoken = localStorage.getItem("usertoken")
        if (!usertoken) {
            window.location.href = "/login"
        }
        let wholeData = JSON.parse(localStorage.getItem("dashboardData"))
        if (wholeData) {
            this.setState({ wholeData })
        }

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let parsed = JSON.parse(headerData)
            this.setState({ parsedData: parsed })
        }

        // Date Working
        var todaysDate = new Date();
        var year = todaysDate.getFullYear();
        var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
        var day = ("0" + todaysDate.getDate()).slice(-2);
        var maxDate = (year + "-" + month + "-" + day);
        this.setState({ maxDate })

        // this.setState({ countries, states: statesCountry, cities })
        this.getData()
    }

    getData() {
        let usertoken = localStorage.getItem("usertoken")
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${usertoken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/auth/getuser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let resp = result.data
                if (resp.diffrent_shipping) {
                    let countryid = countries.filter((e) => {
                        return e.name === resp.diffrent_shipping.country
                    })
                    this.setState({ nestedFields: true, diffrent_shippingtype: true, streetSec: resp.diffrent_shipping.street, citySec: resp.diffrent_shipping.city, houseNumberSec: resp.diffrent_shipping.house_number, zipcodeSec: resp.diffrent_shipping.zip_code, countryidSec: countryid[0].id })
                }
                localStorage.setItem("dashboardData", JSON.stringify(resp))
                this.setState({ wholeData: resp })
            })
            .catch(error => console.log('error', error));
    }

    addAddress() {
        const { firstName, lastName, zipcode, houseNumber, street, phone, city, countryid, zipcodeSec, houseNumberSec, streetSec, citySec, countryidSec } = this.state
        let filteredCountry = []
        let filteredCountrySec = []
        if (firstName === "") {
            this.setState({ fnPopup: true })
        } else {
            this.setState({ fnPopup: false })
        }
        if (lastName === "") {
            this.setState({ lnPopup: true })
        } else {
            this.setState({ lnPopup: false })
        }
        if (zipcode === "") {
            this.setState({ zipPopup: true })
        } else {
            this.setState({ zipPopup: false })
        }
        if (houseNumber === "") {
            this.setState({ hnPopup: true })
        } else {
            this.setState({ hnPopup: false })
        }
        if (street === "") {
            this.setState({ streetPopup: true })
        } else {
            this.setState({ streetPopup: false })
        }
        if (phone === "") {
            this.setState({ phonePopup: true })
        } else {
            this.setState({ phonePopup: false })
        }
        if (city === "") {
            this.setState({ cityPopup: true })
        } else {
            this.setState({ cityPopup: false })
        }
        if (countryid === "") {
            this.setState({ countryPopup: true })
        } else {
            this.setState({ countryPopup: false })
            filteredCountry = countries.filter((e) => {
                return e.id === Number(countryid)
            })
        }
        if (zipcodeSec === "") {
            this.setState({ zipPopupSec: true })
        } else {
            this.setState({ zipPopupSec: false })
        }
        if (houseNumberSec === "") {
            this.setState({ hnPopupSec: true })
        } else {
            this.setState({ hnPopupSec: false })
        }
        if (streetSec === "") {
            this.setState({ streetPopupSec: true })
        } else {
            this.setState({ streetPopupSec: false })
        }
        if (citySec === "") {
            this.setState({ cityPopupSec: true })
        } else {
            this.setState({ cityPopupSec: false })
        }
        if (countryidSec === "") {
            this.setState({ countryPopupSec: true })
        } else {
            this.setState({ countryPopupSec: false })
            filteredCountrySec = countries.filter((e) => {
                return e.id === Number(countryidSec)
            })
        }
        if (firstName !== "" && lastName !== "" && zipcode !== "" && houseNumber !== "" && phone !== "" && city !== "" && countryid !== "") {
            let usertoken = localStorage.getItem("usertoken")
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            if (this.state.nestedFields) {
                if (zipcodeSec !== "" && houseNumberSec !== "" && streetSec !== "" && citySec !== "" && countryidSec !== "") {
                    var formdataSec = new FormData();
                    formdataSec.append("first_name", firstName);
                    formdataSec.append("last_name", lastName);
                    formdataSec.append("street", streetSec);
                    formdataSec.append("house_number", houseNumberSec);
                    formdataSec.append("zip_code", zipcodeSec);
                    formdataSec.append("phone", phone);
                    formdataSec.append("city", citySec);
                    formdataSec.append("country", filteredCountrySec[0].name);
                    formdataSec.append("address_type", "diffrent_shipping");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdataSec,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                var formdata = new FormData();
                                formdata.append("first_name", firstName);
                                formdata.append("last_name", lastName);
                                formdata.append("street", street);
                                formdata.append("house_number", houseNumber);
                                formdata.append("zip_code", zipcode);
                                formdata.append("phone", phone);
                                formdata.append("city", city);
                                formdata.append("country", filteredCountry[0].name);
                                formdata.append("address_type", "shipping");

                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: formdata,
                                    redirect: 'follow'
                                };

                                fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                    .then(response => response.json())
                                    .then(() => {
                                        this.getData()
                                        this.setState({ popupright: false, firstName: '', lastName: '', street: '', houseNumber: '', zipcode: '', phone: '', city: '', countryid: '' })
                                    })
                                    .catch(error => console.log('error', error));
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                var formdata = new FormData();
                formdata.append("first_name", firstName);
                formdata.append("last_name", lastName);
                formdata.append("street", street);
                formdata.append("house_number", houseNumber);
                formdata.append("zip_code", zipcode);
                formdata.append("phone", phone);
                formdata.append("city", city);
                formdata.append("country", filteredCountry[0].name);
                formdata.append("address_type", "shipping");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            if (this.state.diffrent_shippingtype === this.state.nestedFields) {
                                this.setState({ popupright: false, firstName: '', lastName: '', street: '', houseNumber: '', zipcode: '', phone: '', city: '', countryid: '' })
                                this.getData()
                            } else {
                                var formdataSec = new FormData();
                                formdataSec.append("first_name", "");
                                formdataSec.append("last_name", "");
                                formdataSec.append("street", "");
                                formdataSec.append("house_number", "");
                                formdataSec.append("zip_code", "");
                                formdataSec.append("phone", "");
                                formdataSec.append("city", "");
                                formdataSec.append("country", "");
                                formdataSec.append("address_type", "diffrent_shipping");

                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: formdataSec,
                                    redirect: 'follow'
                                };

                                fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                    .then(response => response.json())
                                    .then(results => {
                                        if (results.code === 200) {
                                            this.setState({ popupright: false, firstName: '', lastName: '', street: '', houseNumber: '', zipcode: '', phone: '', city: '', countryid: '' })
                                            this.getData()
                                        }
                                    })
                                    .catch(error => console.log('error', error));
                            }
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    updateData() {
        const { firstName, lastName, email, password, confirmPassword, sex, phone, dob } = this.state
        if (firstName !== "" && lastName !== "" && email !== "" && sex !== "" && phone !== "" && dob !== "" && password === confirmPassword) {
            let usertoken = localStorage.getItem("usertoken")
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("name", firstName);
            formdata.append("last_name", lastName);
            formdata.append("email", email);
            formdata.append("sex", sex);
            formdata.append("phone", phone);
            formdata.append("date_of_birth", dob);

            if (password !== "" && password === confirmPassword) {
                formdata.append("password", password);
                formdata.append("confirm_password", confirmPassword);
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/update/details`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.getData()
                    this.setState({ fnPopup: false, lnPopup: false, phonePopup: false, cpPopup: false, popupright: false, popuprightsec: false })
                })
                .catch(error => console.log('error', error));
        } else {
            if (firstName === "") {
                this.setState({ fnPopup: true })
            } else {
                this.setState({ fnPopup: false })
            }
            if (lastName === "") {
                this.setState({ lnPopup: true })
            } else {
                this.setState({ lnPopup: false })
            }
            if (phone === "") {
                this.setState({ phonePopup: true })
            } else {
                this.setState({ phonePopup: false })
            }
            if (password !== confirmPassword) {
                this.setState({ cpPopup: true })
            } else {
                this.setState({ cpPopup: false })
            }
            if (dob === "") {
                this.setState({ dobPopup: true })
            } else {
                this.setState({ dobPopup: false })
            }
        }
    }

    render() {
        if (this.state.popupright) {
            document.getElementById("mainbody").style.position = "fixed";
            document.getElementById("mainbody").style.width = "100%";
        } else {
            document.getElementById("mainbody").style.position = "static";
            document.getElementById("mainbody").style.width = "auto";
        }
        return (
            <div className="accountpage">
                <Navbar />
                {this.state.parsedData && <Helmet>
                    <title>{this.state.parsedData.dashboard_page_title}</title>
                </Helmet>}
                <div className="account-main">
                    <div className="account-wrapper">
                        <h1 className="account-title" style={{ display: "flex", alignItems: "center" }}>
                            <span className="icon-user">
                                <AccountCircleOutlinedIcon style={{ fontSize: 34 }} />
                            </span>
                            <font style={{ textTransform: "uppercase" }}>{this.state.wholeData.name} {this.state.wholeData.last_name}</font>
                        </h1>
                        <div className="account-left-content">
                            <Leftmenu />
                        </div>
                        <div className="account-right-content">
                            <div className="dashboard-row">
                                <section className="personal-details">
                                    <div className="section-title">
                                        <span>
                                            <EventNoteIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2 }} />
                                        </span>
                                        <span>Mijn gegevens</span>
                                    </div>
                                    <div className="block-inner-wrapper" style={{ backgroundColor: "#fcfaf7", position: "relative", padding: 30, marginRight: 30 }}>
                                        <span>{this.state.wholeData.name} {this.state.wholeData.last_name}</span>
                                        <span>{this.state.wholeData.email}</span>
                                        <span>{this.state.wholeData.date_of_birth}</span>
                                        <span>{this.state.wholeData.phone}</span>
                                        <div className="address-actions" style={{ position: "absolute", right: 30, top: 30 }}>
                                            <a onClick={() => {
                                                let dob = this.state.wholeData.date_of_birth
                                                const moonLanding = new Date(dob)
                                                dob = dob.replace(',', '')
                                                let splittedStr = dob.split(' ')
                                                splittedStr[0] = moonLanding.getMonth() + 1
                                                splittedStr.reverse()
                                                if (splittedStr[2] < 10 && Number(splittedStr[1]) < 10) {
                                                    dob = `${splittedStr[0]}-0${splittedStr[2]}-0${splittedStr[1]}`
                                                } else if (splittedStr[2] < 10) {
                                                    dob = `${splittedStr[0]}-0${splittedStr[2]}-${splittedStr[1]}`
                                                } else {
                                                    dob = `${splittedStr[0]}-${splittedStr[2]}-${splittedStr[1]}`
                                                }
                                                this.setState({
                                                    popuprightsec: true,
                                                    firstName: this.state.wholeData.name,
                                                    lastName: this.state.wholeData.last_name,
                                                    email: this.state.wholeData.email,
                                                    sex: this.state.wholeData.sex,
                                                    phone: this.state.wholeData.phone,
                                                    dob,
                                                })
                                            }}>
                                                Wijzig
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section className="address-book">
                                    <div className="section-title">
                                        <span>
                                            <LocationCityIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2 }} />
                                        </span>
                                        <span>Adres gegevens</span>
                                    </div>
                                    <div className="block-inner-wrapper" style={{ paddingLeft: 0 }}>
                                        {this.state.wholeData.shipping_address ? <div className="address-list-container" style={{ padding: 0 }}>
                                            <ul className="address-list">
                                                <li className="address-tile default">
                                                    <div className="address-wrapper" style={{ padding: 30 }}>
                                                        <div className="mini-address-name">
                                                            <font>{this.state.wholeData.shipping_address.first_name} {this.state.wholeData.shipping_address.last_name}</font>
                                                        </div>
                                                        <div className="mini-address-name">

                                                        </div>
                                                        <div className="mini-address-location" style={{ width: "70%" }}>
                                                            <address>
                                                                <font>{this.state.wholeData.shipping_address.street} {this.state.wholeData.shipping_address.house_number}</font>
                                                                <br />
                                                                <font>{this.state.wholeData.shipping_address.zip_code}</font>
                                                                <br />
                                                                <font>{this.state.wholeData.shipping_address.city}</font>
                                                                <br />
                                                                <font>{this.state.wholeData.shipping_address.country}</font>
                                                            </address>
                                                        </div>
                                                        <div className="address-actions">
                                                            <a onClick={() => {
                                                                let countryid = countries.filter((e) => {
                                                                    return e.name === this.state.wholeData.shipping_address.country
                                                                })
                                                                let statesC = statesCountry.filter((f) => {
                                                                    return f.country_id === Number(countryid[0].id)
                                                                })
                                                                let allCities = []
                                                                let allCitiesSec = []
                                                                statesC.filter((g) => {
                                                                    cities.map((h) => {
                                                                        if (g.id === h.state_id) {
                                                                            allCities.push(h)
                                                                            allCitiesSec.push(h)
                                                                        }
                                                                    })
                                                                })
                                                                this.setState({
                                                                    allCities,
                                                                    allCitiesSec,
                                                                    popupright: true,
                                                                    addressType: "shipping",
                                                                    firstName: this.state.wholeData.shipping_address.first_name,
                                                                    lastName: this.state.wholeData.shipping_address.last_name,
                                                                    zipcode: this.state.wholeData.shipping_address.zip_code,
                                                                    houseNumber: this.state.wholeData.shipping_address.house_number,
                                                                    street: this.state.wholeData.shipping_address.street,
                                                                    phone: this.state.wholeData.shipping_address.phone,
                                                                    countryid: countryid[0].id,
                                                                    city: this.state.wholeData.shipping_address.city,
                                                                })
                                                                if (this.state.wholeData.diffrent_shipping === null) {
                                                                    this.setState({
                                                                        zipcodeSec: this.state.wholeData.shipping_address.zip_code,
                                                                        houseNumberSec: this.state.wholeData.shipping_address.house_number,
                                                                        streetSec: this.state.wholeData.shipping_address.street,
                                                                        countryidSec: countryid[0].id,
                                                                        citySec: this.state.wholeData.shipping_address.city,
                                                                    })
                                                                }
                                                            }}>
                                                                Wijzig
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> : <a onClick={() => {
                                            this.setState({ popupright: true, addressType: 'shipping' });
                                        }}>ADD ADDRESS</a>
                                        }
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.popupright && <div className="bg-overlaymini" style={{ zIndex: 999, position: "fixed" }} onClick={() => this.setState({ popupright: false, popuprightsec: false, fnPopup: false, lnPopup: false, streetPopup: false, hnPopup: false, countryPopup: false, cityPopup: false, zipPopup: false })}>

                </div>}
                {this.state.popuprightsec && <div className="bg-overlaymini" style={{ zIndex: 999, position: "fixed" }} onClick={() => this.setState({ popupright: false, popuprightsec: false, fnPopup: false, lnPopup: false, streetPopupSec: false, hnPopupSec: false, countryPopupSec: false, cityPopupSec: false, zipPopupSec: false })}>

                </div>}
                <div className={`mnicart-flyout-inner deliver-options ${this.state.popupright ? 'open' : null}`}>
                    <div className="checkout-flyout-inner-content" style={{ backgroundColor: "#fcfaf7" }}>
                        <div className="flyout-section">
                            <div className="minicart-flyout-wrapper">
                                <CloseIcon style={{ position: "absolute", cursor: "pointer", top: 34, fontSize: 24, left: 27, zIndex: 99999 }} onClick={() => this.setState({ popupright: false })} />
                                <div className="flyout-edit-delivery-options">
                                    <h3 className="flyout-title">I want to receive my order on...</h3>
                                    <div className="addresslist-fieldset-inner">

                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Voornaam</label>
                                                <input type="text" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                                                {this.state.fnPopup && <span className="alert-fields">Fist name is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Achternaam</label>
                                                <input type="text" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                                {this.state.lnPopup && <span className="alert-fields">Last name is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Straatnaam</label>
                                                <input className="inputfield-text" value={this.state.street} onChange={(e) => this.setState({ street: e.target.value })} />
                                                {this.state.streetPopup && <span className="alert-fields">Street is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Huisnummer</label>
                                                <input className="inputfield-text" value={this.state.houseNumber} onChange={(e) => this.setState({ houseNumber: e.target.value })} />
                                                {this.state.hnPopup && <span className="alert-fields">House number is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Postcode</label>
                                                <input type="text" value={this.state.zipcode} onChange={(e) => this.setState({ zipcode: e.target.value })} />
                                                {this.state.zipPopup && <span className="alert-fields">Zip code is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Plaatsnaam</label>
                                                <input type="text" placeholder="Enter city" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                            </div>
                                            {this.state.cityPopup && <span className="alert-fields">City is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Land</label>
                                                <select onChange={(e) => {
                                                    if (e.target.value !== "Select Country...") {
                                                        this.setState({ countryid: e.target.value, stateid: '' })
                                                        let statesC = statesCountry.filter((f) => {
                                                            return f.country_id === Number(e.target.value)
                                                        })
                                                        let allCities = []
                                                        statesC.filter((g) => {
                                                            cities.map((h) => {
                                                                if (g.id === h.state_id) {
                                                                    allCities.push(h)
                                                                }
                                                            })
                                                        })
                                                        this.setState({ allCities })
                                                    } else {
                                                        this.setState({ countryid: '' })
                                                    }
                                                }}>
                                                    {countries.length > 0 && countries.map((e) => {
                                                        return <option selected={e.id === Number(this.state.countryid)} key={Math.random()} value={e.id}>{e.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {this.state.countryPopup && <span className="alert-fields">Country is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label><span>Telefoonnummer</span></label>
                                                <input type="number" placeholder="For example: +31621345678" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                            </div>
                                            {this.state.phonePopup && <span className="alert-fields">Phone number is required.</span>}
                                        </div>
                                        <div className="checkbox-pr form-row" onClick={() => this.setState({ nestedFields: !this.state.nestedFields })}>
                                            <div className="inputfield-row">
                                                <input type="checkbox" checked={this.state.nestedFields} />
                                                <label>Ontvang bestelling op ander adres</label>
                                            </div>
                                        </div>
                                        {this.state.nestedFields && <>
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Street</label>
                                                    <input className="inputfield-text" value={this.state.streetSec} onChange={(e) => this.setState({ streetSec: e.target.value })} />
                                                    {this.state.streetPopupSec && <span className="alert-fields">Street is required.</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>House Number</label>
                                                    <input className="inputfield-text" value={this.state.houseNumberSec} onChange={(e) => this.setState({ houseNumberSec: e.target.value })} />
                                                    {this.state.hnPopupSec && <span className="alert-fields">House number is required.</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="field-wrapper">
                                                    <label>Zip Code</label>
                                                    <input type="text" value={this.state.zipcodeSec} onChange={(e) => this.setState({ zipcodeSec: e.target.value })} />
                                                    {this.state.zipPopupSec && <span className="alert-fields">Zip code is required.</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>City</label>
                                                    <input type="text" placeholder="Enter city" value={this.state.citySec} onChange={(e) => this.setState({ citySec: e.target.value })} />
                                                </div>
                                                {this.state.cityPopupSec && <span className="alert-fields">City is required.</span>}
                                            </div>
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Country</label>
                                                    <select onChange={(e) => {
                                                        if (e.target.value !== "Select Country...") {
                                                            this.setState({ countryidSec: e.target.value, stateid: '' })
                                                            let statesC = statesCountry.filter((f) => {
                                                                return f.country_id === Number(e.target.value)
                                                            })
                                                            let allCitiesSec = []
                                                            statesC.filter((g) => {
                                                                cities.map((h) => {
                                                                    if (g.id === h.state_id) {
                                                                        allCitiesSec.push(h)
                                                                    }
                                                                })
                                                            })
                                                            this.setState({ allCitiesSec })
                                                        } else {
                                                            this.setState({ countryidSec: '' })
                                                        }
                                                    }}>
                                                        {countries.length > 0 && countries.map((e) => {
                                                            return <option selected={e.id === Number(this.state.countryidSec)} key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {this.state.countryPopupSec && <span className="alert-fields">Country is required.</span>}
                                            </div>
                                        </>}
                                        <div className="buttons-total" style={{ width: "100%", textAlign: "center" }}>
                                            <button className="btn-cart" style={{ margin: "0px auto" }} onClick={this.addAddress.bind(this)}>SAVE CHANGES</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Edit profile popup */}
                <div className={`mnicart-flyout-inner deliver-options ${this.state.popuprightsec ? 'open' : null}`}>
                    <div className="checkout-flyout-inner-content" style={{ backgroundColor: "#fcfaf7" }}>
                        <div className="flyout-section">
                            <div className="minicart-flyout-wrapper">
                                <CloseIcon style={{ position: "absolute", cursor: "pointer", top: 34, fontSize: 24, left: 27, zIndex: 99999 }} onClick={() => this.setState({ popuprightsec: false })} />
                                <div className="flyout-edit-delivery-options">
                                    <h3 className="flyout-title">I want to receive my order on...</h3>
                                    <div className="addresslist-fieldset-inner">
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Voornaam</label>
                                                <input type="text" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                                            </div>
                                            {this.state.fnPopup && <span className="alert-fields">First name is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Achternaam</label>
                                                <input type="text" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                            </div>
                                            {this.state.lnPopup && <span className="alert-fields">Last name is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Gender</label>
                                                <select value={this.state.sex} onChange={(e) => this.setState({ sex: e.target.value })} >
                                                    <option value="">Select</option>
                                                    <option value="men">Man</option>
                                                    <option value="woman">Woman</option>
                                                    <option value="other">Other</option>
                                                </select>
                                                <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                    <ExpandMoreIcon />
                                                </button>
                                            </div>
                                            {this.state.sexPopup && <span className="alert-fields">Field is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Geboortedatum</label>
                                                <input type="date" onKeyDown={(e) => e.preventDefault()} max={this.state.maxDate} value={this.state.dob} onChange={(e) => {
                                                    this.setState({ dob: e.target.value })
                                                }} />
                                                {this.state.dobPopup && <span className="alert-fields">Date of bitrh is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Email</label>
                                                <input type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                            </div>
                                            {this.state.emailPopup && <span className="alert-fields">Email is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Wachtwoord</label>
                                                <input type={this.state.passwordType ? "password" : "text"} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                                <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                    {this.state.passwordType ? <VisibilityOffIcon style={{ fontSize: 20 }} /> : <VisibilityIcon style={{ fontSize: 20 }} />}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Bevestig wachtwoord</label>
                                                <input type={this.state.passwordTypeSec ? "password" : "text"} value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                                                <button className="password-reveal" onClick={() => this.setState({ passwordTypeSec: !this.state.passwordTypeSec })}>
                                                    {this.state.passwordTypeSec ? <VisibilityOffIcon style={{ fontSize: 20 }} /> : <VisibilityIcon style={{ fontSize: 20 }} />}
                                                </button>
                                            </div>
                                            {this.state.cpPopup && <span className="alert-fields">Password and confirm password should matched.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label><span>Telefoonnummer</span></label>
                                                <input type="number" placeholder="For example: +31621345678" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                            </div>
                                            {this.state.phonePopup && <span className="alert-fields">Phone number is required.</span>}
                                        </div>
                                        <div className="buttons-total" style={{ width: "100%", textAlign: "center" }}>
                                            <button className="btn-cart" style={{ margin: "0px auto" }} onClick={this.updateData.bind(this)}>SAVE CHANGES</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Account
