import React, { Component } from 'react'
import CK from '../Components/Ck'
import { gv } from '../Components/globalvar'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class BannerSecEdit extends Component {

    constructor() {
        super()
        this.state = {
            image_url: "",
            delete_image: false,
            heading: "",
            headingInput: false,
            desc: "",
            descInput: false,
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            fullWidth: true,
            crop: {
                // unit: '%',
                width: 1304,
                height: 565,
                aspect: 16 / 9,
            },
            createLoader: true,
            themeColors: false,
        }
    }

    componentDidMount() {
        let accesstoken = localStorage.getItem("adminKey")

        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)
            this.setState({
                image_url: parsedData.image_url, desc: parsedData.desc, spaceTop: parsedData.space_top, spaceBottom: parsedData.space_bottom, fullWidth: parsedData.full_width,
                overlayColor: parsedData.overlayColor,
                fontColor: parsedData.fontColor,
                backgroundOpacity: parsedData.backgroundOpacity,
            })

            let splitted = parsedData.image_url.split('/')
            if (splitted[splitted.length - 1] !== "layout_13_banner_image.jpg") {
                this.setState({ delete_image: true })
            }
        }

        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    changeAlert(e) {
        this.setState({ desc: e })
    }

    close() {
        this.setState({ descInput: false })
    }

    createMarkup() {
        if (this.state.desc) {
            return { __html: this.state.desc };
        }
    }

    uploadImg(e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0] })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 1919) {
                            let crop = {
                                unit: '%',
                                width: 100,
                                height: 90,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        } else {
                            let crop = {
                                unit: 'px',
                                width: 1400,
                                height: 500,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    removeImage() {
        const { image_url } = this.state
        this.setState({ loader: true })
        let splitted = image_url.split("/")
        if (splitted[splitted.length - 1] !== "layout_13_banner_image.jpg") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_8");
            formdata.append("image_name", splitted[splitted.length - 1]);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_13_banner_image.jpg", delete_image: false, loader: false, })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    saveSection() {
        const { desc, image_url, wholeData } = this.state

        let obj = {
            type: "layout_13",
            image_url,
            desc,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            full_width: this.state.fullWidth,
            theming: this.state.themeColors,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (file) {
            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_8");
            formdata.append("image", file);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: result.data.image_url, delete_image: true, loader: false, croppedImageUrl: "", cropper: false })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    showCurrentImage() {
        this.setState({ cropper: false, image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_13_banner_image.jpg", croppedImageUrl: "" })
    }

    handleChangeComplete = (color) => {
        this.setState({ fontColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteSec = (color) => {
        this.setState({ overlayColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    render() {
        return (
            <div style={{ position: "relative" }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                        maxHeight={500}
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="bannersec-home" style={{ backgroundImage: `url(${this.state.image_url})` }}>
                        <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                            <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                            <PublishIcon style={{ color: "white" }} />
                        </div>
                        {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                            <DeleteIcon onClick={this.removeImage.bind(this)} />
                        </div>}
                        {this.state.loader && <div className="loader-working-fb">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>}
                        <div className={`inner-bsh ${this.state.themeColors && "themingibsh"}`}>
                            <div className="overlay-bsh">

                            </div>
                            {this.state.desc ? <div onClick={() => this.setState({ descInput: true })}>
                                {!this.state.descInput ? <div dangerouslySetInnerHTML={this.createMarkup()}></div> : <CK data={this.state.desc} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                            </div> : <CK data={this.state.desc} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                        </div>
                    </div>}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections" style={{ bottom: -60 }}>
                    <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.themeColors} onChange={() => {
                            this.setState({ themeColors: !this.state.themeColors })
                        }} />
                        <font>Theme Colour</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.fullWidth} onChange={() => {
                            this.setState({ fullWidth: !this.state.fullWidth })
                        }} />
                        <font>PAGINABREEDTE</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 1304x565, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default BannerSecEdit
