import React, { Component } from 'react'
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import logo from '../assets/logocheck.png'
import basket from '../assets/basketiconsec.png'
import CloseIcon from '@material-ui/icons/Close';

export class NavbarSecond extends Component {

    constructor() {
        super()
        this.state = {
            dropdownOpacity: 0,
            dropdownPointer: "none",
            dropdownOpacityHorloges: 0,
            dropdownPointerHorloges: "none",
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%", position: "fixed", backgroundColor: "white" }} className="hidingfooter">
                <div className="navbar-main-sec-other">
                    <div className="top-nmso">
                        <span><CheckIcon /> De officiele online store</span>
                        <span><CheckIcon /> Gratis verzending en 60 dagen bedenktijdk</span>
                        <span><CheckIcon /> Achteraf betalen</span>
                    </div>
                    <div className="mid-nmso">
                        <div className="container-midnmso">
                            <div className="left-cmidnmso">
                                <p>Vragen? Bel: 010-2380803</p>
                                <div className="search-lcmid">
                                    <input type="text" placeholder="Waar ben je naar op zoek?" />
                                    <SearchIcon />
                                </div>
                            </div>
                            <div className="mid-cmidnmso">
                                <img src={logo} />
                                <p>WATCHME OFFICIAL DEALER</p>
                            </div>
                            <div className="right-cmidnmso">
                                <p>Mijn account</p>
                                <p>Winkelmand <img src={basket} />
                                    <div className="basket-icon">
                                        <span>20</span>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='closer-nmso' onMouseOver={() => this.setState({ dropdownPointer: "none", dropdownOpacity: 0, dropdownOpacityHorloges: 0, dropdownPointerHorloges: "none" })}>

                    </div>
                    <div className="lists-nmso">
                        <ul>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>NIUEW</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>OORBELLEN</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>ARMBANDEN</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>RNGEN</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointerHorloges: "all", dropdownOpacityHorloges: 1, dropdownPointer: "none", dropdownOpacity: 0 })
                            }}>HORLOGES</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>NIUEW</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>OORBELLEN</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>ARMBANDEN</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>RNGEN</li>
                            <li onMouseOver={() => {
                                this.setState({ dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                            }}>HORLOGES</li>
                        </ul>
                    </div>
                    <div className="dropdown-lists-nmso" style={{ opacity: this.state.dropdownOpacity, pointerEvents: this.state.dropdownPointer }}>
                        <CloseIcon onClick={() => {
                            this.setState({ dropdownPointer: "none", dropdownOpacity: 0 })
                        }} style={{ position: "absolute", top: 10, right: 10 }} />
                        <div className="inner-dropdown-list-nmso">
                            <ul>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                            </ul>
                        </div>
                        <div className="inner-dropdown-list-nmso">
                            <ul>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                            </ul>
                        </div>
                        <div className="inner-dropdown-list-nmso">
                            <ul>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                            </ul>
                        </div>
                        <div className="inner-dropdown-list-nmso">
                            <ul>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                                <li>test</li>
                            </ul>
                        </div>
                        <div className="inner-dropdown-list-nmso">
                            <img src="https://www.zinzi.nl/media/wysiwyg/Zinzi---210114-menu-uiting---horloges.jpg" />
                            <p className="title-idln">2+1 cadeu</p>
                            <p className="desc-idln">test cadeu</p>
                            <button>SHOP NU</button>
                        </div>
                    </div>
                    <div className="dropdown-lists-nmso" style={{ opacity: this.state.dropdownOpacityHorloges, pointerEvents: this.state.dropdownPointerHorloges }}>
                        <CloseIcon onClick={() => {
                            this.setState({ dropdownPointerHorloges: "none", dropdownOpacity: 0 })
                        }} style={{ position: "absolute", top: 10, right: 10 }} />
                        <div className="inner-dropdown-list-nmso">
                            <ul>
                                <li>test</li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                                <li style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                    <img src="https://www.zinzi.nl/media/horloges/Zinzi---191007-Retro-min.png" style={{ maxWidth: 60 }} />
                                    <p>Horloges</p>
                                </li>
                            </ul>
                        </div>
                        <div className="inner-dropdown-list-nmso">
                            <ul>
                                <li>Horloges</li>
                                <li>Alle Horloges</li>
                                <li>Horlogebranden</li>
                            </ul>
                        </div>
                        <div className="inner-dropdown-list-nmso">
                            <img src="https://www.zinzi.nl/media/wysiwyg/Zinzi---210114-menu-uiting---horloges.jpg" />
                            <p className="title-idln">2+1 cadeu</p>
                            <p className="desc-idln">test cadeu</p>
                            <button>SHOP NU</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavbarSecond
