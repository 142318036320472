import React, { Component } from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import './index.css'
import InputComponent from '../Components/InputComponent';
import CK from '../Components/Ck';

export class FaqTemplateData extends Component {

    constructor() {
        super()
        this.state = {
            data: ''
        }
    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    componentDidMount() {
        let currentTime = Math.floor(Date.now() / 1000)
        let getRandom = this.randomStr(6)
        let hashtoken = getRandom + '' + currentTime
        this.setState({ hashtoken })
        this.setState({ data: this.props.data })
    }

    createMarkup(e) {
        return { __html: e };
    }

    render() {
        return (
            <div className="faq-main" style={{ position: "relative", maxWidth: 1260, margin: "0px auto", marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                {this.state.data && this.state.hashtoken && <div className="row faqacc">
                    {this.state.data.wholeData.map((e, i) => {
                        return <div className="card" style={{ backgroundColor: "transparent" }} key={Math.random()}>
                            <div className="card-header">
                                <a data-toggle="collapse" href={`#collapseExample${this.state.hashtoken}${i}`} role="button" aria-expanded="false" aria-controls="collapseExample" >
                                    <p>
                                        {e.title}
                                    </p>
                                    <div className="btn-iconc" style={{ backgroundColor: this.state.data.button_colour }}>
                                        <AddCircleOutlineIcon style={{ fill: this.state.data.icon_colour }} />
                                    </div>
                                </a>
                            </div>
                            <div className="collapse" id={`collapseExample${this.state.hashtoken}${i}`}>
                                <div className="card-body">
                                    <div dangerouslySetInnerHTML={this.createMarkup(e.text)}></div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>}
            </div>
        )
    }
}

export default FaqTemplateData
