import React, { Component } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { gv } from '../Components/globalvar'
import Geocode from "react-geocode";
import './index.css'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

AOS.init();

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyB-sooax1EiXpeAASfGxhn7e19kgog55To");
Geocode.setLanguage("en");
// Geocode.setRegion("es");
Geocode.enableDebug();

export class ContactData extends Component {

    constructor() {
        super()
        this.state = {
            transition: false,
            modal: false,
            firstBlock: false,
            secondBlock: true,
            thirdBlock: false,
            wholeData: [],
            timings: [],
            latitudeMap: '',
            longitudeMap: ''
        }
    }

    componentDidMount() {
        let timings = Object.entries(JSON.parse(this.props.data.realData.shop_timings))
        this.setState({ timings })

        Geocode.fromAddress(this.props.data.realData.address).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({ latitudeMap: lat, longitudeMap: lng })
            },
            error => {
                console.error(error);
            }
        );
    }

    render() {
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={14}
                defaultCenter={{ lat: this.state.latitudeMap, lng: this.state.longitudeMap }}
            >
                {/* {props.isMarkerShown && */}
                {props.isMarkerShown && <Marker position={{ lat: this.state.latitudeMap, lng: this.state.longitudeMap }} />}

                {/* < Marker position={{ lat: this.state.lat, lng: this.state.lng }} draggable={true} onDragEnd={(e) => this.setState({ lat: e.latLng.lat(), lng: e.latLng.lng() })} />} */}
            </GoogleMap>
        ))
        return (
            <div>
                <div className="bg-main-secondh brand-products" style={{ padding: 0, marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                    <div className="checkout-holder" style={{ marginBottom: 0, padding: 0 }}>
                        <div className="inner-wrapper-small" style={{ maxWidth: 1265, width: "100%", padding: "0px 15px" }}>
                            <div className="checkout-layout-left" style={{ marginBottom: 0 }}>
                                <div className="checkout-inner">
                                    <div className="checkout-step-content">
                                        <div className="checkout-review-box contactpage-left" style={{ maxWidth: "none", padding: 20 }}>
                                            <h4>{this.props.data.leftTitle}</h4>
                                            <h6>{this.props.data.realData.left_desc}</h6>
                                            {this.state.latitudeMap && <MyMapComponent
                                                isMarkerShown
                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB-sooax1EiXpeAASfGxhn7e19kgog55To&callback=initMap"
                                                loadingElement={<div style={{ height: "100%" }} />}
                                                containerElement={<div style={{ height: 330, marginTop: 40 }} />}
                                                mapElement={<div style={{ height: "100%" }} />}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkout-layout-right">
                                <div className="checkout-summary-inner contactpage-right" style={{ height: "96%" }}>
                                    <h4>{this.props.data.realData.right_title}</h4>
                                    <h6>{this.props.data.realData.right_desc}</h6>
                                    <ul>
                                        {this.state.timings.length > 0 && this.state.timings.map((e) => {
                                            return <li key={Math.random()}>
                                                <span>
                                                    {e[0]}
                                                </span>
                                                <span>
                                                    {e[1][0] !== "Gesloten" ? `${e[1][0]} - ${e[1][1]}` : `${e[1][0]}`}
                                                </span>
                                            </li>
                                        })}
                                    </ul>
                                    <h6 className="heading6right">{this.props.data.realData.contact_page_vakantiesluiting}</h6>
                                    <h6 className="heading6right" style={{ margin: "0px auto" }}>{this.props.data.realData.contact_page_meldingen}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactData
