import React, { Component } from 'react'
import EventNoteIcon from '@material-ui/icons/EventNote';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DraftsIcon from '@material-ui/icons/Drafts';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { gv } from './globalvar'
import EventIcon from '@material-ui/icons/Event';

export class Leftmenu extends Component {

    logOut() {
        let usertoken = localStorage.getItem("usertoken")

        if (usertoken !== "") {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/logout`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "Successfully logged out.") {
                        localStorage.setItem("usertoken", "")
                        window.location.href = "/"
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    render() {
        return (
            <div className="account-menu-wrap">
                <ul className="account-menu">
                    <li>
                        <a href="/account"> <EventNoteIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2, color: "rgba(0,0,0,.6)" }} /> Mijn gegevens</a>
                    </li>
                    <li>
                        <a href="/order-history"> <ShoppingCartIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2, color: "rgba(0,0,0,.6)" }} /> Orders</a>
                    </li>
                    <li>
                        <a href="/reservation-list"> <EventIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2, color: "rgba(0,0,0,.6)" }} />Reserveringen</a>
                    </li>
                    <li>
                        <a href="/newsletter-subscription"> <DraftsIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2, color: "rgba(0,0,0,.6)" }} />Nieuwsbrief</a>
                    </li>
                    <li onClick={this.logOut.bind(this)}>
                        <a> <ExitToAppIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2, color: "rgba(0,0,0,.6)" }} /> Log out</a>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Leftmenu
