import React, { Component } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export class CK extends Component {
    render() {
        return (
            <CKEditor
                editor={ClassicEditor}
                data={this.props.data}
                onBlur={(event, editor) => {
                    const data = editor.getData();
                    this.props.onChildChange(data)
                    //this.props.onChildBlur()
                }}
            />
        )
    }
}

export default CK
