import React, { Component } from 'react'
import Navbar from '../../Components/Navbar'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

import './index.css'

export class Registration extends Component {

    constructor() {
        super()
        this.state = {
            name: '',
            fnPopup: false,
            last_name: '',
            lnPopup: false,
            email: '',
            password: '',
            passwordPopup: false,
            confirmPassword: '',
            cpPopup: false,
            cppPopup: false,
            date_of_birth: '',
            phone: '',
            phonePopup: false,
            sex: 'men',
            passwordType: true,
            passwordTypeSec: true,
            loginData: '',
            // Update content
            heading: "",
            headingInput: false,
            desc: "",
            descInput: false,
            checkText: "",
            checkTextInput: false,
            newsletterCheck: "no",
            newsletterDesc: "",
            newsletterDescInput: false,
        }
    }

    componentDidMount() {
        let accessToken = localStorage.getItem("usertoken")
        if (accessToken) {
            window.location.href = "/account"
        }

        let email = localStorage.getItem("loginEmail")
        this.setState({ email })
        // Date Working
        var todaysDate = new Date();
        var year = todaysDate.getFullYear();
        var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
        var day = ("0" + todaysDate.getDate()).slice(-2);
        var maxDate = (year + "-" + month + "-" + day);
        this.setState({ maxDate })

        // Login Data
        let loginData = localStorage.getItem("headerData")
        if (loginData) {
            loginData = JSON.parse(loginData)
            this.setState({ loginData, heading: loginData.registration_page_heading, desc: loginData.registration_page_desc, newsletterDesc: loginData.subscription_page_desc_2 })
        }

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        fetch(`${gv}/api/v1/list/of/values`)
            .then(response => response.json())
            .then(result => {
                let loginData = result.data
                localStorage.setItem("headerData", JSON.stringify(loginData))
                this.setState({ loginData, heading: loginData.registration_page_heading, desc: loginData.registration_page_desc, newsletterDesc: loginData.subscription_page_desc_2 })
            })
            .catch(error => console.log('error', error));
    }

    registration() {
        const { name, last_name, email, password, confirmPassword, date_of_birth, phone, sex } = this.state
        if (name === "") {
            this.setState({ fnPopup: true })
        } else {
            this.setState({ fnPopup: false })
        }
        if (last_name === "") {
            this.setState({ lnPopup: true })
        } else {
            this.setState({ lnPopup: false })
        }
        if (password === "") {
            this.setState({ passwordPopup: true })
        } else {
            this.setState({ passwordPopup: false })
        }
        // if (phone === "") {
        //     this.setState({ phonePopup: true })
        // } else {
        //     this.setState({ phonePopup: false })
        // }
        if (confirmPassword === "" || password !== confirmPassword) {
            this.setState({ cpPopup: true })
        } else {
            this.setState({ cpPopup: false })
        }
        if (name !== "" && last_name !== "" && email !== "" && password !== "" && confirmPassword !== "" && password === confirmPassword) {
            var formdata = new FormData();
            formdata.append("name", name);
            formdata.append("last_name", last_name);
            formdata.append("email", email);
            formdata.append("password", password);
            formdata.append("confirm_password", confirmPassword);
            formdata.append("date_of_birth", date_of_birth);
            formdata.append("phone", phone);
            formdata.append("sex", sex);
            formdata.append("sending news_letter_activated", this.state.newsletterCheck);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/signup`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "Registration successfully") {
                        localStorage.setItem("usertoken", result.data.token)
                        window.location.href = "/account"
                    } else if (result.data.email[0] === "The email has already been taken.") {
                        alert("email already taken")
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    updateSec() {
        const { heading, desc, newsletterDesc } = this.state

        let arr = [
            { key: "registration_page_heading", value: heading },
            { key: "registration_page_desc", value: desc },
            { key: "registration_page_news_leter", value: newsletterDesc },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                fetch(`${gv}/api/v1/list/of/values`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data
                        localStorage.setItem("headerData", JSON.stringify(resp))
                    })
                    .catch(error => console.log('error', error));
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    render() {
        return (
            <div>
                <Navbar />
                <Helmet>
                    <title>{this.state.loginData.registration_page_title}</title>
                </Helmet>
                <div className="login-wrapper">
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            <div className="page-layout-left">
                                <span className="page-layout-left-icon">
                                    {/* Logo */}
                                </span>
                                {this.state.isAdmin ? <div className="secchecks">
                                    <h3 className="page-layout-left-title" onClick={() => this.setState({ headingInput: true })} onBlur={() => this.setState({ headingInput: false })}>
                                        {!this.state.headingInput ? this.state.heading : <input type="text" autoFocus value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} />}
                                    </h3>
                                </div> :
                                    <h3 className="page-layout-left-title">
                                        {this.state.heading}
                                    </h3>
                                }
                                {this.state.isAdmin ? <div className="secchecks">
                                    <p className="page-layout-left-subtitle" onClick={() => this.setState({ descInput: true })} onBlur={() => this.setState({ descInput: false })}>
                                        {!this.state.descInput ? this.state.desc : <input type="text" autoFocus value={this.state.desc} onChange={(e) => this.setState({ desc: e.target.value })} onBlur={() => this.setState({ descInput: false })} />}
                                    </p>
                                </div> :
                                    <p className="page-layout-left-subtitle">
                                        {this.state.desc}
                                    </p>
                                }
                                {/* <p className="page-layout-left-subtitle">
                                    {this.state.desc}
                                </p> */}
                                <div className="login-panel_form">
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>E-mail</label>
                                            <input className="read-only" required readOnly type="email" value={this.state.email} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>Gender</label>
                                            <select value={this.state.sex} onChange={(e) => {
                                                this.setState({ sex: e.target.value })
                                            }}>
                                                <option value="men">Man</option>
                                                <option value="woman">Woman</option>
                                                <option value="other">Other</option>
                                            </select>
                                            <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                <ExpandMoreIcon />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>Voornaam</label>
                                            <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                            {this.state.fnPopup && <span className="alert-fields">First Name is required.</span>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>Achternaam</label>
                                            <input type="text" value={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })} />
                                            {this.state.lnPopup && <span className="alert-fields">Last Name is required.</span>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>Geboortedatum</label>
                                            <input type="date" onKeyDown={(e) => e.preventDefault()} max={this.state.maxDate} value={this.state.date_of_birth} onChange={(e) => this.setState({ date_of_birth: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>Wachtwoord</label>
                                            <input type={this.state.passwordType ? "password" : "text"} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                            <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                {this.state.passwordType ? <VisibilityOffIcon style={{ fontSize: 20 }} /> : <VisibilityIcon style={{ fontSize: 20 }} />}
                                            </button>
                                        </div>
                                        {this.state.passwordPopup && <span className="alert-fields">Password is required.</span>}
                                    </div>

                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label>Bevestig wachtwoord</label>
                                            <input type={this.state.passwordTypeSec ? "password" : "text"} value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                                            <button className="password-reveal" onClick={() => this.setState({ passwordTypeSec: !this.state.passwordTypeSec })}>
                                                {this.state.passwordTypeSec ? <VisibilityOffIcon style={{ fontSize: 20 }} /> : <VisibilityIcon style={{ fontSize: 20 }} />}
                                            </button>
                                        </div>
                                        {this.state.cpPopup && <span className="alert-fields">Password and Confirm Password should matched.</span>}
                                    </div>
                                    <div className="form-row">
                                        <div className="field-wrapper">
                                            <label><span>Telefoonnummer</span></label>
                                            <input type="number" placeholder="For example: +31621345678" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                        </div>
                                        {/* {this.state.phonePopup && <span className="alert-fields">Phone number is required.</span>} */}
                                    </div>
                                    <div className="form-row">
                                        <div className="field-wrapper" style={{ position: "relative" }}>
                                            {this.state.isAdmin ? <div className="secchecks" onClick={() => this.setState({ newsletterDescInput: true })} onBlur={() => this.setState({ newsletterDescInput: false })}>
                                                {!this.state.isAdmin && <input type="checkbox" onChange={(e) => {
                                                    if (e.target.checked) {
                                                        this.setState({ newsletterCheck: "yes" })
                                                    } else {
                                                        this.setState({ newsletterCheck: "no" })
                                                    }
                                                }} />}
                                                <label>
                                                    {!this.state.newsletterDescInput ? this.state.newsletterDesc : <input type="text" autoFocus value={this.state.newsletterDesc} onChange={(e) => this.setState({ newsletterDesc: e.target.value })} onBlur={() => this.setState({ newsletterDescInput: false })} />}
                                                </label>
                                            </div> : <div>
                                                    <input type="checkbox" onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.setState({ newsletterCheck: "yes" })
                                                        } else {
                                                            this.setState({ newsletterCheck: "no" })
                                                        }
                                                    }} />
                                                    <label>
                                                        {this.state.newsletterDesc}
                                                    </label>
                                                </div>
                                            }
                                            {/* <label>{this.state.newsletterDesc}</label> */}
                                        </div>
                                    </div>
                                    <div className="form-row form-row-button">
                                        <button onClick={this.registration.bind(this)}>ACCOUNT AANMAKEN</button>
                                    </div>
                                </div>
                            </div>
                            <div className="page-layout-right">
                                <div className="account-benefits">
                                    <div className="benefits-wrapper">
                                        <div className="content-asset">
                                            <span className="benefits-subtitle">{this.state.loginData.heading_1}</span>
                                            <h3 className="benefits-title">{this.state.loginData.heading_2}</h3>
                                            <ul className="benefits-list">
                                                <li>
                                                    <span className="icon-check">
                                                        <CheckIcon />
                                                    </span>
                                                    <span>{this.state.loginData.login_info1}</span>
                                                </li>
                                                <li>
                                                    <span className="icon-check">
                                                        <CheckIcon />
                                                    </span>
                                                    <span>{this.state.loginData.login_info2}</span>
                                                </li>
                                                <li>
                                                    <span className="icon-check">
                                                        <CheckIcon />
                                                    </span>
                                                    <span>{this.state.loginData.login_info3}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="benefits-dynamic-block">
                                            {this.state.loginData.login_right_image && <div className="image-holder">
                                                <img src={this.state.loginData.login_right_image} alt="lri" />
                                            </div>}
                                            <div className="text-holder">
                                                <p>
                                                    {this.state.loginData.login_right_text}
                                                </p>
                                                <p>
                                                    {this.state.loginData.gift_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                        <SaveAltIcon />
                        <button onClick={this.updateSec.bind(this)}>SAVE</button>
                    </div>
                }
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default Registration
